import React, { useEffect, useState, useRef } from "react";
import Footer from "../../../component/Footer";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const Profile = () => {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const [isTradingPowerEditable, setIsTradingPowerEditable] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(userData);
  const [formErrors, setFormErrors] = useState({});
  const [brokerData, setBrokerData] = useState({});
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState([]);
  const [isPositionOpen, setIsPositionOpen] = useState(false);

  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker);
  };
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Access token or user ID not found. Please log in again.",
            life: 3000,
          });
          setLoading(false);
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
          setSelectedBroker(response.data.broker_details ? response.data.broker_details.broker : null);

          setActiveSubscription(response.data.active_subscription);
          setIsPositionOpen(response.data.is_position_open);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.msg,
            life: 3000,
          });
        }
      } catch (error) {
        console.clear();
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Access token or user ID not found. Please log in again.",
          life: 3000,
        });
        setLoading(false);
        navigate("/commonlogin");
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/get_profile_details`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        setUserData(response.data.user_details);
        setBrokerData(response.data.broker_details);
        setFormData({
          ...response.data.user_details,
          ...response.data.broker_details,
        });
        setSelectedBroker(response.data.broker_details ? response.data.broker_details.broker : null);

        setActiveSubscription(response.data.active_subscription);
        setIsPositionOpen(response.data.is_position_open);

      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
          life: 3000,
        });
      }
    } catch (error) {
      console.clear();
      let errorMsg = "Failed to fetch data";
      if (error.response) {
        errorMsg = error.response.data.msg
          ? formatMessage(error.response.data.msg)
          : "An error occurred";
      } else if (error.message) {
        errorMsg = error.message;
      }
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMsg,
        life: 3000,
      });
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleConnectionStatus = (status) => {};
  const toLowerCase = (string) => {
    return string.toLowerCase();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "lot_size_limit") {
      if (/^\d*$/.test(value)) {
        if (parseInt(value, 10) > 5000) {
          setError("Maximum limit is 5000");
          setFormData((prevData) => ({
            ...prevData,
            [name]: 5000,
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Maximum limit is 5000",
          }));
        } else {
          setError("");
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      } else {
        setError("Only digits are allowed");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only digits are allowed",
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (
        name === "name" &&
        !/\d/.test(value) &&
        !/[^a-zA-Z\s]/.test(value) &&
        value.length >= 3 &&
        value.length <= 50
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          name: "",
        }));
      } else if (
        name === "email" &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      } else if (name === "mobile" && /^\d{10}$/.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "",
        }));
      } else if (
        name === "commission" &&
        /^[0-9%]+$/.test(value) &&
        !(value.includes("%") && value.length === 1)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          commission: "",
        }));
      }

      if (name === "tradingPower") {
        setIsTradingPowerEditable(true);
      }
    }
  };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/g;

    if (!formData.name) {
      tempErrors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(formData.name)) {
      tempErrors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(formData.name)) {
      tempErrors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (formData.name.length < 3) {
      tempErrors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (formData.name.length > 50) {
      tempErrors.name = "Name must be at most 50 characters";
      isValid = false;
    }

    if (!formData.email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.mobile) {
      tempErrors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formData.mobile)) {
      tempErrors.mobile = "Mobile number must contain only digits";
      isValid = false;
    } else if (formData.mobile.length !== 10) {
      tempErrors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }

    if (formData.lot_size_limit === "") {
      tempErrors.lot_size_limit = "Lot size limit is required";
      isValid = false;
    } else if (isNaN(formData.lot_size_limit)) {
      tempErrors.lot_size_limit = "Lot size limit must be a number";
      isValid = false;
    } else {
      const lotSizeLimit = parseInt(formData.lot_size_limit, 10);
      if (lotSizeLimit < 1 || lotSizeLimit > 5000) {
        tempErrors.lot_size_limit = "Lot size limit must be between 1 and 5000";
        isValid = false;
      }
    }

    if (!formData.commission) {
      tempErrors.commission = "Commission is required";
      isValid = false;
    } else if (!/^[0-9%]+$/.test(formData.commission)) {
      tempErrors.commission =
        "Commission must only contain digits and the '%' character";
      isValid = false;
    } else if (
      formData.commission.includes("%") &&
      formData.commission.length === 1
    ) {
      tempErrors.commission = "Commission cannot be just '%'";
      isValid = false;
    }

    setFormErrors(tempErrors);
    return isValid;
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");

      if (!authToken) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Access token not found. Please log in again.",
          life: 3000,
        });
        setLoading(false);
        navigate("/commonlogin");
        return;
      }

      const response = await axios.put(
        `${config.apiDomain}/api/common/save_profile_details`,
        {
          user_id: localStorage.getItem("userId"),
          email: formData.email,
          mobile: formData.mobile,
          name: formData.name,
          lot_size_limit: formData.lot_size_limit,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        setUserData(formData);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
          life: 3000,
        });
      }
    } catch (error) {
      let errorMsg = "Failed to fetch data";

      if (error.response) {
        errorMsg = error.response.data.msg
          ? formatMessage(error.response.data.msg)
          : "An error occurred";
      } else if (error.message) {
        errorMsg = error.message;
      }

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMsg,
        life: 3000,
      });

      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBrokerInformation = async (e) => {
    e.preventDefault();

  
    if (!validateForm()) {
      return; 
    }

    setIsLoading(true);

    try {
      const authToken = localStorage.getItem("authToken");

      if (!authToken) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Access token not found. Please log in again.",
          life: 3000,
        });
        setLoading(false);
        navigate("/commonlogin");
        return;
      }

     
      let payload;
      if (selectedBroker === "angle_one") {
        payload = {
          user_id: localStorage.getItem("userId"),
          client_id: formData.client_id,
          password: formData.password,
          qr_totp_token: formData.qr_totp_token,
          api_key: formData.api_key,
          broker: "angle_one",
        };
      } else if (selectedBroker === "dhan") {
        payload = {
          user_id: localStorage.getItem("userId"),
          dhan_client_id: formData.dhan_client_id,
          access_token: formData.access_token,
          broker: "dhan",
        };
      }

      const response = await axios.put(
        `${config.apiDomain}/api/common/save_broker_details`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        setUserData(formData);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
          life: 3000,
        });
      } else {
        let errorMsg = response.data.msg || "An error occurred";
        if (response.data.st === 2) {
          errorMsg = `Error: ${response.data.msg}. ${formatMessage(
            response.data.details
          )}`;
        }

        if (errorMsg === "Token expired") {
          errorMsg = "Session expired. Please log in again.";
          navigate("/commonlogin");
        }

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } catch (error) {
      let errorMsg = "Error updating user profile";

      if (error.response) {
        if (error.response.status === 401) {
          errorMsg = "Session expired. Please log in again.";
          navigate("/commonlogin");
        } else {
          errorMsg =
            formatMessage(error.response.data.msg || error.response.data) ||
            "An error occurred";
        }
      } else if (error.message) {
        errorMsg = error.message;
      }

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMsg,
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const toTitleCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    userData && userData.broker_account_exchanges
      ? formatExchanges(userData.broker_account_exchanges)
      : [];

      const brokerNameMap = {
        angle_one: "AngelOne",
        dhan:"Dhan"
      };
    
      const getBrokerDisplayName = (brokerName) => {
        return brokerNameMap[brokerName] || brokerName;
      };
  return (
    <div>
      <Header></Header>
      <SubHeader></SubHeader>
      <Toast ref={toast} position="top-right" />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className=" layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl  flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
                <div className="d-flex justify-content-between align-items-center">
                  <ol className="breadcrumb breadcrumb-style1 text-black">
                    <li className="breadcrumb-item">
                      <Link to="/teacher/dashboard" className="text-black">
                        <i className="ri-home-7-line ri-lg"></i>
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-secondary"
                      aria-current="page"
                    >
                      Profile
                    </li>
                  </ol>
                  <SubscriptionBadge></SubscriptionBadge>
                </div>
              </nav>

           
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body pt-0">
                        <div className="w-px-40 h-auto mx-auto mt-3 rounded-circle">
                          <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-xl">
                            <span className="avatar-initial rounded-circle bg-label-primary">
                              {" "}
                              {userData.name &&
                                userData.name
                                  .split(" ")
                                  .filter(
                                    (word, index) =>
                                      index === 0 ||
                                      index ===
                                        userData.name.split(" ").length - 1
                                  )
                                  .map((word) => word.charAt(0).toUpperCase())
                                  .join("")}
                            </span>
                          </div>
                        </div>
                        <div className="user-info  mt-3 text-center">
                          <h5 className="">{toTitleCase(userData.name)}</h5>
                          <span className="btn btn-outline-secondary rounded-pill btn-xs text-capitalize">
                            {" "}
                            {toTitleCase(userData.role)}
                          </span>
                        </div>

                        <ul className="list-unstyled my-3 py-1">
                        {brokerData && brokerData.broker && selectedBroker && (
        <li className="d-flex justify-content-between align-items-center mb-4">
          <strong>Broker</strong>
          <span className="ml-auto">
            {getBrokerDisplayName(selectedBroker)}
          </span>
        </li>
      )}
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Broker Connection:</strong>
                            <span className="text-success ml-auto">
                              <div className="ms-auto">
                                <div
                                  className={`text-success ml-auto ${
                                    userData.broker_conn_status
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  onClick={() =>
                                    handleConnectionStatus(
                                      !userData.broker_conn_status
                                    )
                                  }
                                >
                                  {userData.broker_conn_status ? (
                                    <>
                                      <i className="ri-shield-check-line"></i>{" "}
                                      Connected
                                    </>
                                  ) : (
                                    <>
                                      <i className="ri-close-large-line"></i>{" "}
                                      Not Connected
                                    </>
                                  )}
                                </div>
                              </div>
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Broker Acc. Balance:</strong>
                            <span className="ml-auto">
                              {(userData.broker_account_fund || 0).toFixed(2)}{" "}
                              Rs.
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Commission:</strong>
                            <span className="ml-auto">
                              {userData.commission}%
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-7">
                            <strong>Lot Size Limit:</strong>
                            <span className="ml-auto ">
                              {userData.lot_size_limit} Lot
                            </span>
                          </li>
                          {formattedExchanges.length > 0 && (
                            <li className="d-flex justify-content-between align-items-center">
                              <strong>Segments</strong>
                              <span className="ml-auto">
                                <ul className="list-unstyled">
                                  {formattedExchanges.map((exchange, index) => (
                                    <li key={index}>{exchange}</li>
                                  ))}
                                </ul>
                              </span>
                            </li>
                          )}
                        </ul>

                        <hr />
                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex flex-column align-items-start mb-4">
                            <span className="fw-medium fs-5">Contacts</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Email:</strong>
                            <span className="ml-auto">{userData.email}</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Mobile:</strong>
                            <span className="ml-auto">{userData.mobile}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {activeSubscription && activeSubscription.length > 0 && (
                      <div className="row mt-4">
                        {activeSubscription.map((subscription, index) => (
                          <div key={index} className="col-md-12 mb-4">
                            <div className="card border border-2 border-primary rounded">
                              <div className="card-body">
                                <div className="d-flex justify-content-between align-items-start">
                                  <span className="badge bg-label-primary rounded-pill text-capitalize">
                                    {subscription.plan_name}
                                  </span>
                                  <div className="d-flex justify-content-center">
                                    <sub className="h5 pricing-currency mb-auto mt-1 text-primary">
                                      ₹
                                    </sub>
                                    <h1 className="mb-0 text-primary">
                                      {subscription.pricing}
                                    </h1>
                                  </div>
                                </div>
                                <ul className="list-unstyled g-2 my-6">
                                  <li className="mb-2 d-flex align-items-center">
                                    <i className="ri-circle-fill ri-10px text-body text-capitalize me-2"></i>
                                    <span className="text-capitalize fs-5">
                                      <strong>
                                        {subscription.subscription_name}
                                      </strong>
                                    </span>
                                  </li>
                                  <li className="mb-2 d-flex align-items-center">
                                    <i className="ri-circle-fill ri-10px text-body text-capitalize me-2"></i>
                                    <span className="text-capitalize">
                                      {subscription.plan_description}
                                    </span>
                                  </li>
                                  {subscription.max_user_limit && (
                                    <li className="mb-2 d-flex align-items-center">
                                      <i className="ri-circle-fill ri-10px text-body me-2"></i>
                                      <span>
                                        Max Users:{" "}
                                        <strong>
                                          {subscription.max_user_limit}
                                        </strong>
                                      </span>
                                    </li>
                                  )}

                                  <li className="mb-2 d-flex align-items-center">
                                    <i className="ri-circle-fill ri-10px text-body me-2"></i>
                                    <span>
                                      Service Period:{" "}
                                      <strong>
                                        {subscription.service_period}
                                      </strong>
                                    </span>
                                  </li>
                                </ul>
                                <div>
                                  <div className="d-flex justify-content-between align-items-center mb-1">
                                    <span className="h6 mb-0">
                                      Current plan expiry on
                                    </span>
                                    <span className="h6 mb-0">
                                      {subscription.expiry_date}
                                    </span>
                                  </div>
                                  <div className="progress mb-1 rounded h-6 h-100">
                                    <div
                                      className="progress-bar bg-success rounded-start"
                                      role="progressbar"
                                      style={{
                                        width: `${
                                          (subscription.completed_days /
                                            subscription.service_period) *
                                          100
                                        }%`,
                                      }}
                                      aria-valuenow={
                                        subscription.completed_days
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax={
                                        subscription.service_period
                                      }
                                    >
                                      {subscription.completed_days} days
                                      completed
                                    </div>
                                    <div
                                      className="progress-bar bg-primary rounded-end"
                                      role="progressbar"
                                      style={{
                                        width: `${
                                          (subscription.remaining_days /
                                            subscription.service_period) *
                                          100
                                        }%`,
                                      }}
                                      aria-valuenow={
                                        subscription.remaining_days
                                      }
                                      aria-valuemin="0"
                                      aria-valuemax={
                                        subscription.service_period
                                      }
                                    >
                                      {subscription.remaining_days} days
                                      remaining
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="col-md-8">
                    <div className="row">
                    <div className="col-md-8 col-sm-7">
  <div className="nav-align-top">
    <ul className="nav nav-pills flex-row justify-content-center flex-wrap mb-3 row-gap-1">
      <li className="nav-item active">
        <Link
          to="/teacher/user_profile"
          className="nav-link active btn btn-primary"
        >
          <i className="ri-user-3-line me-1_5"></i>Profile
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/teacher/user_profile_Timeline"
          className="nav-link"
        >
          <i className="ri-timeline-view me-1_5"></i>
          Timeline
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/teacher/user_profile_login_log"
          className="nav-link"
        >
          <i className="ri-news-line me-1_5"></i>Login log
        </Link>
      </li>

      <li className="nav-item">
        <Link
          to="/teacher/user_profile_activity_log"
          className="nav-link"
        >
          <i className="ri-file-list-2-line me-1_5"></i>Activity log
        </Link>
      </li>
    </ul>
  </div>
</div>


                      <div className="card p-6">
                        <div className="card-body pt-0">
                          <form id="formAccountSettings" method="POST">
                            <div className="row mt-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="fw-medium fs-5 text-start mb-5">
                                  <i className="ri-user-line ri-ms me-1"></i>
                                  Personal Information
                                </span>

                                {loading ? (
                                  <div className="d-flex align-items-center">
                                    <div
                                      className="spinner-border spinner-border-sm custom-spinner ms-3"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="">
                                       <Tooltip target=".custom-target-icon" />
                                    <i
                                      className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary"
                                      role="button"
                                      data-pr-tooltip="Reload"
                                      onClick={handleRefresh}
                                      data-pr-position="top"
                                    ></i>
                                  </div>
                                )}
                              </div>

                              <div className="col-md-4 mt-5">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      className={`form-control ${
                                        formErrors.name ? "is-invalid" : ""
                                      }`}
                                      type="text"
                                      id="name"
                                      name="name"
                                      value={formData.name || ""}
                                      placeholder="Name"
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="name">
                                      {" "}
                                      <span className="text-danger">
                                        *{" "}
                                      </span>{" "}
                                      Name{" "}
                                    </label>
                                    {formErrors.name && (
                                      <div className="invalid-feedback">
                                        {formErrors.name}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mt-5">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      className={`form-control ${
                                        formErrors.email ? "is-invalid" : ""
                                      }`}
                                      type="text"
                                      id="email"
                                      name="email"
                                      value={formData.email || ""}
                                      placeholder="E-mail"
                                      required
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="email">
                                      {" "}
                                      <span className="text-danger">* </span>
                                      E-mail{" "}
                                    </label>
                                    {formErrors.email && (
                                      <div className="invalid-feedback">
                                        {formErrors.email}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mt-5">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      id="mobile"
                                      name="mobile"
                                      className={`form-control ${
                                        formErrors.mobile ? "is-invalid" : ""
                                      }`}
                                      value={formData.mobile || ""}
                                      placeholder="Mobile Number"
                                      onChange={handleChange}
                                      maxLength="10"
                                    />
                                    <label htmlFor="mobile">
                                      <span className="text-danger">* </span>
                                      Mobile Number{" "}
                                    </label>
                                    {formErrors.mobile && (
                                      <div className="invalid-feedback">
                                        {formErrors.mobile}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mt-5">
                                <div className="input-group input-group-merge">
                                  <div className="form-floating form-floating-outline">
                                    <input
                                      className={`form-control ${
                                        formErrors.lot_size_limit
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      type="text"
                                      id="lot_size_limit"
                                      name="lot_size_limit"
                                      value={formData.lot_size_limit || ""}
                                      placeholder="Lot Size Limit"
                                      onChange={handleChange}
                                      maxLength="5000"
                                    />
                                    <label htmlFor="lot_size_limit">
                                      <span className="text-danger">* </span>
                                      Lot Size Limit{" "}
                                    </label>
                                    {formErrors.lot_size_limit && (
                                      <div className="invalid-feedback">
                                        {formErrors.lot_size_limit}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="mt-6 text-end">
                                <button
                                  onClick={handleSubmit}
                                  className="btn btn-success rounded-pill  text-end me-3"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <div
                                      className="spinner-border me-2"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                  )}{" "}
                                  {loading ? "Saving Data" : "Save Data"}
                                </button>
                              </div>
                            </div>
                            </form>
                            </div>
                            </div>
                            <>
    {/* Conditionally render based on is_position_open */}
    {isPositionOpen ? (
      <div
      className="p-3 mt-3"
      style={{
        backgroundColor: '#f9f1e4',
        border: '1px solid #ffc107',
        borderRadius: '4px',
      }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <h5 className="text-start mb-0" >
          <i className="ri-alert-line me-2"></i> Position is Open, Can Not Update Broker Details.
        </h5>
      </div>
      <p className="text-start mt-3" >
        There is an open position. Please close the position before updating broker details.
      </p>
      <div className="text-end">
        <Link
          to="/trade_position"
          className="btn btn-primary rounded-pill"
        
        >
          Go to Position Page
        </Link>
      </div>
    </div>
    ) : (
                            <div className="card p-6 mt-4">
                            <div className="card-body pt-0">
                              <h5 className="text-start">
                                {" "}
                                <i className="ri-group-line ri-ms me-2"></i>
                                Broker Information
                              </h5>
                              <div className="nav-align-top">
                                <ul className="nav nav-tabs" role="tablist">
                                  <li className="nav-item me-5">
                                    <div
                                      className={` ${
                                        selectedBroker === "angle_one"
                                          ? "border-bottom  border-primary text-primary pb-2 border-4"
                                          : "text-black"
                                      }`}
                                      onClick={() =>
                                        handleBrokerChange("angle_one")
                                      }
                                    >
                                      AngleOne
                                    </div>
                                  </li>

                                  <li className="nav-item">
                                    <div
                                      className={` ${
                                        selectedBroker === "dhan"
                                          ? "border-bottom border-primary text-primary pb-2 border-4"
                                          : "text-black"
                                      }`}
                                      onClick={() => handleBrokerChange("dhan")}
                                    >
                                      Dhan
                                    </div>
                                  </li>
                                </ul>

                                <div className="row mt-5">
                                  {selectedBroker === "angle_one" && (
                                    <>
                                      <div className="col-md-4 mt-5">
                                        <div className="input-group input-group-merge">
                                          <div className="form-floating form-floating-outline">
                                            <input
                                              type="text"
                                              className={`form-control ${
                                                formErrors.brokerClientId
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              id="client_id"
                                              name="client_id"
                                              placeholder="Broker Client ID"
                                              value={formData.client_id || ""}
                                              onChange={handleChange}
                                            />
                                            <label htmlFor="client_id">
                                              {" "}
                                              <span className="text-danger">
                                                *{" "}
                                              </span>
                                              Broker Client ID
                                            </label>
                                            {formErrors.brokerClientId && (
                                              <div className="invalid-feedback">
                                                {formErrors.brokerClientId}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4 mt-5">
                                        <div className="input-group input-group-merge">
                                          <div className="form-floating form-floating-outline">
                                            <input
                                              type="text"
                                              className={`form-control ${
                                                formErrors.brokerPassword
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              id="password"
                                              name="password"
                                              placeholder="Broker Password"
                                              value={formData.password || ""}
                                              onChange={handleChange}
                                            />
                                            <label htmlFor="password">
                                              <span className="text-danger">
                                                *{" "}
                                              </span>
                                              Broker Password
                                            </label>
                                            {formErrors.brokerPassword && (
                                              <div className="invalid-feedback">
                                                {formErrors.brokerPassword}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 mt-5">
                                        <div className="input-group input-group-merge">
                                          <div className="form-floating form-floating-outline">
                                            <input
                                              type="text"
                                              className={`form-control ${
                                                formErrors.brokerQrTotpToken
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              id="qr_totp_token"
                                              name="qr_totp_token"
                                              value={
                                                formData.qr_totp_token || ""
                                              }
                                              placeholder="Broker QR TOTP Token"
                                              onChange={handleChange}
                                            />
                                            <label htmlFor="qr_totp_token">
                                              <span className="text-danger">
                                                *{" "}
                                              </span>
                                              Broker QR TOTP Token
                                            </label>
                                            {formErrors.brokerQrTotpToken && (
                                              <div className="invalid-feedback">
                                                {formErrors.brokerQrTotpToken}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 mt-4">
                                        <div className="input-group input-group-merge">
                                          <div className="form-floating form-floating-outline">
                                            <input
                                              type="text"
                                              className={`form-control ${
                                                formErrors.brokerApiKey
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              name="api_key"
                                              placeholder="Broker API Key"
                                              value={formData.api_key || ""}
                                              onChange={handleChange}
                                              autoComplete="api_key"
                                            />
                                            <label htmlFor="api_key">
                                              <span className="text-danger">
                                                *{" "}
                                              </span>
                                              Broker API Key
                                            </label>
                                            {formErrors.brokerApiKey && (
                                              <div className="invalid-feedback">
                                                {formErrors.brokerApiKey}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  {selectedBroker === "dhan" && (
                                    <>
                                      <div className="col-md-4 mt-5">
                                        <div className="input-group input-group-merge">
                                          <div className="form-floating form-floating-outline">
                                            <input
                                              type="text"
                                              className="form-control "
                                              id="dhan_client_id"
                                              name="dhan_client_id"
                                              placeholder="Broker Client ID"
                                              value={
                                                formData.dhan_client_id || ""
                                              }
                                              onChange={handleChange}
                                            />
                                            <label htmlFor="dhan_client_id">
                                              {" "}
                                              <span className="text-danger">
                                                *{" "}
                                              </span>
                                              Broker Client ID
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-4 mt-5 ">
                                        <div className="input-group input-group-merge">
                                          <div className="form-floating form-floating-outline">
                                            <input
                                              type="text"
                                              className="form-control "
                                              id="access_token"
                                              name="access_token"
                                              placeholder="Access Token"
                                              value={
                                                formData.access_token || ""
                                              }
                                              onChange={handleChange}
                                            />
                                            <label htmlFor="access_token">
                                              <span className="text-danger">
                                                *{" "}
                                              </span>
                                              Broker Access Token
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="mt-6 text-end">
                              <button
                                onClick={handleBrokerInformation}
                                className="btn btn-success rounded-pill  text-end me-3"
                                disabled={IsLoading}
                              >
                                {IsLoading ? (
                                  <div
                                    className="spinner-border me-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {IsLoading ? "Saving Data" : "Save Data"}
                              </button>
                            </div>
                       
                     
                        
                      </div>
    )}
 


    </>
                    </div>
                  </div>
                </div>
             
              <CardLinks></CardLinks>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Profile;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
