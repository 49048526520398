import React from "react";
import { Link } from "react-router-dom";
import autoprofito_logo from "../../../assets/img/products/company/autoprofito_logo.png";

import { useEffect, useState } from "react";
import axios from "axios";

import FaqSection from "./FaqSection";
import MetaTags from "./MetaTags";

const LandingFooter = () => {
  return (
    <>
      <MetaTags />

      <div data-bs-spy="scroll" className="scrollspy-example ">
        <div className="pt-4">
          <div className="container-xxl my-6 h-px-300 gradient-bg-2 border rounded shadow d-flex align-items-center justify-content-center">
            <div className="text-center">
              <h4 className="text-primary mb-2">
                Still not convinced? Start with a 14-day FREE trial!
              </h4>
              <p className="text-body mb-6 mb-md-11">
                You will get full access to with all the features for 14 days.
              </p>
              <Link to="/contact" className="btn btn-primary">
                Contact us
              </Link>
            </div>
          </div>
        </div>

        <FaqSection />

        <div className="container-xxl gradient-bg-1 my-6 h-px-300 border rounded shadow d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h2 className="display-5 text-primary fw-bold mb-0 mt-3">
              Ready to Get Started?
            </h2>
            <p className="fw-medium mb-6 mb-md-8">
              Start your project with a 14-day free trial
            </p>
            <Link to="/contact" className="btn btn-primary">
              Contact us
              <i className="ri-arrow-right-line ri-16px ms-2 scaleX-n1-rtl"></i>
            </Link>
          </div>
        </div>

        <div className="landing-footer">
          <div className="footer-top  bg-black position-relative gradient-bg overflow-hidden mb-0">
            <div className="container-xxl position-relative">
              <div className="row gx-0 gy-0 mb-0 gx-sm-12 gx-lg-12">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="app-brand-link mb-6">
                    <div className="">
                      <img src={autoprofito_logo} alt="Favicon" width="44px" />
                    </div>
                    <span className="app-brand-text demo footer-link fw-semibold ms-1 px-3">
                      AutoProfito
                    </span>
                  </div>
                  <div className="footer-text px-0 footer-logo-description">
                    <p>
                      {" "}
                      AutoProfito (Product of Shekru Labs India Pvt. Ltd.) is a
                      technology provider and not a wealth manager, investment
                      advisor, or portfolio management service. All transactions
                      through AutoProfito are the user's responsibility, and we
                      cannot be held liable for any user actions.
                    </p>
                    <p>
                      We strive for transparency, but the responsibility for any
                      selection or transaction rests with the user.
                    </p>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-6 col-6 mt-0 mt-5 mt-sm-0">
                  <h6 className="footer-title mb-4 mb-lg-6 fs-4">Pages</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-4">
                      <Link to="/" className="footer-link">
                        Home
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/about" className="footer-link">
                        About
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/contact" className="footer-link">
                        Contact
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/help_center" className="footer-link">
                        FAQ
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/pricing" className="footer-link">
                        Pricing
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6 mt-0 mt-5 mt-sm-0 ">
                  <h6 className="footer-title mb-4 mb-lg-6 fs-4 ">Legal</h6>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-4">
                      <Link to="/privacy_policy" className="footer-link">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/cookies_policy" className="footer-link">
                        Cookies Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/cancellation_policy" className="footer-link">
                        Cancellation Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link
                        to="/refund_policy"
                        className="footer-link refundpolicy"
                      >
                        Refund Policy
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to="/terms_condition" className="footer-link">
                        Terms and Condition
                      </Link>
                    </li>
                    <li>
                      <Link to="/sebi-circular" className="footer-link">
                      SEBI Circular
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12  mt-0 mt-5 mt-sm-0">
                  <h6 className="footer-title mb-4 mb-lg-6 fs-4">Contact</h6>

                  <p className="footer-text px-0 footer-logo-description">
                    <i className="ri-map-pin-line ri-lg text-white"></i> Shekru
                    Labs India Pvt. Ltd., 2nd Floor, Manogat Appt Muktangan
                    English School & Jr College, Sahakar Nagar, Pune, 411009
                  </p>

                  <div className="">
                    <p className="footer-text px-0 footer-logo-description">
                      <i className="ri-mail-line ri-lg text-white"></i>{" "}
                      shekrulabs@gmail.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center col-12  mt-0 mt-5 mt-sm-0">
              <a
                href="https://www.facebook.com/share/ra9cKRDkDpy2W94j/?mibextid=qi2Omg"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-facebook-circle-fill  ri-2x"
                  style={{ color: "#1877F2" }}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/autoprofito/?next=%2F"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-instagram-line  ri-2x"
                  style={{ color: "#de45a8" }}
                ></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-youtube-line  ri-2x"
                  style={{ color: "#FF0000" }}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/company/104616702/admin/dashboard/"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-linkedin-fill ri-2x"
                  style={{ color: "#0077B5" }}
                ></i>
              </a>
              <a
                href="https://www.threads.net/@autoprofito"
                className="me-4"
                target="_blank"
              >
                <i
                  className="ri-threads-line  ri-2x"
                  style={{ color: "white" }}
                >
                  {" "}
                </i>
              </a>
              <a
                href="https://www.threads.net/@autoprofito"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-twitter-x-line  ri-2x"
                  style={{ color: "grey" }}
                >
                  {" "}
                </i>
              </a>
              <a
                href="https://t.me/Autoprofito"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-telegram-line  ri-2x"
                  style={{ color: "#0088CC" }}
                >
                  {" "}
                </i>
              </a>
              <a
                href="https://pin.it/4TpIUsHzB"
                className=" me-4"
                target="_blank"
              >
                <i
                  className="ri-pinterest-line  ri-2x"
                  style={{ color: "#E60023" }}
                >
                  {" "}
                </i>
              </a>
            </div>
          </div>

          <div className="footer-bottom bg-body">
            <div className="container-xxl d-flex flex-wrap justify-content-center flex-md-row flex-column text-center text-md-center">
              <div className="text-center mt-2">
                <p className="text-black mb-1 fs-8">
                  Shekru Labs India Pvt. Ltd., 2nd Floor, Manogat Appt.,
                  Muktangan English School & Jr College, Sahakar Nagar, Pune,
                  411009
                </p>
                <p className=" text-black mb-1 fs-8 d-inline-block">
                  Email: shekrulabs@gmail.com
                </p>
                <p className="text-black fs-8 d-inline-block mx-3">|</p>
                <p className="text-black fs-8 d-inline-block">
                  CIN: U74999PN2018PTC174371
                </p>
              </div>
            </div>
          </div>

          <div className="footer-bottom py-5">
            <div className="container-xxl d-flex flex-wrap justify-content-between flex-md-row flex-column text-center ">
              <div className="mb-2 mb-md-0">
                <span className="footer-text">
                  <p> &copy; Copyright All Rights Reserved.</p>
                </span>
              </div>
              <div className="mb-2 mb-md-0">
                <a
                  href="https://www.facebook.com/share/ra9cKRDkDpy2W94j/?mibextid=qi2Omg"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-facebook-circle-fill  ri-xl"></i>
                </a>
                <a
                  href="https://www.instagram.com/autoprofito/?next=%2F"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-instagram-line  ri-xl"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A "
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-youtube-line  ri-xl"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/104616702/admin/dashboard/"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-linkedin-fill  ri-xl "></i>
                </a>
                <a
                  href="https://www.threads.net/@autoprofito"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-threads-line  ri-xl"> </i>
                </a>
                <a
                  href="https://www.threads.net/@autoprofito"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-twitter-x-line  ri-xl"> </i>
                </a>
                <a
                  href="https://t.me/Autoprofito"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-telegram-line  ri-xl"> </i>
                </a>
                <a
                  href="https://pin.it/4TpIUsHzB"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-pinterest-line  ri-xl"> </i>
                </a>
              </div>

              <div className="mb-2 mb-md-0">
                <span>
                  <p>
                    Powered by{" "}
                    <a href="https://www.shekruweb.com" target="_blank">
                      Shekru Labs India Pvt. Ltd.
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
