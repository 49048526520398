import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccessful = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/teacher/payment_history");
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card text-center p-5 shadow-lg w-100%">
        <div className="card-body">
          <h1 className="card-title text-success mb-4">Payment Successful</h1>
          <p className="card-text mb-4">
            Thank you for your payment. Your transaction was successful.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
