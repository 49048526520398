


import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import Footer from "../../../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../component/config";
import back from "../../../assets/img/icons/misc/triangle-light.png";
import image from "../../../assets/img/illustrations/illustration-upgrade-account-2.png";
import StudentHeader from "./StudentHeader";
import SubHeaderS from "./SubHeaderS";

const ProfileReports = () => {
  const [userData, setUserData] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const toast = useRef(null);
  const [brokerData, setBrokerData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
         
          setLoading(false);
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.msg,
            life: 3000,
          });
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleConnectionStatus = (status) => {
    console.log("Connection status:", status);
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };
  return (
    <div>
      <div>
       <StudentHeader></StudentHeader>
       <SubHeaderS></SubHeaderS>
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper">
                <div className="container flex-grow-1 container-p-y">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-style1">
                      <li className="breadcrumb-item">
                        <Link to="/student/dashboard">
                          {" "}
                          <i className="ri-home-7-line ri-lg"></i>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Profile
                      </li>
                    </ol>
                  </nav>

                
                  {userData && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body pt-0">
                          <div className="user-info mt-3 text-center">
                            <h5>{capitalizeFirstLetter(userData.name)}</h5>
                            <span className="badge bg-label-info rounded-pill">
                              {capitalizeFirstLetter(userData.role)}
                            </span>
                          </div>
                          <ul className="list-unstyled my-3 py-1">
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Broker Connection:</strong>
                              <span className="text-success ml-auto">
                                <div className="ms-auto">
                                  <div
                                    className={`text-success ml-auto ${
                                      userData.broker_conn_status
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                    onClick={() =>
                                      handleConnectionStatus(
                                        !userData.broker_conn_status
                                      )
                                    }
                                  >
                                    {userData.broker_conn_status ? (
                                      <>
                                        <i className="ri-shield-check-line"></i>{" "}
                                        Connected
                                      </>
                                    ) : (
                                      <>
                                        <i className="ri-close-large-line"></i>{" "}
                                        Not Connected
                                      </>
                                    )}
                                  </div>
                                </div>
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Broker Acc. Balance:</strong>
                              <span className="ml-auto">
                                {userData.amount} Rs.
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Commission:</strong>
                              <span className="ml-auto">
                                {userData.commission}%
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Lot Size Limit:</strong>
                              <span className="ml-auto">
                                {userData.lot_size_limit} Lot
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Segments:</strong>
                              <span className="ml-auto">
                                {userData.exchanges}
                              </span>
                            </li>
                          </ul>
                          <hr />
                          <ul className="list-unstyled my-3 py-1">
                            <li className="d-flex flex-column align-items-start mb-4">
                              <span className="fw-medium fs-5">Contacts</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Email:</strong>
                              <span className="ml-auto">{userData.email}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Mobile:</strong>
                              <span className="ml-auto">{userData.mobile}</span>
                            </li>
                          </ul>
                          <hr />
                          <ul className="list-unstyled my-3 py-1"></ul>
                        </div>
                      </div>
                    </div>
                      <div className="col-md-8">
                        <div className="row">
                        {/* <div className="col-md-8 col-sm-7">
                        <div class="nav-align-top">
                          <ul class="nav nav-pills flex-column justify-content-start flex-md-row flex-wrap mb-6 row-gap-1">
                          <li className="nav-item  ">
                            <Link
                              to="/app2/student_profile"
                              className="nav-link  "
                            >
                              <i className="ri-user-3-line me-1_5"></i>Profile
                            </Link>
                          </li>
                          <li className="nav-item active">
                            <Link
                              to="/student/report"
                              className="nav-link active btn btn-primary "
                            >
                              <i className="ri-team-line me-1_5"></i>Reports
                            </Link>
                          </li>
                          
                       
                    <li className="nav-item">
                      <Link to="/student/login_activity_log" className="nav-link " >
                        <i className="ri-team-line me-1_5"></i>Activity log
                      </Link>
                    </li>

                          </ul>
                        </div>
                      </div> */}

                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="card">
                                  <div className="card-body">
                                    <h5 className="card-title mb-5">
                                      My Reports
                                    </h5>
                                    <h4 className="text-light mb-0">2,2199</h4>
                                    <p className="mb-3">Profit This Month</p>
                                    <Link
                                      to="/app2/student_my_report"
                                      className="btn btn-sm btn-primary"
                                    >
                                      <i className="ri-user-follow-fill ri-md me-2">
                                        {" "}
                                      </i>
                                      My Reports
                                    </Link>
                                  </div>
                                  <img
                                    src={back}
                                    className="scaleX-n1-rtl position-absolute bottom-0 end-0"
                                    width="166"
                                    alt="triangle background"
                                    data-app-light-img="icons/misc/triangle-light.png"
                                    data-app-dark-img="icons/misc/triangle-dark.png"
                                  />
                                  <img
                                    src={image}
                                    className="scaleX-n1-rtl position-absolute bottom-0 end-0 me-5 mb-3"
                                    height="176"
                                    alt="Upgrade Account"
                                  />
                                </div>
                              </div>
                             
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileReports;
