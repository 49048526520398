import SubHeader from "../component/SubHeader";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";

import { VirtualScroller } from "primereact/virtualscroller";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const TimeLine = () => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [userData, setUserData] = useState({});
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [brokerData, setBrokerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);

  const [formData, setFormData] = useState(null);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          setLoading(false);
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.msg,
            life: 3000,
          });
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    setIsLoadings(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.get(
        `${config.apiDomain}/api/teacher/generate_report_log`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        setReportData(response.data.activity[0]);
      } else {
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
        
        navigate("/commonlogin");
      }
    } finally {
      setIsLoadings(false);
    }
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString("en-US", options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString("default", { month: "short" });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };

  const generateReport = async () => {
    const authToken = localStorage.getItem("authToken");
    setLoadings(true);

    try {
      const response = await axios.get(
        `${config.apiDomain}/api/teacher/generate_report`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg || "Report generated successfully",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg || "Failed to generate report",
        });
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin");
      } else {
        const errorMsg =
          error.response?.data?.msg || "Failed to generate report";
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
        });
      }
    } finally {
      setLoadings(false);
    }
  };

  const loadInstrument = async () => {
   
    setLoading(true);

    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.get(
        `${config.apiDomain}/api/teacher/get_instrument`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
        });
      }
    } catch (error) {
      console.clear(); 

      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInstrumentLog = async () => {
      setIsLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          throw new Error("Auth token not found");
        }

        const response = await axios.get(
          `${config.apiDomain}/api/teacher/get_instrument_log`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setActivities(response.data.activity);
        } else {
      
        }
      } catch (error) {
        

        if (error.response && error.response.status === 401) {
          
          navigate("/commonlogin");
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchInstrumentLog();
  }, [navigate]);

  const itemTemplate = (activity, options) => {
    return (
      <div key={options.index} className="mb-2 text-start">
        <small
          className={`mb-1 d-inline ${
            activity.status ? "text-success" : "text-danger"
          }`}
        >
          <small className="text-muted">Status:</small>{" "}
          {activity.status ? "Complete" : "Incomplete"}
        </small>
        <br></br>
        <p className="d-inline">
          Datetime: {formatDateTime(activity.datetime)}{" "}
          {formatDateDay(activity.datetime)}-
          {formatDateMonth(activity.datetime)}-
          {formatDateYear(activity.datetime)}
        </p>
      </div>
    );
  };

  const handleConnectionStatus = (status) => {
   
  };
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    userData && userData.broker_account_exchanges
      ? formatExchanges(userData.broker_account_exchanges)
      : [];

  return (
    <div>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
              <div className="d-flex justify-content-between align-items-center">
                <ol className="breadcrumb breadcrumb-style1 text-black">
                  <li className="breadcrumb-item">
                    <Link to="/teacher/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Profile
                  </li>
                </ol>
                <SubscriptionBadge></SubscriptionBadge>
                </div>
              </nav>

             
                <div className="row">
                  <div className="col-md-4">
                    <div className="card ">
                      <div className="card-body pt-0">
                        <div className="w-px-40 h-auto mx-auto mt-3 rounded-circle">
                          <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-xl">
                          <span className="avatar-initial rounded-circle bg-label-primary">
                              {" "}
                              {userData.name &&
                                userData.name
                                  .split(" ")
                                  .filter(
                                    (word, index) =>
                                      index === 0 ||
                                      index ===
                                        userData.name.split(" ").length - 1
                                  )
                                  .map((word) => word.charAt(0).toUpperCase())
                                  .join("")}
                            </span>
                          </div>
                        </div>
                        <div className="user-info  mt-3 text-center">
                          <h5 className="text-capitalize">{userData.name}</h5>
                          <span className="btn btn-outline-secondary rounded-pill btn-xs text-capitalize">
                            {" "}
                            {userData.role}
                          </span>
                        </div>

                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Broker Connection:</strong>
                            <span className="text-success ml-auto">
                              <div className="ms-auto">
                                <div
                                  className={`text-success ml-auto ${
                                    userData.broker_conn_status
                                      ? "text-success"
                                      : "text-danger"
                                  }`}
                                  onClick={() =>
                                    handleConnectionStatus(
                                      !userData.broker_conn_status
                                    )
                                  }
                                >
                                  {userData.broker_conn_status ? (
                                    <>
                                      <i className="ri-shield-check-line"></i>{" "}
                                      Connected
                                    </>
                                  ) : (
                                    <>
                                      <i className="ri-close-large-line"></i>{" "}
                                      Not Connected
                                    </>
                                  )}
                                </div>
                              </div>
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Broker Acc. Balance:</strong>
                            <span className="ml-auto">
                              {(userData.broker_account_fund || 0).toFixed(2)}{" "}
                              Rs.
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Commission:</strong>
                            <span className="ml-auto">
                              {userData.commission}%
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-7">
                            <strong>Lot Size Limit:</strong>
                            <span className="ml-auto ">
                              {userData.lot_size_limit} Lot
                            </span>
                          </li>
                          {formattedExchanges.length > 0 && (
                            <li className="d-flex justify-content-between align-items-center">
                              <strong>Segments</strong>
                              <span className="ml-auto">
                                <ul className="list-unstyled">
                                  {formattedExchanges.map((exchange, index) => (
                                    <li key={index}>{exchange}</li>
                                  ))}
                                </ul>
                              </span>
                            </li>
                          )}
                        </ul>

                        <hr />
                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex flex-column align-items-start mb-4">
                            <span className="fw-medium fs-5">Contacts</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Email:</strong>
                            <span className="ml-auto">{userData.email}</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Mobile:</strong>
                            <span className="ml-auto">{userData.mobile}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row mt-4">
                    <div className="col-md-8 col-sm-7">
  <div className="nav-align-top">
    <ul className="nav nav-pills flex-row justify-content-center flex-wrap mb-3 row-gap-1">
      <li className="nav-item active">
                              <Link
                                to="/teacher/user_profile"
                                className="nav-link"
                              >
                                <i className="ri-user-3-line me-1_5"></i>Profile
                              </Link>
                            </li>

                            <li className="nav-item">
                              <Link
                                to="/teacher/user_profile_Timeline"
                                className="nav-link active btn btn-primary"
                              >
                                <i className="ri-timeline-view me-1_5"></i>Timeline
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/teacher/user_profile_login_log"
                                className="nav-link "
                              >
                                <i className="ri-news-line me-1_5"></i>Login log
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to="/teacher/user_profile_activity_log"
                                className="nav-link "
                              >
                                <i className="ri-file-list-2-line me-1_5"></i>Activity
                                log
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 mb-6 mb-xl-0">
                        <div
                          className="card"
                          style={{
                            height: "540px",
                            overflowY: "auto",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center card-header">
                            <h5 className="text-center mb-0 flex-grow-1">
                              Generate Report
                            </h5>
                          </div>

                          <div className="card-body">
                            <ul className="timeline mb-0">
                              <li className="timeline-item timeline-item-transparent">
                                <span className="timeline-point timeline-point-primary"></span>

                                <div className="timeline-event">
                                  <div className="timeline-header mb-3">
                                    {/* <button
                                      className="btn btn-primary active me-3"
                                      onClick={generateReport}
                                      disabled={loadings}
                                    >
                                      {loadings ? (
                                        <>
                                          <i className="ri-loader-2-line ri-lg me-2"></i>
                                          Generating Report..
                                        </>
                                      ) : (
                                        "Generate Report"
                                      )}
                                    </button> */}

                                    <br></br>
                                  </div>
                                  {reportData && ( // Check if reportData is defined
                                    <>
                                      <small
                                        className={`mb-1 d-inline ${
                                          reportData?.status
                                            ? "text-success"
                                            : "text-danger"
                                        }`}
                                      >
                                        <small className="text-start text-muted">
                                          Status:
                                        </small>{" "}
                                        {reportData?.status
                                          ? "Complete"
                                          : "Incomplete"}
                                      </small>
                                      <p className="mb-5 text-start">
                                        Datetime:{" "}
                                        {formatDateTime(reportData.datetime)}{" "}
                                        {formatDateDay(reportData.datetime)}-
                                        {formatDateMonth(reportData.datetime)}-
                                        {formatDateYear(reportData.datetime)}
                                      </p>
                                    </>
                                  )}
                                  <div className="d-flex align-items-center mb-4"></div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-6 mb-6 mb-xl-0">
                        <div className="card">
                          <div className="d-flex justify-content-between align-items-center card-header">
                            <h5 className="text-center mb-0 flex-grow-1">
                              Load Instrument
                            </h5>
                          </div>

                          <div className="card-body">
                            <ul className="timeline mb-0">
                              <li className="timeline-item timeline-item-transparent">
                                <span className="timeline-point timeline-point-success"></span>
                                <div className="timeline-event">
                                  <div className="timeline-header mb-3">
                                    {/* <button
                                      className="btn btn-primary active text-center"
                                      onClick={loadInstrument}
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <>
                                          <i className="ri-loader-2-line ri-lg me-2"></i>{" "}
                                          Loading Instruments...
                                        </>
                                      ) : (
                                        "Load Instrument"
                                      )}
                                    </button> */}
                                    <br></br>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        height: "400px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <VirtualScroller
                                        items={activities}
                                        itemSize={50}
                                        itemTemplate={itemTemplate}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardLinks></CardLinks>
                </div>
             
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TimeLine;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
