import React, { useEffect, useState ,useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import StudentHeader from "./StudentHeader";
import Footer from "../../../component/Footer"
import SubHeaderS from "./SubHeaderS";
import { Link, useNavigate,useParams } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
const StudentOrderBook = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [backClicked, setBackClicked] = useState(false);
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const fetchData = async () => {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
     if (!authToken || !userId) {
    navigate("/commonlogin");
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_book`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        setData(response.data.data);
      } else {
        setError(new Error("No data found"));
      }
    }catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleRefresh = async () => {
    const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId) {
      setError(new Error("User ID not found"));
      setLoading(false);
      return;
    }
  
    if (!authToken) {
      setError(new Error("Auth token not found"));
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_book`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        const errorMsg = response.data.msg || "Success";
        setData(response.data.data); // Assuming response.data.data is an array to set in DataTable
  
     
      } else if (response.data && response.data.st === 2) {
        const errorMsg = response.data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // Ensure the dependency array is empty for initial fetch
  

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
  };

  const renderTransactionType = (rowData) => {
    const style = {
      color: rowData.transactiontype === "BUY" || rowData.transactionType === "BUY" ? "green" : "orange",
    };
    const transactionType = rowData.transactiontype || rowData.transactionType;
    return <span style={style}>{transactionType}</span>;
  };
  
  const renderOrderStatus = (rowData) => {
    const status = rowData.orderstatus || rowData.orderStatus;
    const style = {
      color: status.toLowerCase() === "complete" ? "green" : "orange",
    };
    const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return <span style={style}>{formattedStatus}</span>;
  };
  
  const renderStrikePrice = (rowData) => {
    const strikePrice = rowData.strikeprice || rowData.drvStrikePrice;
    return <span>{strikePrice}</span>;
  };
  

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  return (
    <>
     <Toast ref={toast} />
      <StudentHeader />
      <SubHeaderS />

      <div className="container-xxl container-p-y">
      <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumb-style1 text-black">
    <li className="breadcrumb-item">
      <Link to="/student/dashboard" className="text-black">
        <i className="ri-home-7-line ri-lg"></i>
      </Link>
    </li>
    <li className="breadcrumb-item active text-secondary" aria-current="page">
      Order Book
    </li>
  </ol>
</nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
          <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>

            <h5 className="mb-0 mx-auto">Order Book</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
          
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (   <div className="d-flex align-items-center">
              <div
                className="spinner-border spinner-border-sm custom-spinner ms-3"
                role="status"
                
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="mt-3">
              <Tooltip target=".custom-target-icon" />
              <i
                className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary" role="button"
                data-pr-tooltip="Reload"
                onClick={handleRefresh}
                data-pr-position="top"
               
              ></i>
            </div>      
            
             )}
          </div>
          <DataTable
            className="custom-column-border text-center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column
    align="center"
    className="custom-column-border"
    field="tradingsymbol"
    header="Symbols"
    body={(rowData) => rowData.tradingsymbol || rowData.tradingSymbol}
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="transactiontype"
    header="Transaction Type"
    body={renderTransactionType}
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="exchange"
    header="Exchange"
    body={(rowData) => rowData.exchange || rowData.exchangeSegment}
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="instrumenttype"
    header="Instrument Type"
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="strikeprice"
    header="Strike Price"
    body={renderStrikePrice}
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="optiontype"
    header="Option Type"
  ></Column>
  <Column
    align="center"
    className="custom-column-border"
    field="orderstatus"
    header="Order Status"
    body={renderOrderStatus}
  ></Column>
            <Column
              align="center"
              className="custom-column-border "
              header="Actions"
              body={(rowData) => (
                <Link to={`/app2/student_order_details/${rowData.uniqueorderid || rowData.orderId}`}>

                  <button
                    className="btn btn-info  custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                  >
                    <i className="ri-gallery-view-2"></i>
                  </button>
                </Link>
              )}
            ></Column>
          </DataTable>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default StudentOrderBook;
