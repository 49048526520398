import React from "react";
import faq from "../../../assets/img/products/character_7.png";

import MetaTags from "./MetaTags";
const Features = () => {
  return (
    <div>
      <MetaTags />
  
      <section
        id="landingReviews"
        className=" bg-body landing-reviews pt-10 mt-5"
      >
        <div className="container-xxl">
        
        </div>
      </section>
  
      <div className="container-xxl bg-white bg-icon-right rounded">
        <section id="landingFAQ" className="landing-faq py-10">
          <div className="row gy-5">
            <div className="col-lg-4">
              <div className="text-center">
                <img
                  src={faq}
                  alt="sitting girl with laptop"
                  className="faq-image scaleX-n1-rtl"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="text-start ">
              <h4>   What AutoProfito help you?</h4>
              <p className="mb-1">
            All plans include 40+ advanced tools and features to boost your
            products.
          </p>
          <p className="mt-1 mb-10">Choose the best plan to fit your needs.</p>
              </div>
            
              <h2 className="text-start h4 ">
                Join the trading revolution with AutoProfito
              </h2>
              <div className="">
                At QuantBot you will get the tools used by BIG PLAYERS at your
                fingertip, come and join the revolution at lowest possible cost
                <p className="my-2">
                  <i className="ri-check-double-line"></i>&nbsp; Highly secured
                  cloud infrastructure <br />
                  <i className="ri-check-double-line"></i>&nbsp; Fast trade
                  execution in multiple brokers <br />
                  <i className="ri-check-double-line"></i>&nbsp; Simultaneous
                  trade execution in multiple Brokers and Multiple accounts
                </p>
                Our mission is to create an easy to use algotrading platform
                mainly for retail traders and make them get the best out of it.
                Technology is out primary edge and the market is dominated by
                algorithmic trading, take the fast movers advantage.
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="bg-white">
       
        <section
          id="landingReviews"
          className=" bg-body landing-reviews pt-10 mt-5"
        >
          <div className="container-xxl"></div>
        </section>

        <section id="landingFunFacts" className="section-py landing-fun-facts py-12 my-4">
        <div className="container-xxl">
  <div className="row gx-0 gy-5 gx-sm-6">
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-primary">
        <i className="ri ri-cloud-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">137+</h2>
      <h6 className="mb-0 text-body">Completed Sites</h6>
    </div>
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-success">
        <i className="ri ri-time-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">1,100+</h2>
      <h6 className="mb-0 text-body">Working Hours</h6>
    </div>
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-warning">
        <i className="ri ri-user-smile-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">137+</h2>
      <h6 className="mb-0 text-body">Happy Customers</h6>
    </div>
    <div className="col-6 col-md-3 col-lg-3 text-center">
      <span className="badge rounded-pill bg-label-hover-info">
        <i className="ri ri-award-line fs-2"></i>
      </span>
      <h2 className="fw-bold mb-0 fun-facts-text">23+</h2>
      <h6 className="mb-0 text-body">Awards Winning</h6>
    </div>
  </div>
</div>


  </section>
       
      </div>
    </div>
  );
};

export default Features;
