// import React, { useState, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import AdminHeader from "./AdminHeader";
// import AdminSubHeader from "./AdminSubHeader";
// import Footer from "../../../component/Footer";
// import { Button } from "primereact/button";
// import { Toast } from "primereact/toast";
// import config from "../../../component/config";

// const CreateSubscription = () => {
//   const toast = useRef(null);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [backClicked, setBackClicked] = useState(false);
//   const [rows, setRows] = useState([
//     { subscriptionName: "", subscribeDescription: "", pricing: "", servicePeriod: "",maxUserLimit:"" }
// ]);

//   const handleBack = () => {
//     if (!backClicked) {
//       setBackClicked(true);
//       navigate(-1);
//     }
//   };

//   const handleAddRow = () => {
//     setRows([
//       ...rows,
//       { subscriptionName: "", subscribeDescription: "", pricing: "", servicePeriod: "",maxUserLimit:"" }
//     ]);
//   };

//   const handleDeleteRow = (index) => {
//     setRows(rows.filter((_, i) => i !== index));
//   };

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const updatedRows = rows.map((row, i) =>
//       i === index ? { ...row, [name]: value } : row
//     );
//     setRows(updatedRows);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     // Here, you would handle form submission
//     // For example, sending the `rows` data to the server

//     setLoading(false);
//   };

//   return (
//     <div>
//       <AdminHeader />
//       <AdminSubHeader />
//       <div className="container-xxl flex-grow-1 container-p-y">
//         <nav aria-label="breadcrumb">
//           <ol className="breadcrumb breadcrumb-style1 text-black">
//             <li className="breadcrumb-item">
//               <Link to="/admin/dashboard" className="text-black">
//                 <i className="ri-home-7-line ri-lg"></i>
//               </Link>
//             </li>
//             <li className="breadcrumb-item">
//               <Link to="/admin/manage_faq" className="text-black">
//                 Manage Subscription
//               </Link>
//             </li>
//             <li className="breadcrumb-item active text-secondary" aria-current="page">
//               Create Subscription
//             </li>
//           </ol>
//         </nav>
//         <div className="card p-5">
//           <div className="row align-items-center">
//             <div className="col-5 text-start mb-5">
//               <button
//                 onClick={handleBack}
//                 className="btn rounded-pill btn-outline-secondary btn-xs"
//               >
//                 <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
//               </button>
//             </div>
//             <div className="col-6 text-start mb-5">
//               <h5 className="mb-0">Create Subscription</h5>
//             </div>
//           </div>
//           <div className="row">
//           <div className="col-3 mb-4">
//                     <div className="form-floating form-floating-outline">
//                       <input
//                         className="form-control"
//                         type="text"
//                         id="addonname"
//                         name="addonname"
//                         // value={formData.name}
//                         // onChange={handleChange}
//                         placeholder="Addons Name"
//                         required
//                       />
//                       <label htmlFor="addonname">
//                         {" "}
//                         <span className="text-danger">*</span> Addons Name{" "}
//                       </label>

//                     </div>
//                   </div>
//                   <div className="col-3 mb-4">
//                     <div className="form-floating form-floating-outline">
//                       <input
//                         className="form-control "
//                         type="text"
//                         id="addondescription"
//                         name="addondescription"
//                         // value={formData.name}
//                         // onChange={handleChange}
//                         placeholder="Addon description"
//                         required
//                       />
//                       <label htmlFor="addondescription">
//                         {" "}
//                         <span className="text-danger">*</span> Addon description{" "}
//                       </label>

//                     </div>
//                   </div>
//                   </div>
//           <form onSubmit={handleSubmit}>
//             {rows.map((row, index) => (
//               <div key={index} className="row g-5 mb-3">
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                   <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="subscriptionName"
//                       value={row.subscriptionName}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Subscription Name"
//                     />
//                     <label htmlFor={`subscriptionName-${index}`}>   <span className="text-danger">*</span> Subscription Name</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                   <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="subscribeDescription"
//                       value={row.subscribeDescription}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Subscribe Description"
//                     />
//                     <label htmlFor={`subscribeDescription-${index}`}>   <span className="text-danger">*</span> Subscribe Description</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                 <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="pricing"
//                       value={row.pricing}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Pricing"
//                     />
//                     <label htmlFor={`pricing-${index}`}>   <span className="text-danger">*</span> Pricing</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                 <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="servicePeriod"
//                       value={row.servicePeriod}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Service Period"
//                     />
//                     <label htmlFor={`servicePeriod-${index}`}>   <span className="text-danger">*</span> Service Period</label>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                 <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="maxUserLimit"
//                       value={row.maxUserLimit}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Max User Limit "
//                     />
//                     <label htmlFor={`maxUserLimit-${index}`}>   <span className="text-danger">*</span> Max User Limit</label>
//                   </div>
//                 </div>

//                 <div className="col-lg-12 col-xl-2 col-12 d-flex align-items-center mb-0">
//                   <button
//                     type="button"
//                     onClick={() => handleDeleteRow(index)}
//                     className="btn btn-xl btn-outline-danger"
//                   >
//                     <i className="ri-close-line ri-24px me-1"></i>
//                     <span className="align-middle">Delete</span>
//                   </button>
//                 </div>
//                 <hr />
//               </div>
//             ))}
// <div className="mb-3">
//   <button
//     type="button"
//     onClick={handleAddRow}
//     className="btn btn-primary"
//   >
//     <i className="ri-add-line me-1"></i>
//     <span className="align-middle">Add Row</span>
//   </button>
// </div>
// <div className="text-end mb-5 mt-6">
//   <button
//     type="submit"
//     className="btn rounded-pill btn-success btn-sm"
//     disabled={loading}
//   >
//     {loading ? (
//       <i className="ri-loader-line ri-lg me-1"></i>
//     ) : (
//       <i className="ri-checkbox-circle-line ri-lg me-1"></i>
//     )}{" "}
//     {loading ? "Saving Data...." : "Save Data"}
//   </button>
// </div>
//           </form>
//         </div>
//       </div>

//       <Toast ref={toast} position="top-right" />
//       <Footer />
//     </div>
//   );
// };

// export default CreateSubscription;

// import React, { useState, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import AdminHeader from "./AdminHeader";
// import AdminSubHeader from "./AdminSubHeader";
// import Footer from "../../../component/Footer";
// import { Button } from "primereact/button";
// import { Toast } from "primereact/toast";

// const CreateSubscription = () => {
//   const toast = useRef(null);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [backClicked, setBackClicked] = useState(false);
//   const [SubscriptionName, setSubscriptionName] = useState("");
//   const [SubscriptionDescription, setSubscriptionDescription] = useState("");
//   const [errors, setErrors] = useState({});
//   const [rows, setRows] = useState([
//     {
//       planName: "",
//       planDescription: "",
//       pricing: "",
//       servicePeriod: "",
//       maxUserLimit: "",
//     },
//   ]);

//   const handleBack = () => {
//     if (!backClicked) {
//       setBackClicked(true);
//       navigate(-1);
//     }
//   };

//   const handleAddRow = () => {
//     setRows([
//       ...rows,
//       {
//         planName: "",
//         planDescription: "",
//         pricing: "",
//         servicePeriod: "",
//         maxUserLimit: "",
//       },
//     ]);
//   };

//   const handleDeleteRow = (index) => {
//     setRows(rows.filter((_, i) => i !== index));
//   };

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const updatedRows = rows.map((row, i) =>
//       i === index ? { ...row, [name]: value } : row
//     );
//     setRows(updatedRows);
//   };

//   const handleSubscriptionNameChange = (event) => {
//     setSubscriptionName(event.target.value);
//   };

//   const handleSubscriptionDescriptionChange = (event) => {
//     setSubscriptionDescription(event.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const requestData = {
//       subscription_name: SubscriptionName,
//       subscription_description: SubscriptionDescription,
//       plans: rows.map((row) => ({
//         plan_name: row.planName,
//         plan_description: row.planDescription,
//         pricing: row.pricing,
//         service_period: row.servicePeriod,
//         max_user_limit: row.maxUserLimit,
//       })),
//     };

//     try {
//       const authToken = localStorage.getItem("authToken");

//       const response = await axios.post(
//         "https://ghanish.in/api/admin/subscription/create",
//         requestData,
//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data && response.data.st === 1) {
//         toast.current.show({
//           severity: "success",
//           summary: "Success",
//           detail: formatMessage(response.data.msg) || "Success!",
//           className: "text-start",
//           life: 3000,
//         });

//         setTimeout(() => {
//           navigate("/admin/manage_subscription");
//         }, 1000);
//       } else if (response.data && response.data.st === 2) {
//         toast.current.show({
//           severity: "warn",
//           summary: "Warning",
//           detail:
//             formatMessage(response.data.msg) ||
//             "Warning: Please check the input.",
//           life: 3000,
//         });
//       } else if (
//         response.data &&
//         (response.data.st === 3 || response.data.st === 4)
//       ) {
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: formatMessage(response.data.msg) || "An error occurred.",
//           life: 3000,
//         });
//       } else {
//         setErrors({ global: "Unexpected response status" });
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: "Unexpected response status",
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       if (error.response && error.response.status === 401) {
//         navigate("/commonlogin");
//       } else {
//         const errorMsg = error.response?.data?.msg
//           ? formatMessage(error.response.data.msg)
//           : String(error.message || "Failed to fetch data");

//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: errorMsg,
//           life: 3000,
//           className: "text-start",
//         });
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const formatMessage = (msg) => {
//     if (typeof msg === "object") {
//       return Object.values(msg).flat().join(", ");
//     }
//     return String(msg);
//   };

//   return (
//     <div>
//       <AdminHeader />
//       <AdminSubHeader />
//       <div className="container-xxl flex-grow-1 container-p-y">
//         <nav aria-label="breadcrumb">
//           <ol className="breadcrumb breadcrumb-style1 text-black">
//             <li className="breadcrumb-item">
//               <Link to="/admin/dashboard" className="text-black">
//                 <i className="ri-home-7-line ri-lg"></i>
//               </Link>
//             </li>
//             <li className="breadcrumb-item">
//               <Link to="/admin/manage_faq" className="text-black">
//                 Manage Subscription
//               </Link>
//             </li>
//             <li
//               className="breadcrumb-item active text-secondary"
//               aria-current="page"
//             >
//               Create Subscription
//             </li>
//           </ol>
//         </nav>
//         <div className="card p-5">
//           <div className="row align-items-center">
//             <div className="col-5 text-start mb-5">
//               <button
//                 onClick={handleBack}
//                 className="btn rounded-pill btn-outline-secondary btn-xs"
//               >
//                 <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
//               </button>
//             </div>
//             <div className="col-6 text-start mb-5">
//               <h5 className="mb-0">Create Subscription</h5>
//             </div>
//           </div>
//           <div className="column">
//             <div className="col-3 mb-4">
//               <div className="form-floating form-floating-outline">
//                 <input
//                   className="form-control"
//                   type="text"
//                   id="SubscriptionName"
//                   name="SubscriptionName"
//                   value={SubscriptionName}
//                   onChange={handleSubscriptionNameChange}
//                   placeholder="Subscription Name"
//                   required
//                 />
//                 <label htmlFor="SubscriptionName">
//                   {" "}
//                   <span className="text-danger">*</span> Subscription Name{" "}
//                 </label>
//               </div>
//             </div>
//             <div className="col-12 ">
//               <div className="form-floating form-floating-outline">
//                 <textarea
//                   className="form-control h-px-75"
//                     aria-label="With textarea"
//                   id="SubscriptionDescription"
//                   name="SubscriptionDescription"
//                   value={SubscriptionDescription}
//                   onChange={handleSubscriptionDescriptionChange}
//                   placeholder="Subscription description"
                  
//                 />
              
//                 <label htmlFor="SubscriptionDescription">
                 
//                   <span className="text-danger">*</span> Subscription description
//                 </label>
//               </div>
//             </div>
//           </div>
//           <hr></hr>
//           <form onSubmit={handleSubmit}>
//             {rows.map((row, index) => (
//               <div key={index} className=" g-5 ">
                  
//                 <div className="row ">
//                 <div className="col-lg-5 col-xl-3 col-12 mb-2">
//                   <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="planName"
//                       value={row.planName}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Plan Name"
//                     />
//                     <label htmlFor={`planName-${index}`}>
//                       {" "}
//                       <span className="text-danger">*</span> Plan Name
//                     </label>
//                   </div>
//                 </div>
            
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                   <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="pricing"
//                       value={row.pricing}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Pricing"
//                     />
//                     <label htmlFor={`pricing-${index}`}>
//                       {" "}
//                       <span className="text-danger">*</span> Pricing
//                     </label>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 col-xl-2 col-12 mb-0">
//                   <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="servicePeriod"
//                       value={row.servicePeriod}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Service Period"
//                     />
//                     <label htmlFor={`servicePeriod-${index}`}>
//                       {" "}
//                       <span className="text-danger">*</span> Service Period
//                     </label>
//                   </div>
//                 </div>
//                 <div className="col-lg-5 col-xl-3 col-12 mb-0">
//                   <div className="form-floating form-floating-outline">
//                     <input
//                       type="text"
//                       name="maxUserLimit"
//                       value={row.maxUserLimit}
//                       onChange={(e) => handleInputChange(index, e)}
//                       className="form-control"
//                       placeholder="Max User Limit "
//                     />
//                     <label htmlFor={`maxUserLimit-${index}`}>
//                       {" "}
//                       <span className="text-danger">*</span> Max User Limit
//                     </label>
//                   </div>
//                 </div>

//                 <div className="col-lg-12 col-xl-2 col-12 d-flex align-items-center mb-0">
//                 {index === 0 ? (
//                   <button
//                     type="button"
//                     onClick={handleAddRow}
//                     className="btn btn-xl btn-primary"
//                   >
//                     <i className="ri-add-line me-1"></i>
//                     <span className="align-middle">Add Row</span>
//                   </button>
//                 ):(
//                   <button
//                     type="button"
//                     onClick={() => handleDeleteRow(index)}
//                     className="btn btn-xl btn-outline-danger"
//                   >
//                     <i className="ri-close-line ri-24px me-1"></i>
//                     <span className="align-middle">Delete</span>
//                   </button>
//                 )}
//                 </div>
//                 <div className="col-lg-12 col-xl-12 col-12 mb-0 mt-2">
//                   <div className="form-floating form-floating-outline">
//                     <textarea
//                       className="form-control h-px-75"
//                     aria-label="With textarea"
//                       name="planDescription"
//                       value={row.planDescription}
//                       onChange={(e) => handleInputChange(index, e)}
                     
//                       placeholder="Plan Description"
//                     />
//                     <label htmlFor={`planDescription-${index}`}>
//                       {" "}
//                       <span className="text-danger">*</span> Plan
//                       Description
//                     </label>
//                   </div>
//                 </div>
//               </div>
//               <hr></hr>
//               </div>
//             ))}
//             <div className="row">
//               <div className="text-end mb-5 mt-6">
//                 <button
//                   type="submit"
//                   className="btn rounded-pill btn-success btn-sm"
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <i className="ri-loader-line ri-lg me-1"></i>
//                   ) : (
//                     <i className="ri-checkbox-circle-line ri-lg me-1"></i>
//                   )}{" "}
//                   {loading ? "Saving Data...." : "Save Data"}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Footer />
//       <Toast ref={toast} />
//     </div>
//   );
// };

// export default CreateSubscription;












import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import Footer from "../../../component/Footer";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";

const CreateSubscription = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionDescription, setSubscriptionDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [rows, setRows] = useState([
    {
      planName: "",
      planDescription: "",
      pricing: "",
      servicePeriod: "",
      maxUserLimit: "",
    },
  ]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        planName: "",
        planDescription: "",
        pricing: "",
        servicePeriod: "",
        maxUserLimit: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    // Update the value in rows state
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [name]: value } : row
    );
    setRows(updatedRows);

    // Validate the field
    const rowErrors = validateRow(updatedRows[index]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`row_${index}`]: rowErrors,
    }));
  };

  const handleSubscriptionNameChange = (e) => {
    const { value } = e.target;
    setSubscriptionName(value);

    // Validate subscription name
    if (validateSubscriptionName(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subscriptionName: undefined,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subscriptionName: "Subscription Name must be 3-50 characters long and contain only letters.",
      }));
    }
  };

  const handleSubscriptionDescriptionChange = (e) => {
    const { value } = e.target;
    setSubscriptionDescription(value);

    // Validate subscription description
    if (validateSubscriptionDescription(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subscriptionDescription: undefined,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subscriptionDescription: "Subscription Description must be between 3 and 1000 characters.",
      }));
    }
  };

  const validateSubscriptionName = (name) => {
    const namePattern = /^[a-zA-Z\s]{3,50}$/;
    return namePattern.test(name);
  };

  const validateSubscriptionDescription = (description) => {
    return description.length >= 3 && description.length <= 1000;
  };

  const validatePlanName = (name) => {
    const namePattern = /^[a-zA-Z\s]{3,50}$/;
    return namePattern.test(name);
  };

  const validatePricing = (pricing) => {
    const pricingPattern = /^[1-9]\d*$/; // Positive integer only
    return pricingPattern.test(pricing);
  };

  const validateServicePeriod = (period) => {
    const periodPattern = /^(1[0-2]|[1-9])$/; // 1 to 12
    return periodPattern.test(period);
  };

  const validateMaxUserLimit = (limit) => {
    const limitPattern = /^(50|[1-4]?[0-9])$/; // 1 to 50
    return limitPattern.test(limit);
  };
  

  const validateRow = (row) => {
    const errors = {};
    if (!validatePlanName(row.planName))
      errors.planName = "Plan Name must be 3-50 characters long and contain only letters.";
        if (!validatePricing(row.pricing)) errors.pricing = "Invalid Pricing";
    if (!validateServicePeriod(row.servicePeriod)) errors.servicePeriod = "Invalid Service Period";
    if (!validateMaxUserLimit(row.maxUserLimit)) errors.maxUserLimit = "Invalid Max User Limit";
    if (!validateSubscriptionDescription(row.planDescription)) errors.planDescription = "Plan Description must be between 3 and 1000 characters.";
    
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    let valid = true;
    const fieldErrors = {};

    if (!validateSubscriptionName(subscriptionName)) {
      fieldErrors.subscriptionName = "Subscription Name must be 3-50 characters long and contain only letters.";
      valid = false;
    }

    if (!validateSubscriptionDescription(subscriptionDescription)) {
      fieldErrors.subscriptionDescription = "Subscription Description must be between 3 and 1000 characters.";
      valid = false;
    }

    rows.forEach((row, index) => {
      const rowErrors = validateRow(row);
      if (Object.keys(rowErrors).length > 0) {
        valid = false;
        fieldErrors[`row_${index}`] = rowErrors;
      }
    });

    if (!valid) {
      setErrors(fieldErrors);
      setLoading(false);
      return;
    }

    const requestData = {
      subscription_name: subscriptionName,
      subscription_description: subscriptionDescription,
      plans: rows.map((row) => ({
        plan_name: row.planName,
        plan_description: row.planDescription,
        pricing: row.pricing,
        service_period: row.servicePeriod,
        max_user_limit: row.maxUserLimit,
      })),
    };

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
       if (!authToken || !userId) {
      navigate("/commonlogin");
        return;
      }

      const response = await axios.post(
        "https://ghanish.in/api/admin/subscription_copy_trading/create",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: formatMessage(response.data.msg) || "Success!",
          className: "text-start",
          life: 3000,
        });

        setTimeout(() => {
          navigate("/admin/manage_subscription");
        }, 1000);
      } else if (response.data && response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail:
            formatMessage(response.data.msg) ||
            "Warning: Please check the input.",
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: formatMessage(response.data.msg) || "An error occurred.",
          life: 3000,
        });
      } else {
        setErrors({ global: "Unexpected response status" });
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unexpected response status",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Failed to fetch data");

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
          className: "text-start",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };


  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_subscription" className="text-black">
                Manage Subscription
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Create Subscription
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-5 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-start mb-5">
              <h5 className="mb-0">Create Subscription</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
          <div className="column">
            <div className="col-3 mb-4">
              
            {/* <div className="mb-3">
                <label className="form-label">Subscription Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.subscriptionName ? 'is-invalid' : ''}`}
                  placeholder="Subscription Name"
                  value={subscriptionName}
                  onChange={handleSubscriptionNameChange}
                />
                {errors.subscriptionName && (
                  <div className="invalid-feedback">{errors.subscriptionName}</div>
                )}
              </div> */}
                <div className="form-floating form-floating-outline">
                <input
                  type="text"
                  className={`form-control ${errors.subscriptionName ? 'is-invalid' : ''}`}
                  placeholder="Subscription Name"
                  value={subscriptionName}
                  onChange={handleSubscriptionNameChange}
                />
                      <label htmlFor>
                        {" "}
                        <span className="text-danger">*</span> Subscription Name
                      </label>
                      {errors.subscriptionName && (
                  <div className="invalid-feedback">{errors.subscriptionName}</div>
                )}
                    </div>
            </div>
            <div className="col-12 ">
          
                                  <div className="form-floating form-floating-outline">
                                  <textarea
                  className={`form-control h-px-75 ${errors.subscriptionDescription ? 'is-invalid' : ''}`}
                  placeholder="Subscription Description"
                  value={subscriptionDescription}
                  onChange={handleSubscriptionDescriptionChange}
                ></textarea>
                      <label htmlFor>
                        {" "}
                        <span className="text-danger">*</span> Subscription Description
                      </label>
                      {errors.subscriptionDescription && (
                  <div className="invalid-feedback">{errors.subscriptionDescription}</div>
                )}
                    </div>
            </div>
          </div>
          <hr></hr>
         
            {rows.map((row, index) => (
              <div key={index} className=" g-5 ">
                <div className="row ">
                  <div className="col-lg-5 col-xl-3 col-12 mb-2">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        name="planName"
                        value={row.planName}
                        onChange={(e) => handleInputChange(index, e)}
                        className={`form-control  ${errors[`row_${index}`]?.planName ? 'is-invalid' : ''}`}
                        placeholder="Plan Name"
                      />
                      <label htmlFor={`planName-${index}`}>
                        {" "}
                        <span className="text-danger">*</span> Plan Name
                      </label>
                      {errors[`row_${index}`]?.planName && (
                        <div className="text-danger">{errors[`row_${index}`]?.planName}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-xl-2 col-12 mb-0">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        name="pricing"
                        value={row.pricing}
                        onChange={(e) => handleInputChange(index, e)}
                        className={`form-control  ${errors[`row_${index}`]?.pricing ? 'is-invalid' : ''}`}
                        placeholder="Pricing"
                      />
                      <label htmlFor={`pricing-${index}`}>
                        {" "}
                        <span className="text-danger">*</span> Pricing
                      </label>
                      {errors[`row_${index}`]?.pricing && (
                        <div className="text-danger">{errors[`row_${index}`]?.pricing}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-xl-2 col-12 mb-0">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        name="servicePeriod"
                        value={row.servicePeriod}
                        onChange={(e) => handleInputChange(index, e)}
                        className={`form-control  ${errors[`row_${index}`]?.servicePeriod ? 'is-invalid' : ''}`}
                        placeholder="Service Period"
                      />
                      <label htmlFor={`servicePeriod-${index}`}>
                        {" "}
                        <span className="text-danger">*</span> Service Period
                      </label>
                      {errors[`row_${index}`]?.servicePeriod && (
                        <div className="text-danger">{errors[`row_${index}`]?.servicePeriod}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-5 col-xl-3 col-12 mb-0">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        name="maxUserLimit"
                        value={row.maxUserLimit}
                        onChange={(e) => handleInputChange(index, e)}
                        className={`form-control  ${errors[`row_${index}`]?.maxUserLimit ? 'is-invalid' : ''}`}
                        placeholder="Max User Limit"
                      />
                      <label htmlFor={`maxUserLimit-${index}`}>
                        {" "}
                        <span className="text-danger">*</span> Max User Limit
                      </label>
                      {errors[`row_${index}`]?.maxUserLimit && (
                        <div className="text-danger">{errors[`row_${index}`]?.maxUserLimit}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12 col-xl-2 col-12 d-flex align-items-center mb-0">
                    {index === 0 ? (
                      <button
                        type="button"
                        onClick={handleAddRow}
                        className="btn btn-xl btn-primary"
                      >
                        <i className="ri-add-line me-1"></i>
                        <span className="align-middle">Add Row</span>
                      </button>
                    ):(
                      <button
                        type="button"
                        onClick={() => handleDeleteRow(index)}
                        className="btn btn-xl btn-outline-danger"
                      >
                        <i className="ri-close-line ri-24px me-1"></i>
                        <span className="align-middle">Delete</span>
                      </button>
                    )}
                  </div>
                  <div className="col-lg-12 col-xl-12 col-12 mb-0 mt-2">
                    <div className="form-floating form-floating-outline">
                      <textarea
      className={`form-control h-px-75 ${errors[`row_${index}`]?.planDescription ? 'is-invalid' : ''}`}
                        
                        aria-label="With textarea"
                        name="planDescription"
                        value={row.planDescription}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Plan Description"
                      />
                      <label htmlFor={`planDescription-${index}`}>
                        {" "}
                        <span className="text-danger">*</span> Plan
                        Description
                      </label>
                      {errors[`row_${index}`]?.planDescription && (
                        <div className="text-danger">{errors[`row_${index}`]?.planDescription}</div>
                      )}
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>
            ))}
            <div className="row">
              <div className="text-end mb-5 mt-6">
                <button
                  type="submit"
                  className="btn rounded-pill btn-success btn-sm"
                  disabled={loading}
                >
                  {loading ? (
                   <div className="spinner-border me-2" role="status">
                   <span className="visually-hidden">Loading...</span>
                 </div>
                  ) : (
                    <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                  )}{" "}
                  {loading ? "Saving Data" : "Save Data"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default CreateSubscription;
