import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../../component/config";
import axios from "axios";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";

const SubscriptionPlan = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const navigate = useNavigate();

  const [backClicked, setBackClicked] = useState(false);
  const { subscriptionId, subscriptionType } = useParams();

  const fetchAllSubscriptionPlans = async (
    subscriptionId,
    subscriptionType
  ) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/view_all_subscription_plans`,
        {
          teacher_id: userId,
          subscription_id: subscriptionId,
          subscription_type: subscriptionType,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.st === 1) {
        setSubscriptionPlans(response.data.all_subscription_plans_list || []);
      } else {
      }
    } catch (error) {
      console.clear(); 
    }
  };
  useEffect(() => {
    fetchAllSubscriptionPlans(subscriptionId, subscriptionType);
  }, [subscriptionId, subscriptionType]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const anyPlanSubscribed = subscriptionPlans.some((plan) => plan.subscribed);
  const handleSubscribe = (
    subscriptionId,
    subscriptionPlanId,
    subscriptionType
  ) => {
    navigate(
      `/teacher/payment_gateway/${subscriptionId}/${subscriptionPlanId}/${subscriptionType}`
    );
  };
  const gifs = [
    require('../../../assets/gif/meteor-rain.gif'), 
    require('../../../assets/gif/rocket.gif'),       
    require('../../../assets/gif/diagram.gif') 
  ];
  return (
    <div>
      <Header></Header>
      <SubHeader></SubHeader>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/billing" className="text-black">
                Subscriptions
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Subscription Plan
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-7 text-center mb-5">
              <h5 className="mb-0">Subscription Plan</h5>
            </div>
            <div className="col-12 col-md-2 col-lg-2 text-end mb-5">
              <Link to="/teacher/payment_history">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-money-rupee-circle-line me-1 ri-lg"></i>{" "}
                    <span>Payment History</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 col-12 mb-md-0 mb-0">
            <div className="text-center col-12">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 mb-0 text-center">
                    <div className="bs-stepper wizard-modern wizard-modern-example  text-center">
                      <div className="bs-stepper-header">
                        <div
                          className="step"
                          data-target="#account-details-modern"
                        >
                          <button
                            type="button"
                            className="step-trigger d-flex flex-column align-items-center"
                          >
                            <span className="bs-stepper-circle bg-primary custom-stepper-circle"></span>
                            <span className="bs-stepper-label  text-center">
                              <span className="d-flex flex-column align-items-center">
                                <span className="bs-stepper-title">
                                  Subscription Plan
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>

                        <div
                          className="progress mb-1 text-center rounded mt-3 mx-auto"
                          style={{ height: "9px", width: "55%" }}
                        >
                          <div
                            className="progress-bar rounded"
                            role="progressbar"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div
                          className="step"
                          data-target="#personal-info-modern"
                        >
                          <button
                            type="button"
                            className="step-trigger d-flex flex-column align-items-center"
                          >
                            <span className="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                            <span className="bs-stepper-label mt-2 text-center">
                              <span className="d-flex flex-column gap-1">
                                <span className="bs-stepper-title">
                                  Payment
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className="text-center mb-0 mx-auto w-100">
                        All plans include 40+ advanced tools and features to
                        boost your product. Choose the best plan to fit your
                        needs. All plans include 40+ advanced tools and features
                        to boost
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center gy-3">
              {subscriptionPlans.map((plan,index) => (
                <div className="col-md-4 mb-6 col-lg-4 " key={plan.subscription_plan_id}>
                  <div
                    className={`card border ${
                      plan.subscribed ? "border-info" : "shadow"
                    } equal-height`}
                  >
                    <div className="card-body pt-4 d-flex flex-column">
                      <h4 className="card-title text-center text-capitalize">
                        {plan.plan_name}
                      </h4>
                      <div className="my-5 pt-3 text-center">
             
             <img src={gifs[index % gifs.length]} alt="Plan Image" height="100" />

             </div>
                      <div className="text-center h-px-50">
                        <div className="d-flex justify-content-center">
                          <sup className="h6 text-body text-primary fs-4 pricing-currency mt-9 mb-0 me-1">
                            ₹
                          </sup>
                          <h1 className="mb-0 text-primary">{plan.pricing}</h1>
                          {/* <sub className="h6 text-body pricing-duration mt-auto mb-1">
                            /month
                          </sub> */}
                        </div>
                        <div
                          className="text-center mt-3 mb-2"
                          style={{
                            minHeight: "4em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <small className="price-yearly price-yearly-toggle text-muted">
                            {plan.plan_description}
                          </small>
                        </div>
                      </div>
                      <ul className="list-group custom-list ps-6 my-5 pt-5 flex-grow-1 mt-12 list-unstyled">
  <li className="mb-2">
    <i className="ri-check-double-line text-info me-2 "></i>
    Copy trades on <strong>{plan.max_user_limit} student</strong> account.
    <div className="text-muted">(Excluding Master)</div>
  </li>
  <li className="mb-2">
    <i className="ri-check-double-line text-info me-2 "></i>
    <strong>{plan.service_period} days free</strong> service
  </li>
  <li className="mb-2">
    <i className="ri-check-double-line text-info me-2"></i>
    Reporting: Daily, Weekly, Monthly
  </li>
</ul>


                      {plan.subscribed ? (
                        <button
                          type="button"
                          className="btn btn-outline-info d-grid w-100 mt-auto d-flex align-items-center justify-content-center"
                          disabled
                        >
                          <i className="ri ri-checkbox-circle-line ri-lg me-2"></i>
                          <span>Subscribed</span>
                        </button>
                      ) : (!anyPlanSubscribed && plan.pricing === "0") ||
                        plan.pricing !== "0" ? (
                        <button
                          type="button"
                          className="btn btn-primary d-grid w-100 mt-auto"
                          onClick={() =>
                            handleSubscribe(
                              plan.subscription_id,
                              plan.subscription_plan_id,
                              plan.subscription_type
                            )
                          }
                        >
                          Subscribe Now
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SubscriptionPlan;
