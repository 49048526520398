import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import autoprofito_logo from  "../../../assets/img/products/company/autoprofito_logo.png"

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const userTradeBook = JSON.parse(localStorage.getItem("userTradeBook"));
  const userOrderBook = JSON.parse(localStorage.getItem("userOrderBook"));
  const [showModal, setShowModal] = useState(false);
  const [showTelegramItem, setShowTelegramItem] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const broadcastingFlag = localStorage.getItem("broadcastingFlag");
 
    setShowTelegramItem(broadcastingFlag === 'true'); 
  }, []);
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);

  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  
  const toTitleCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept", 
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsDate = { weekday: "long", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });

    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const closeDropdown = () => setDropdownOpen(false);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    navigate("/commonlogin");
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); 
  };
  
  return (
    <div>
   
      <nav className="layout-navbar navbar navbar-expand-xl align-items-center border border-bottom" id="layout-navbar">
        <div className="container-xxl d-flex justify-content-between align-items-center">
         
          <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-6">
            <div className="app-brand-logo demo">
              <img
                src={autoprofito_logo}
                alt="AutoProfito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span className="app-brand-text demo menu-text fw-semibold ms-1">
              AutoProfito
            </span>
          </div>

         
          <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
            <button className="nav-item nav-link px-0 me-xl-6 btn" onClick={toggleSidebar}>
              <i className="ri-menu-line ri-xl"></i>
            </button>
          </div>

          <div className=" d-xl-block">
            <span className="text-secondary">{formattedDate}</span>
            <br />
            <strong className="fs-4">{formattedTime}</strong>
          </div>

          <div className="d-flex align-items-center">
            <div className="text-end me-3 d-none  d-xl-block">
              <h6 className=" fs-5 mb-0 bold text-uppercase">
                {toTitleCase(userName)}
              </h6>
              <div className="position-relative d-inline-block my-2">
                <Link to="/teacher/user_profile">
                  <span className="btn btn-outline-secondary rounded-pill btn-xs">
                    {toTitleCase(userRole)}
                  </span>
                </Link>
              </div>
            </div>
            <div className=" align-items-xl-center me-4 me-xl-0 ">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item dropdown-user dropdown">
                <div
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  onClick={toggleDropdown}
                  role="button"
                >
                  <div className=" position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-lg">
                      <span className="avatar-initial rounded-circle bg-label-primary fs-2">
                        {userName && userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
                {dropdownOpen && (
                  <ul
                    className="dropdown-menu dropdown-menu-end mt-3  me-3 show"
                    style={{ right: "0", left: "auto", width:"300px" }}
                    onMouseLeave={closeDropdown}
                  >
                 <li>
  <div className="d-flex justify-content-start align-items-center ms-4">
    {/* Avatar */}
    <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-lg me-3">
      <span className="avatar-initial rounded-circle bg-label-primary fs-2">
        {userName && userName.charAt(0).toUpperCase()}
      </span>
    </div>

    {/* User Info */}
    <div className="text-start">
      <h6 className="fs-6 mb-0 bold text-uppercase">
        {toTitleCase(userName)}
      </h6>
      <div className="position-relative d-inline-block my-2">
        <Link to="/teacher/user_profile">
          <span className="btn btn-outline-secondary rounded-pill btn-xs">
            {toTitleCase(userRole)}
          </span>
        </Link>
      </div>
    </div>
  </div>
</li>
<hr className="mb-0 mt-0"></hr>
                    <li>
                      <Link
                        to="/teacher/user_profile"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-user-line ri-md me-2"></i>
                        <span className="align-middle">Profile</span>
                      </Link>
                    </li>
                  
                    <li>
                      <Link
                        to="/teacher/billing"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-medal-2-fill ri-md me-2"></i>
                        <span className="align-middle">Subscriptions</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/teacher/payment_history"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-money-rupee-circle-line ri-md me-2"></i>
                        <span className="align-middle">Payment History</span>
                      </Link>
                    </li>
                   
                    <li>
                      <Link
                        to="/teacher/my_report"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-bar-chart-box-line ri-md me-2"></i>
                        <span className="align-middle">My Report</span>
                      </Link>
                    </li>
                    
                    <li>
                      <Link
                        to="/teacher/feature_request"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-sparkling-line ri-md me-2"></i>
                        <span className="align-middle">Feature Request</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/teacher/chat"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-chat-2-line ri-md me-2"></i>
                        <span className="align-middle"> Chat</span>
                      </Link>
                    </li>

                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <div className="d-grid px-4 pt-2 pb-1">
                        <div
                          onClick={handleShowModal}
                          className="dropdown-item btn btn-danger d-flex align-items-center"
                        >
                          <i className="ri-logout-box-r-line ri-lg me-1"></i>{" "}
                          <span className="align-middle">Logout</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className={`sidebar ${isSidebarOpen ? "active" : ""}`}>
  <div className="sidebar-content">
  
    <div className="sidebar-header d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="app-brand-logo demo">
          <img
            src={autoprofito_logo}
            alt="AutoProfito Logo"
            className="w-px-40 h-auto rounded-circle"
          />
        </div>
        <span className="app-brand-text demo menu-text fw-semibold ms-2">
          AutoProfito
        </span>
      </div>
      <span className="close-icon text-end" onClick={toggleSidebar}>
        <i className="ri ri-close-line ri-lg ms-3"></i>
      </span>
    </div>
    <hr />

 
    <ul className="sidebar-nav mt-4">
      <li>
        <Link to="/teacher/dashboard"  onClick={() => {
        toggleSidebar();
        navigate("/teacher/dashboard");
      }}>
          <i className="ri ri-home-7-line ri-lg me-3"></i> Home
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
      <li>
        <Link to="/teacher/manage_student"  onClick={() => {
        toggleSidebar();
        navigate("/teacher/manage_student");
      }}>
          <i className="ri ri-user-line ri-lg me-3"></i> Manage Student
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
      
      {showTelegramItem && (
      <li>
        <Link to="/teacher/telegram_broadcast"  onClick={() => {
        toggleSidebar();
        navigate("/teacher/telegram_broadcast");
      }}>
          <i className="ri ri-telegram-line ri-lg me-3"></i> Telegram Broadcast
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
      )}
<li className="text-muted ">-------------------   Trade    -------------------</li>
<li>
        <Link to="/trade_position"
         onClick={() => {
          toggleSidebar();
          navigate("/trade_position");
        }}>
          <i className="ri ri-stock-line ri-lg me-3"></i> Position
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>

<li>
        <Link to="/user_basket" onClick={() => {
        toggleSidebar();
        navigate("/user_basket");
      }}>
          <i className="ri ri-shopping-basket-line ri-lg me-3"></i> Basket
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
      <li>
        <Link to="/teacher/watch_list"  onClick={() => {
        toggleSidebar();
        navigate("/teacher/watch_list");
      }}>
          <i className="ri ri-list-check-3 ri-lg me-3"></i> Watchlist
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
    
      {userTradeBook && (
      <li>
        <Link to="/trade_book"  onClick={() => {
        toggleSidebar();
        navigate("/trade_book");
      }}>
          <i className="ri ri-swap-2-line ri-lg me-3"></i> Trade Book
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
      )}
       {userOrderBook && (
      <li>
        <Link to="/order_book"  onClick={() => {
        toggleSidebar();
        navigate("/order_book");
      }}>
          <i className="ri ri-book-marked-line ri-lg me-3"></i> Order Book
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
       )}
   
  
    
      
      
     
    </ul>
  </div>
</div>



      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button
              onClick={handleCloseModal}
              className="btn btn-outline-secondary rounded-pill btn-sm "
            >
              <i className="ri-close-large-line me-2"></i>
              <span>Cancel</span>
            </button>
            <button
              onClick={handleConfirmLogout}
              className="btn btn-danger rounded-pill btn-sm"
            >
              <i className="ri-logout-box-r-line  me-2"></i>
              Logout
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
