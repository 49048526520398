import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Toast } from "primereact/toast";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";

const OrderbookDetails = () => {
   const { id } = useParams();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [backClicked, setBackClicked] = useState(false);
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const userId = localStorage.getItem("userId");

  const fetchOrderDetails = async () => {
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    if (!authToken || !userId) {
      navigate("/commonlogin");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_details`,
        {
          user_id: userId,
          uniqueorderid: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1 && response.data.data) {
        const orderDetails = response.data.data;
        setData([orderDetails]); 
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
     
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin"); 
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    const authToken = localStorage.getItem("authToken"); 

    if (!userId) {
      setError(new Error("User ID not found"));
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_details`,
        {
          user_id: userId,
          uniqueorderid: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        const orderDetails = response.data.data;
        setData([orderDetails]);
        const errorMsg = response.data.msg || "Success";
      } else if (response.data && response.data.st === 2) {
        const errorMsg = response.data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    } catch (error) {
      console.clear(); 
     
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin"); 
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]); 

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

     
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); 

   
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary";
  };


  
  // Function to render the transaction type
  const renderTransactionType = (rowData) => {
    const type = rowData.transactiontype || rowData.transactionType; // Check both fields
    const style = {
      color: type === "BUY" ? "green" : "orange",
    };
    return <span style={style}>{type}</span>;
  };
  
  const formatExpiryDate = (dateString) => {
    // Month mapping
    const monthMap = {
      'JAN': 'Jan',
      'FEB': 'Feb',
      'MAR': 'Mar',
      'APR': 'Apr',
      'MAY': 'May',
      'JUN': 'Jun',
      'JUL': 'Jul',
      'AUG': 'Aug',
      'SEP': 'Sep',
      'OCT': 'Oct',
      'NOV': 'Nov',
      'DEC': 'Dec',
    };
  
    // Ensure dateString is a string
    if (typeof dateString !== 'string') return dateString;
  
    
  
    // Check for the format 'DDMMMYYYY' (e.g., '10OCT2024')
    const dateMatch = dateString.match(/^(\d{2})([A-Z]{3})(\d{4})$/);
    if (dateMatch) {
      const day = dateMatch[1];
      const monthAbbr = dateMatch[2];
      const year = dateMatch[3];
      const month = monthMap[monthAbbr]; // Map month abbreviation to full month name
      return `${day} ${month} ${year}`; // Format: "10 Oct 2024"
    }
  
    // Check if dateString is in 'YYYY-MM-DD' format
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      const [year, month, day] = dateString.split('-');
      const monthAbbr = monthMap[month]; // Get abbreviated month
      return `${day} ${monthAbbr} ${year}`; // Format: "10 Oct 2024"
    }
  
    // Optionally, log a warning for unexpected format
    console.warn(`Invalid date format: ${dateString}`);
    return dateString; // Return the original string if formatting fails
  };
  
  // Render function to get the formatted expiry date
  const renderExpiryDate = (rowData) => {
    const expiryDate = rowData.expirydate || rowData.drvExpiryDate || "";
    return formatExpiryDate(expiryDate);
  };
  

  const renderOrderStatus = (rowData) => {
    const status = rowData.orderstatus || rowData.orderStatus; // Check both fields
    const style = {
      color: status && status.toLowerCase() === "complete" ? "green" : "orange",
    };
  
    const formattedStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  
    return <span style={style}>{formattedStatus}</span>;
  };

  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <div className="d-flex justify-content-between align-items-center">
            <ol className="breadcrumb breadcrumb-style1 text-black">
              <li className="breadcrumb-item">
                <Link to="/teacher/dashboard" className="text-black">
                  <i className="ri-home-7-line ri-lg"></i>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/order_book" className="text-black">
                  Order Book
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-secondary"
                aria-current="page"
              >
                Order Book Details
              </li>
            </ol>
            <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button
              onClick={handleBack}
              className="btn rounded-pill btn-outline-secondary btn-xs"
            >
              <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
            </button>

            <h5 className="mb-0 mx-auto">Order Book Details</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <div
                  className="spinner-border spinner-border-sm custom-spinner ms-3"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
            className="custom-column-border text-center "
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column
              align="center"
              className="custom-column-border"
              field="variety"
              header="Variety"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="ordertype"
              body={(rowData) => rowData.ordertype || rowData.orderType}

              header="Order Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="producttype"
              body={(rowData) => rowData.producttype || rowData.productType}

              header="Product Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="quantity"
              header="Quantity"
            ></Column>

            <Column
              align="center"
              className="custom-column-border"
              field="lotsize"
              header="Lot Size"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="tradingsymbol"
              body={(rowData) => rowData.tradingsymbol || rowData.tradingSymbol}

              header="Symbol"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="transactiontype"
              header="Transaction Type"
              body={renderTransactionType}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="exchange"
              body={(rowData) => rowData.exchange || rowData.exchangeSegment}

              header="Exchange"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="instrumenttype"
              header="Instrument Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="strikeprice"
              header="Strike Price"
              body={(rowData) => rowData.strikeprice || rowData.drvStrikePrice}

            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="optiontype"
              header="Option Type"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="expirydate"
              header="Expiry Date"
              body={renderExpiryDate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="orderstatus"
              header="Order Status"
              body={renderOrderStatus}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default OrderbookDetails;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
