import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import config from "../../../component/config";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import CardLinks from "../../../component/CardLinks";
import Modal from "react-bootstrap/Modal";
const AdminFeatureRequest = () => {
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [featureData, setFeatureData] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); 
  const [selectedFeature, setSelectedFeature] = useState(null); 
  const [deleteLoading, setDeleteLoading] = useState(false);
  const toast = useRef(null);
 
  useEffect(() => {
   
    const fetchFeatureRequests = async () => {
      setLoading(true);
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }

      try {
        const response = await axios.get(`${config.apiDomain}/api/admin/feature_request/list`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          },
        });

        if (response.data.st === 1) {
          setFeatureData(response.data.data);
        } else {
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/commonlogin'); 
        } else {
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeatureRequests();
  }, []);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleView = (id) => {
    navigate(`/admin/feature_request/Feature_view/${id}`);
  };

  const handleRefresh = async () => {
    setLoading(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.get(`${config.apiDomain}/api/admin/feature_request/list`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
      });

      if (response.data.st === 1) {
        setFeatureData(response.data.data);
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg || 'Failed to fetch data', life: 3000 });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin'); // Navigate to login page on 401 Unauthorized
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: error.response?.data?.msg || 'An error occurred while submitting the request.', life: 3000 });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.delete(`${config.apiDomain}/api/admin/feature_request/delete`, {
        headers: { Authorization: `Bearer ${authToken}` },
        data: { feature_request_id: selectedFeature.feature_request_id },
      });

      if (response.data.st === 1) {
        setFeatureData((prevData) => prevData.filter((item) => item.feature_request_id !== selectedFeature.feature_request_id));
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg || 'Failed to delete feature request', life: 3000 });
      }
    } catch (error) {
      console.error("Error deleting feature request:", error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while deleting the feature request.', life: 3000 });
    } finally {
      setDeleteLoading(false);
      setShowModal(false);
    }
  };

  const openDeleteModal = (rowData) => {
    setSelectedFeature(rowData);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFeature(null);
  };
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Feature Request
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 col-md-4 col-lg-4  text-center">
              <h5 className="mb-0">Feature Request</h5>
            </div>
            <div className="col-2"></div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>
              {loading ? (
                <div className="d-flex align-items-center">
                  <div
                    className="spinner-border spinner-border-sm custom-spinner ms-3"
                    role="status"

                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div >
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary" role="button"
                    data-pr-tooltip="Reload"
                    onClick={handleRefresh}
                    data-pr-position="top"

                  ></i>
                </div>
              )}
            </div>
          </div>

          <DataTable
            value={featureData}
            rows={20}
            paginator
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="p-datatable-rounded border border-1 text-center"
          >
            <Column
              field="name"
              align={"center"}
              header="Name"
              className="border border-1 text-capitalize"
            ></Column>


            <Column
              field="product"
              align={"center"}
              className="border border-1 text-capitalize"
              header="Product"
            ></Column>
            <Column
              field="feature"
              align={"left"}
              className="border border-1 text-capitalize w-100"
              header="Feature"
           
            ></Column>
            <Column
              align={"center"}
              className="border border-1 w-25"
              header="Actions"
              // style={{ width: "150px" }} 
              body={(rowData) => (
                <div className="d-flex flex-nowrap justify-content-center align-items-center">

                  <button
                    className="btn btn-info me-3 custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                    onClick={() => handleView(rowData.feature_request_id)}
                  >
                    <i className="ri-gallery-view-2 ri-lg"></i>
                  </button>

                  <button
                    className="btn btn-danger active text-align custom-btn-action1 custom-target-icon "
                    data-pr-tooltip="Delete Record"
                    data-pr-position="top"
                    onClick={() => openDeleteModal(rowData)}

                  >
                    <i className="ri-delete-bin-line ri-lg"></i>
                  </button>
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure?
          </Modal.Body>
          <Modal.Footer>
           
            <div className="d-flex justify-content-between w-100">
                          <button
                            onClick={closeModal}
                            className="btn btn-outline-secondary rounded-pill btn-sm"
                          >
                            <i className="ri-close-large-line me-2"></i>
                            <span>Cancel</span>
                          </button>
                          <button
                            onClick={handleDelete}
                            disabled={deleteLoading}
                            className="btn btn-danger rounded-pill btn-sm "
                           
                          >
                           <i className="ri-delete-bin-line me-2"></i>
                            {deleteLoading ? "Deleting" : "Delete "}
                          </button>
                        </div>
          </Modal.Footer>
        </Modal>

        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
};

export default AdminFeatureRequest;
