import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../component/config";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const Billing = () => {
  const [backClicked, setBackClicked] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [hoveredPlan, setHoveredPlan] = useState(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (filterType) => {
    setFilter(filterType);
  };

  const filteredSubscriptions = subscriptionData.filter(
    (subscription) =>
      filter === "all" || (filter === "active" && subscription.subscribed)
  );
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");
        if (!authToken || !userId) {
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/view_all_subscription`,
          {
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setSubscriptionData(response.data.subscription_list || []);
        } else {
        }
      } catch (error) {
        console.clear();
      }
    };

    fetchSubscriptionData();
  }, [userId, authToken]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleViewDetails = (subscriptionId, subscriptionType) => {
    navigate(
      `/teacher/subscription_plan/${subscriptionId}/${subscriptionType}`
    );
  };

  return (
    <div>
      <Header />
      <SubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <div className="d-flex justify-content-between align-items-center">
            <ol className="breadcrumb breadcrumb-style1 text-black">
              <li className="breadcrumb-item">
                <Link to="/teacher/dashboard" className="text-black">
                  <i className="ri-home-7-line ri-lg"></i>
                </Link>
              </li>

              <li
                className="breadcrumb-item active text-secondary"
                aria-current="page"
              >
                Subscriptions
              </li>
            </ol>
            <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Subscriptions</h5>
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-end mb-5">
              <Link to="/teacher/payment_history">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-money-rupee-circle-line me-1 ri-lg"></i>{" "}
                    <span>Payment History</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-12  text-center">
            Listed here are all your active subscriptions
          </div>
          <div>
            <div className="col-md-12   text-center">
              <div className="mt-4">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    onClick={() => handleFilterChange("all")}
                    className={`btn ${
                      filter === "all"
                        ? " btn-outline-success"
                        : " btn-outline-secondary"
                    }`}
                  >
                    All
                  </button>

                  <button
                    type="button"
                    onClick={() => handleFilterChange("active")}
                    className={`btn ${
                      filter === "active"
                        ? " btn-outline-info"
                        : " btn-outline-secondary"
                    }`}
                  >
                    Subscribed
                  </button>
                </div>
              </div>
            </div>
          </div>
          {filteredSubscriptions && subscriptionData.length > 0 ? (
            <div className="row">
              {filteredSubscriptions.map((subscription) => {
                // Get the active plan if exists, otherwise get the first plan for subscriptions like "Telegram"
                const activePlan = subscription.plans.find(
                  (plan) => plan.active
                );
                const fallbackPlan =
                  subscription.plans.length > 0 ? subscription.plans[0] : null;
                const servicePeriod = activePlan
                  ? parseInt(activePlan.service_period)
                  : fallbackPlan
                  ? parseInt(fallbackPlan.service_period)
                  : 0;
                const price = activePlan
                  ? activePlan.price
                  : fallbackPlan
                  ? fallbackPlan.price
                  : "N/A";
                const planDescription = activePlan
                  ? activePlan.plan_description
                  : fallbackPlan
                  ? fallbackPlan.plan_description
                  : "No Description Available";

                return (
                  <div
                    className="col-12 mt-4 p-5"
                    key={subscription.subscription_id}
                    onClick={() =>
                      handleViewDetails(
                        subscription.subscription_id,
                        subscription.subscription_type
                      )
                    }
                  >
                    <div
                      className="card mb-6 border border-2 card-hover1 rounded"
                      role="button"
                    >
                      <div className="card-body">
                      <div className="row">
  <div className="col-12 col-md-8">
    <h4 className="text-start mb-0  fs-4 text-uppercase">
      {subscription.subscription_name}
      {activePlan && (
      
          <i className="ri-verified-badge-line text-info ms-3 ri-lg"></i>
        
      )}
    </h4>
  </div>

  <div className="col-12 col-md-4 text-md-end mt-2 mt-md-0">
    <div className="d-flex justify-content-md-end flex-wrap">
      {subscription.plans.map((plan, index) => (
        <span
          key={index}
          className={`btn rounded-pill btn-xs ${
            plan.active ? "btn-outline-info bg-label-info" : "btn-outline-secondary"
          } me-2 mt-2`}
        >
          {plan.active && (
            <i className="ri-checkbox-circle-line text-info ri-lg me-1"></i>
          )}
          <span className="text-capitalize">{plan.name}</span>
        </span>
      ))}
    </div>
  </div>
</div>



                        <div className="row mt-6 mt-md-0 mt-lg-0">
                          <div className="col-12 col-md-6 col-lg-6 mt-1">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <small>{subscription.description}</small>
                            </div>

                            <div>
                              <div className="column mt-1">
                                <div className="row">
                                  <div className="col-6 mt-6">
                                    {activePlan && (
                                      <span className="fs-6 fs-md-5 d-block">
                                        <strong>Days</strong>
                                      </span>
                                    )}
                                  </div>

                                  {subscription.expiry_date && (
                                    <div className="col-6 text-end mt-6">
                                      <span className="fs-5 d-block">
                                        <strong className="fs-6 fs-md-5 d-block">
                                          {subscription.completed_days} Of{" "}
                                          {servicePeriod} Days
                                        </strong>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {activePlan && (
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width: `${
                                        (subscription.completed_days /
                                          servicePeriod) *
                                        100
                                      }%`,
                                    }}
                                    aria-valuenow={
                                      (subscription.completed_days /
                                        servicePeriod) *
                                      100
                                    }
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              )}
                              {activePlan && (
                                <span className="d-block mt-2">
                                  {activePlan.completed_days !== null &&
                                    activePlan.service_period !== null &&
                                    activePlan.service_period -
                                      activePlan.completed_days <=
                                      5 && (
                                      <small>
                                        Your Plan requires an update
                                      </small>
                                    )}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-6">
                            {activePlan && (
                              <>
                                <span className="fs-5 d-block">
                                  <strong className="fs-3">₹{price}</strong>
                                  <small className="ms-2">
                                    Per {servicePeriod} Days
                                  </small>
                                </span>
                                <span className="d-block mt-3">
                                  <small>{planDescription}</small>
                                </span>
                              </>
                            )}
                            {subscription.expiry_date && (
                              <>
                                <span className="d-block mt-5">
                                  <strong>
                                    Expire Date {subscription.expiry_date}
                                  </strong>
                                </span>

                                <span className="d-block">
                                  <small>
                                    We will send you a notification upon
                                    subscription expiration
                                  </small>
                                </span>
                              </>
                            )}
                            {!activePlan && (
                              <div className="d-flex justify-content-end align-items-end mt-5">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                >
                                  <i className="ri ri-medal-2-line me-2 ri-lg"></i>{" "}
                                  <span>Subscribe Now</span>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center mt-5">
              <p className="mt-6 fs-3">
                No Active Subscription.
                <i className="ri-emotion-unhappy-line"></i>
              </p>
              <p>
                <Link to="">
                  View all subscriptions & offers here{" "}
                  <i className="ri-external-link-line"></i>
                </Link>
              </p>
            </div>
          )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Billing;
