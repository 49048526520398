import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import zerodhaImg from "../../../assets/img/products/company/zerodha.png";
import dhanImg from "../../../assets/img/products/company/dhan.png";
import upStockImg from "../../../assets/img/products/company/upstock.png";
import angleOneImg from "../../../assets/img/products/company/angle_one.png";
import heroElement from "../../../assets/img/products/3d-business-man.png";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import ServicesOffered from "./ServicesOffered";
import Features from "./Features";
import MetaTags from "./MetaTags";


function LandingHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags />
      <LandingHeader />
      <div data-bs-spy="scroll" className=" scrollspy-example pt-10 mt-5 ">
        <section id="landingHero" className="position-relative bg-white ">
          
          <div className="container-xxl">
            <div className="row align-items-center">
              <div className="col-lg-6 ">
                <div className="text-center text-lg-start">
                  <h2 className="fs-1 hero-title mb-4 poppins-bold text-start pt-5">
                    A Digital Agency <br />
                    Specialized in AI and <br />
                    WEB 3.0
                  </h2>
                  <div className="fs-5 mb-8 lh-md ">
                    <p className="pb-0 mb-0 text-start ">
                      We work in a customized way of devising and handling
                      entirely online projects with long-term partnerships.
                    </p>
                  </div>
                  <div className="">
                    <Link to="/signup">
                    <button
                      type="button"
                      className="btn rounded-pill btn-outline-info waves-effect mx-2"
                    >
                      Get Started
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end">
                <img
                  src={heroElement}
                  alt="hero elements"
                  className="img-fluid"
                  style={{
                   
                    width: "auto",
                    height: "auto", 
                    maxWidth: "100%", 
                  }}
                />
              </div>
            </div>
          </div>
        </section>
<div className="my-4">
        <marquee behavior="scroll" direction="left" scrollamount="15" hspace="0" vspace="0%" width="100%">
    <img  src={angleOneImg}  className="mx-4 h-2 w-4" />
    <img  src={zerodhaImg}  className="mx-4 h-2 w-4" />
    <img  src={dhanImg}  className="mx-4 h-2 w-4"/>
    <img src={upStockImg} className="mx-4 h-2 w-4" />
   
  </marquee>
  </div>
          <div className="container-xxl mt-5  mb-5">
        <div className="text-center mb-5 mt-10">
          <h3 className="fw-bold">   What AutoProfito help you?</h3>
          <p className="mb-1">
            All plans include 40+ advanced tools and features to boost your
            products.
          </p>
          <p className="mt-1 mb-10">Choose the best plan to fit your needs.</p>
        </div>
          {/* <ReviewCard/> */}

          {/* <SliderComponent/> */}

          <div className="row">
            <div className="col-md-12 col-lg-4 d-flex align-items-stretch pb-5">
              <div className="card text-center flex-fill">
                <div className="card-body d-flex flex-column justify-content-center">
                  <i className="ri ri-cloud-line text-primary fs-1 mb-3"></i>
                  <h5 className="card-title">Cloud Based</h5>
                  <p className="card-text">
                    Full cloud based automated system with simple automation and
                    easy to use algorithm trading system.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 d-flex align-items-stretch pb-5">
              <div className="card text-center flex-fill">
                <div className="card-body d-flex flex-column justify-content-center">
                  <i className="ri ri-pencil-ruler-line text-primary fs-1 mb-3"></i>
                  <h5 className="card-title">Multiple Accounts</h5>
                  <p className="card-text">
                    Automate trading in multiple accounts with no software
                    installation and VPS requirement.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 d-flex align-items-stretch pb-5">
              <div className="card text-center flex-fill">
                <div className="card-body d-flex flex-column justify-content-center">
                  <i className="fri ri-upload-cloud-line text-primary fs-1 mb-3"></i>
                  <h5 className="card-title">Rapid Scale up in Cloud</h5>
                  <p className="card-text">
                    Add as many accounts as you want with custom plans with
                    practical features.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 col-lg-4 d-flex align-items-stretch">
              <div className="card text-center flex-fill">
                <div className="card-body d-flex flex-column justify-content-center">
                  <i className="fa-solid fa-cloud-arrow-up text-primary fs-1 mb-3"></i>
                  <h5 className="card-title">Rapid Scale up in Cloud</h5>
                  <p className="card-text">
                    Add as many accounts as you want with custom plans with
                    practical features.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* SKILLS / BROKER  */}
        <div className="bg-white mt-10 pb-5">
        
           <div className="container-xxl  pt-4 mb-5">
        <div className="text-center mb-5 mt-8">
          <h3 className="fw-bold">Brokers</h3>
          <p className="mb-1">
          Our UX and UI design proficiency ensures seamless
          </p>
          <p className="mt-1 mb-10">interaction
          in the digital realm.</p>
        </div>
      </div>

          <div className="container-xxl my-4">
            <div className="row">
              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          AngelOne
                        </div>
                      </div>
                      <div className="col-6 d-flex">
                        <img
                          src={angleOneImg}
                          alt="AngelOne"
                          className="img-fluid"
                          style={{ height: "30px", width: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites.
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress"
                          style={{ height: "12px", width: "100%" }}
                        >
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            100%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          Zerodha
                        </div>
                      </div>
                      <div className="col-6 d-flex ">
                        <img
                          src={zerodhaImg}
                          alt="Zerodha"
                          className="img-fluid"
                          style={{ height: "30px", maxWidthwidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites.
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress bg-light"
                          style={{
                            height: "12px",
                            width: "100%",
                          }}
                        >
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "50%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            50%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          Dhan
                        </div>
                      </div>
                      <div className="col-6 d-flex">
                        <img
                          src={dhanImg}
                          alt="Dhan"
                          className="img-fluid"
                          style={{ height: "30px", maxWidthwidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites .
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress bg-light"
                          style={{
                            height: "12px",
                            width: "100%",
                          }}
                        >
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "50%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            50%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 mb-4">
                <div className="card d-flex flex-column shadow-none bg-transparent border border-secondary h-100">
                  <div className="card-body d-flex flex-column">
                    <div className="row align-items-center flex-grow-1">
                      <div className="col-6 d-flex justify-content-start">
                        <div
                          className=" text-start text-dark poppins-medium"
                          style={{ fontSize: "32px" }}
                        >
                          Upstock
                        </div>
                      </div>
                      <div className="col-6 d-flex">
                        <img
                          src={upStockImg}
                          alt="UpStock"
                          className="img-fluid"
                          style={{ height: "30px", maxWidthwidth: "150px" }}
                        />
                      </div>
                    </div>
                    <div className="row align-items-center mt-3">
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <p className="lh-base fs-6">
                          Bootstrap is a front-end framework that makes it easy
                          to create responsive and mobile-friendly websites.
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <div
                          className="progress bg-light"
                          style={{
                            height: "12px",
                            width: "100%",
                          }}
                        >
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "50%" }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            50%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </div>
      <Features />
      <ServicesOffered />
      <LandingFooter />
    </>
  );
}

export default LandingHome;
