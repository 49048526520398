import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import autoprofito_logo from "../../../assets/img/products/company/autoprofito_logo.png"
const StudentHeader = () => {
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    navigate("/commonlogin");
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const userTradeBook = JSON.parse(localStorage.getItem("userTradeBook"));
  const userOrderBook = JSON.parse(localStorage.getItem("userOrderBook"));
  const navigate = useNavigate();
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
   
  };
  const [currentTime, setCurrentTime] = useState(new Date());
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept", // Custom abbreviation
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsDate = { weekday: "long", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    
    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); 
  };
  const closeDropdown = () => setDropdownOpen(false);
  return (
    <div>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center  border border-bottom"
        id="layout-navbar"
      >
        <div className="container-xxl d-flex justify-content-between align-items-center">
        <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-6">
            <div className="app-brand-logo demo">
              <img
                src={autoprofito_logo}
                alt="AutoProfito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span className="app-brand-text demo menu-text fw-semibold ms-1">
              AutoProfito
            </span>
          </div>

         
          <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
            <button className="nav-item nav-link px-0 me-xl-6 btn" onClick={toggleSidebar}>
              <i className="ri-menu-line ri-xl"></i>
            </button>
          </div>
          <div className=" d-xl-block">
            <span className="text-secondary">
              {formattedDate}
            </span>
            <br></br>

            <strong className="fs-4">{formattedTime}</strong>
          </div>
          
          <div className="d-flex align-items-center">
          <div className="text-end d-none me-3 d-xl-block">
              <h6 className=" fs-5 mb-0 bold text-uppercase">
                {toTitleCase(userName)}
              </h6>
              <div className="position-relative d-inline-block my-2">
                <Link to="/teacher/user_profile">
                  <span className="btn btn-outline-secondary rounded-pill btn-xs ">
                    {toTitleCase(userRole)}
                  </span>
                </Link>
              </div>
            </div>
            <div className=" align-items-xl-center me-4 me-xl-0 ">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <div
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  onClick={toggleDropdown}
                  role="button"
                >
                   <div className=" position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-info border-3 rounded-circle bg-label-info avatar-lg">
                      <span className="avatar-initial rounded-circle bg-label-info fs-2">
                        {userName && userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
                {dropdownOpen && (
                <ul  className="dropdown-menu dropdown-menu-end mt-3  me-3 show"
                   style={{ right: "0", left: "auto", width:"300px" }}
                   onMouseLeave={closeDropdown}
                >
                <li>
  <div className="d-flex justify-content-start align-items-center ms-4">
    {/* Avatar */}
    <div className="d-flex justify-content-center align-items-center border border-info border-3 rounded-circle bg-label-info avatar-lg me-3">
      <span className="avatar-initial rounded-circle bg-label-info fs-2">
        {userName && userName.charAt(0).toUpperCase()}
      </span>
    </div>

    {/* User Info */}
    <div className="text-start">
      <h6 className="fs-6 mb-0 bold text-uppercase">
        {toTitleCase(userName)}
      </h6>
      <div className="position-relative d-inline-block my-2">
     
          <span className="btn btn-outline-secondary rounded-pill btn-xs">
            {toTitleCase(userRole)}
          </span>
       
      </div>
    </div>
  </div>
</li>
<hr className="mb-0 mt-0"></hr>
                  <li>
                    <Link to="/app2/student_profile" className="dropdown-item"  onClick={closeDropdown}>
                      <i className="ri-user-line ri-22px me-2"></i>
                      <span className="align-middle">Profile</span>
                    </Link>
                  </li><li>
                    <Link to="/app2/student_my_report" className="dropdown-item"  onClick={closeDropdown}>
                    
                      <i className="ri-bar-chart-box-line ri-22px me-2"></i>
                      <span className="align-middle">My Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/student/feature_request" className="dropdown-item"  onClick={closeDropdown}>
                      <i className="ri-sparkling-line ri-22px me-2"></i>
                      <span className="align-middle">Feature Request</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/student/chat" className="dropdown-item"  onClick={closeDropdown}>
                      <i className="ri-chat-2-line ri-22px me-2"></i>
                      <span className="align-middle"> Chat</span>
                    </Link>
                  </li>
                
                  <hr className="mb-0 mt-0"></hr>
                  <li>
                   
                    <div className="d-grid px-4 pt-2 pb-1">
                      <div
                       
                       onClick={handleShowModal}
                        className="dropdown-item btn btn-danger d-flex align-items-center"
                      >
                        <i className="ri-logout-box-r-line ri-lg me-1"></i>{" "}
                        <span className="align-middle">Logout</span>
                      </div>
                    </div>
                  </li>
                  
                </ul>
                )}
              </li>
            </ul>
            </div>
          </div>

         
        </div>
      </nav>


      <div className={`sidebar ${isSidebarOpen ? "active" : ""}`}>
  <div className="sidebar-content">
  
    <div className="sidebar-header d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="app-brand-logo demo">
          <img
            src={autoprofito_logo}
            alt="AutoProfito Logo"
            className="w-px-40 h-auto rounded-circle"
          />
        </div>
        <span className="app-brand-text demo menu-text fw-semibold ms-2">
          AutoProfito
        </span>
      </div>
      <span className="close-icon text-end" onClick={toggleSidebar}>
        <i className="ri ri-close-line ri-lg ms-3"></i>
      </span>
    </div>
    <hr />

 
    <ul className="sidebar-nav mt-4">
      <li>
        <Link to="/student/dashboard"  onClick={() => {
        toggleSidebar();
        navigate("/student/dashboard");
      }}>
          <i className="ri ri-home-7-line ri-lg me-3"></i> Home
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
     
      
     



    
       {userTradeBook && (
      <li>
        <Link to="/app2/student_trade_book"  onClick={() => {
        toggleSidebar();
        navigate("/app2/student_trade_book");
      }}>
          <i className="ri ri-swap-2-line ri-lg me-3"></i> Trade Book
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
      )}
       {userOrderBook && (
      <li>
        <Link to="/app2/order_book"  onClick={() => {
        toggleSidebar();
        navigate("/app2/order_book");
      }}>
          <i className="ri ri-book-marked-line ri-lg me-3"></i> Order Book
          <i className="ri ri-arrow-right-s-line sidebar-arrow"></i>
        </Link>
      </li>
       )} 
   
  
    
      
      
     
    </ul>
  </div>
</div>



      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure?
        </Modal.Body>
        <Modal.Footer>
          
          <div className="d-flex justify-content-between w-100">
                            <button
                              onClick={handleCloseModal}
                              className="btn btn-outline-secondary rounded-pill btn-sm "
                            >
                              <i className="ri-close-large-line me-2"></i>
                              <span>Cancel</span>
                            </button>
                            <button
                              onClick={handleConfirmLogout}
                              
                              className="btn btn-danger rounded-pill btn-sm"
                            >
                                <i className="ri-logout-box-r-line  me-2"></i>
                            Logout
                            </button>
                          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentHeader;
