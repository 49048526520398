import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
import CardLinks from "../../../component/CardLinks";
import dhanlogo from "../../../assets/logo/dhanlogo.jpeg"
const ManageStudent = () => {
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const handleClose = () => setShowConfirmModal(false);
  const handleShow = (rowData) => {
    setRowToDelete(rowData);
    setShowConfirmModal(true);
  };
  const confirmDelete = (rowData) => {
    handleShow(rowData);
  };

  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [backClicked, setBackClicked] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [errorDetails, setErrorDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [dropdownValue, setDropdownValue] = useState("with-broker");  
  const API_BASE_URL = `${config.apiDomain}/api/teacher/bulk_upload_students`;
  const [activeStatusCount, setActiveStatusCount] = useState(0);
  const [tradeStatusCount, setTradeStatusCount] = useState(0);
  const userId = localStorage.getItem("userId");
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);
  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop();
      if (
        !allowedTypes.includes(selectedFile.type) &&
        !(fileType === "csv" || fileType === "xlsx")
      ) {
        setError("Please select a CSV or Excel file.");
        setFile(null);
        setFileName("");
      } else {
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setError(null);
      }
    } else {
      setFile(null);
      setFileName("");
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    setIsLoading(true);
    setError(null);
    setErrorDetails([]);

    const formData = new FormData();
    formData.append("teacher_id", userId);
    formData.append("csv_file", file);

    const authToken = localStorage.getItem("authToken");

    const apiUrl = `${API_BASE_URL}?with_broker_details=${
      dropdownValue === "with-broker"
    }`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessCount(data.success_count);
        setErrorCount(data.error_count);
        setErrorDetails(data.error_details);
        setFileUploaded(true);
      
        if (data.st === 1) {
          if (fileInputRef.current) {
            fileInputRef.current.value = ""; 
          }
        }
       
      } else {
        setError(data.msg || "Failed to upload file.");
      }
    } catch (error) {
      setError("Failed to upload file.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetModal = () => {
    setFile(null);
    setFileName("");
    setError("");
    setFileUploaded(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = ""; 
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }

      const response = await fetch(
        `${config.apiDomain}/api/teacher/manage_students/listview`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      const data = await response.json();
      if (response.ok && data.st === 1) {
        setStudents(data.data);
        const totalCount = data.data.length;
        const activeCount = data.data.filter(
          (item) => item.active_status
        ).length;
        const tradeCount = data.data.filter((item) => item.trade_status).length;

        setActiveStatusCount(activeCount);
        setTradeStatusCount(tradeCount);
        setTotalCount(totalCount);
      } else {
      
        setError(data.message || "Failed to fetch students");
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin"); }
        else {
      const errorMsg = error.response
        ? error.response.data.msg || "Failed to fetch data"
        : error.message || "Failed to fetch data";
      setError(errorMsg); 
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMsg,
        life: 3000,
      });
    }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggleTrade = async (studentId) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("Auth token not found");

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/student_trade_switch`,
        { student_id: studentId },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.st === 1) {
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.student_id === studentId
              ? { ...student, trade_status: !student.trade_status }
              : student
          )
        );
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.msg || "Trade status updated successfully",
          life: 3000,
        });
        // await handleRefresh();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.msg || "Error switching trade status",
          life: 3000,
        });
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.msg || "Error switching trade status",
          life: 3000,
        });
      }
    }
  };

  const handleToggle = async (studentId) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("Auth token not found");

      const response = await fetch(
        `${config.apiDomain}/api/teacher/student_active_switch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ student_id: studentId }),
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.st === 1) {
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.student_id === studentId
              ? { ...student, active_status: !student.active_status }
              : student
          )
        );
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Student status updated successfully",
          life: 3000,
        });
        // await handleRefresh();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: responseData.msg || "Failed to update student status",
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Network error",
        life: 3000,
      });
      console.clear(); 
    }
  };

  const handleDelete = async () => {
    if (!rowToDelete) return;

    try {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");

      if (!authToken) throw new Error("Auth token not found");

      const response = await fetch(
        `${config.apiDomain}/api/teacher/manage_students/delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            student_id: rowToDelete.student_id,
            teacher_id: userId,
          }),
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.st === 1) {
        const updatedStudents = students.filter(
          (student) => student.student_id !== rowToDelete.student_id
        );
        setStudents(updatedStudents);
      } else if (responseData.st === 401) {
       
        navigate("/commonlogin");
      }
    } catch (error) {
      console.clear(); 
    } finally {
      setShowConfirmModal(false);
    }
  };

  

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await fetch(
        `${config.apiDomain}/api/teacher/manage_students/listview`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      const data = await response.json();
      if (response.ok && data.st === 1) {
        setStudents(data.data);
        const totalCount = data.data.length;
        const activeCount = data.data.filter(
          (item) => item.active_status
        ).length;
        const tradeCount = data.data.filter((item) => item.trade_status).length;

        setActiveStatusCount(activeCount);
        setTradeStatusCount(tradeCount);
        setTotalCount(totalCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
    
      setLoading(false);
      
      if (error.response && error.response.status === 401) {
        
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
        console.clear(); 
      }
    } finally {
      setLoading(false);
    }
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div className="row align-items-center">
        <div className="col-12 col-md-2 d-flex justify-content-center">
          <label
            className={`switch switch-square ${
              rowData.active_status ? "bg-success" : ""
            }`}
          >
            <input
              type="checkbox"
              className="switch-input"
              checked={rowData.active_status}
              onChange={() => handleToggle(rowData.student_id)}
            />
            <span
              className={`switch-toggle-slider ${
                rowData.active_status ? "bg-success" : ""
              }`}
            >
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
          </label>
        </div>
  
        <div className="col-12 col-md-10 mt-3 mt-md-0">
          <div className="text-capitalize mb-1 fs-5">
            <strong>{rowData.name}</strong>
          </div>
          <div className="text-muted">{rowData.mobile}</div>
        </div>
      </div>
    );
  };
  

  const lotSizeBodyTemplate = (rowData) => {
    return `${rowData.lot_size_limit} Lot`;
  };

  const commissionBodyTemplate = (rowData) => {
    return `${rowData.commission}%`;
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

    
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); 

   
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary"; 
  };
  const tradeStatusBodyTemplate = (rowData) => {
    return (
      <div className="row align-items-center">
        {rowData.broker_status ? (
          <>
            <div className="col-12 col-md-3">
              <label
                className={`switch switch-square ${
                  rowData.trade_status ? "bg-success" : ""
                }`}
              >
                <input
                  type="checkbox"
                  className="switch-input"
                  checked={rowData.trade_status}
                  onChange={() => handleToggleTrade(rowData.student_id)}
                />
                <span
                  className={`switch-toggle-slider ${
                    rowData.trade_status ? "bg-success" : ""
                  }`}
                >
                  <span className="switch-on"></span>
                  <span className="switch-off"></span>
                </span>
              </label>
            </div>
  
            <div className="col-12 col-md-8 mt-2 mt-md-0">
              <div className={rowData.trade_status ? "text-success" : "text-muted"}>
              {rowData.broker_name === "angle_one" && (
                <img
                  src={dhanlogo} // Assuming correct path for Angel One
                  alt="Angel One"
                  style={{ height: "20px" }}
                  className="me-2"
                />
              )}
              {rowData.broker_name === "dhan" && (
                <img
                  src={dhanlogo}
                  alt="Dhan"
                  style={{ height: "20px" }}
                  className="me-2"
                />
              )}
                {getBrokerDisplayName(rowData.broker_name)}
              </div>
              <div className="text-muted mt-2 mt-md-0">
                {rowData.broker_account_balance || 0} Rs
              </div>
            </div>
          </>
        ) : (
          <div>Broker Not Connected</div>
        )}
      </div>
    );
  };
  

  const brokerNameMap = {
    angle_one: "AngelOne",
    dhan:"Dhan"
  };

  const getBrokerDisplayName = (brokerName) => {
    return brokerNameMap[brokerName] || brokerName;
  };
  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
  <nav aria-label="breadcrumb">
    <div className="row d-flex justify-content-between align-items-center">
      <div className="col d-flex justify-content-start">
        <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
          <li className="breadcrumb-item">
            <Link to="/teacher/dashboard" className="text-black">
              <i className="ri-home-7-line ri-lg"></i>
            </Link>
          </li>
          <li
            className="breadcrumb-item active text-secondary"
            aria-current="page"
          >
            Student
          </li>
        </ol>
      </div>
      <div className="col d-flex justify-content-end">
        <SubscriptionBadge />
      </div>
    </div>
  </nav>




        <div className="card p-4">
  <div className="row align-items-center mb-4">
    <div className="col-3 text-start">
      <button
        onClick={handleBack}
        className="btn rounded-pill btn-outline-secondary btn-xs"
      >
        <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
      </button>
    </div>
    <div className="col-6 col-md-4 col-lg-4 text-center">
      <h5 className="mb-0"> Student</h5>
    </div>
    <div className="col-3"></div>
  </div>


  <div className="row align-items-center mb-3">
    <div className="col-lg-7 col-md-6 col-sm-12 d-flex align-items-center mb-2 mb-md-0">
    
      <IconField iconPosition="left">
        <InputIcon className="ri ri-search-line"></InputIcon>
        <InputText
          type="search"
          placeholder="Search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="rounded"
        />
      </IconField>
      
      {loading ? (
        <div className="d-flex align-items-center">
          <div
            className="spinner-border spinner-border-sm custom-spinner ms-3"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="mt-3">
        <Tooltip target=".custom-target-icon" />
        <i
          className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
          role="button"
          data-pr-tooltip="Reload"
          onClick={handleRefresh}
          data-pr-position="top"
        ></i>
        </div>
      )}
    </div>

    
    <div className="col-lg-5 col-md-6 col-sm-12 text-end">
      <Tooltip target=".btn-upload" />
      <Button
        type="button"
        className="btn btn-primary btn-upload me-2"
        onClick={() => setModalOpen(true)}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        data-pr-tooltip="Student Bulk Upload"
        data-pr-position="top"
        icon="ri-upload-2-line"
      ></Button>

      <Link to="/teacher/manage_student/create_student">
        <button className="btn btn-success">
          <i className="ri-add-circle-line ri-lg me-2"></i>
          <span>Create</span>
        </button>
      </Link>
    </div>
  </div>

 
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Bulk Upload Student Data
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={resetModal}
          ></button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <input
                type="file"
                className={`form-control me-2 ${error ? "is-invalid" : ""}`}
                id="fileUpload"
                onChange={handleFileChange}
                accept=".csv, .xls, .xlsx"
                ref={fileInputRef}
              />
              <select
                className="form-select me-2"
                value={dropdownValue}
                onChange={(e) => setDropdownValue(e.target.value)}
              >
                <option value="with-broker">With Broker Details</option>
                <option value="without-broker">Without Broker Details</option>
              </select>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <i className="ri-loader-2-line ri-lg me-2"></i> Submitting...
                  </>
                ) : (
                  <>
                    <i className="ri-checkbox-circle-line ri-lg me-1"></i> Submit
                  </>
                )}
              </button>
            </div>

            {error && (
              <p className="text-danger text-start mt-1 mb-0">{error}</p>
            )}

            <div className="d-flex justify-content-between mt-4 mb-6">
              <a href="/with_broker.csv" download="with_broker.csv" className="text-secondary">
                <i className="ri-download-cloud-line"></i> Sample File (With Broker Details)
              </a>
              <a href="/without_broker.csv" download="without_broker.csv" className="text-secondary">
                <i className="ri-download-cloud-line"></i> Sample File (Without Broker Details)
              </a>
            </div>
          </div>

        
          {fileUploaded && (
            <div>
              <div className="d-flex justify-content-between mt-4">
                <p className="text-start">
                  <i className="ri-checkbox-circle-line text-info"></i> Successfully uploaded: {successCount} Rows
                </p>
                <p className="text-start">
                  <i className="ri-close-circle-line"></i> Uploaded error: {errorCount} Rows
                </p>
              </div>
              <div className="table-responsive overflow-auto h-100">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">Rows</th>
                      <th className="text-center">Error messages</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorDetails.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{item.row}</td>
                        <td>{item.error}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>



          <DataTable
            value={students}
            paginator
            rows={20}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 20, 50]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            globalFilter={globalFilter}
            showGridlines
            emptyMessage="No customers found."
          
          >
            <Column
              align={"center"}
              className="custom-column-border"
              header="No."
              body={(rowData, { rowIndex }) => rowIndex + 1}
            ></Column>
            <Column
              align={"center"}
              className="custom-column-border"
              field="name"
              header={
                <div>
                  <span>Name</span>
                  <span className="text-muted me-1">
                    {" "}
                    ({activeStatusCount}/{totalCount})
                  </span>
                </div>
              }
              body={nameBodyTemplate}
            />

            <Column
              align={"center"}
              className="custom-column-border"
              field="lot_size_limit"
              header="Lot Size Limit"
              body={lotSizeBodyTemplate}
            />
            <Column
              align={"center"}
              className="custom-column-border"
              field="commission"
              header="Commission"
              body={commissionBodyTemplate}
            />

<Column
  align={"center"}
  className="custom-column-border  "
 
  header={
    <div className="ms-12 me-12">
      <span>Broker Conn. Status</span>
      <span className="text-muted me-1">
        {" "}
        ({tradeStatusCount}/{totalCount})
      </span>
    </div>
  }
  body={(rowData) => (
    <div
      className={classNames({
        "text-success": rowData.broker_status,
        "text-danger": !rowData.broker_status,
      })}
    >
      {rowData.broker_status ? (
        tradeStatusBodyTemplate(rowData)
      ) : (
        <>
          <div>Broker Not Connected</div>
          <div className="text-primary mt-1">Connect Now</div>
        </>
      )}
    </div>
  )}
/>

            <Column
              align={"center"}
              className="custom-column-border "
              header="Actions"
              body={(rowData) => (
                <div className="d-flex flex-nowrap justify-content-center align-items-center">

                  <Link
                    to={`/teacher/manage_student/view_student/${rowData.student_id}`}
                    state={{ teacherId: rowData.student_id }}
                  >
                    <button
                      className="btn btn-info me-3 custom-btn-action1 custom-target-icon "
                      data-pr-tooltip="View Record"
                      data-pr-position="top"
                    >
                      <i className="ri-gallery-view-2 ri-lg"></i>
                    </button>
                  </Link>

                  <Link
                    to={`/teacher/manage_student/update_student/${rowData.student_id}`}
                    state={{ teacherId: rowData.student_id }}
                  >
                    <button
                      className="btn btn-warning me-3 custom-btn-action1 custom-target-icon"
                      data-pr-tooltip="Update Record"
                      data-pr-position="top"
                    >
                      <i className="ri-edit-2-line ri-lg"></i>
                    </button>
                  </Link>

                  <button
                    className="btn btn-danger active text-align custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="Delete Record"
                    data-pr-position="top"
                    onClick={() => confirmDelete(rowData)}
                  >
                    <i className="ri-delete-bin-line ri-lg"></i>
                  </button>
                </div>
              )}
            />
          </DataTable>
        </div>
        <CardLinks></CardLinks>
        <Toast ref={toast} />
        <Modal show={showConfirmModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
              <button
                onClick={handleClose}
                className="btn btn-outline-secondary rounded-pill btn-sm"
              >
                <i className="ri-close-large-line me-2"></i>
                <span>Cancel</span>
              </button>
              <button
                onClick={handleDelete}
                className="btn btn-danger rounded-pill btn-sm"
              >
                <i className="ri-delete-bin-line me-2"></i>
                Delete
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default ManageStudent;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
