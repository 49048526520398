import React,{useEffect} from "react";
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";
import logo from "../../../assets/img/products/logo (2) (1).png";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <LandingHeader />
      <div className="container-xxl mt-10 pt-10 mb-5">
        <div className="text-center mb-5 mt-10">
          <h3 className="fw-bold">About us</h3>
          <p className="mb-1">
            All plans include 40+ advanced tools and features to boost your
            products.
          </p>
          <p className="mt-1 mb-10">Choose the best plan to fit your needs.</p>
        </div>
      </div>
      <section>
        <div className="container-xxl">
          <div className="container-xxl custom-container mt-10 pt-10">
            <div className="row mb-5 text-center d-none d-lg-block">
              
            </div>
            <div className="row align-items-center order-lg-1">
              <div className="col-lg-6 col-12 mb-3 mb-lg-0 order-sm-1 text-center text-lg-start">
                <span className="fs-3 mb-2 mb-sm-3 d-block fw-normal">
                  Autoprofito is simply used to manage Demat Accounts of your
                  Students
                </span>
              </div>
              <div className="col-lg-6 col-12 mb-3 mb-lg-0 order-sm-1 text-center text-lg-start">
                <div className="row">
                  <div className="col-6 col-md-3 col-lg-6 col mb-4">
                    <div
                      className="card shadow-none bg-transparent border border-secondary"
                      style={{ height: "100px", maxWidth: "250px" }}
                    >
                      <div className="card-body text-start">
                        <h3 className="card-title text-sm-black fw-bold">
                          3.5
                        </h3>
                        <p className="card-text">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-6 mb-4">
                    <div
                      className="card shadow-none bg-transparent border border-secondary"
                      style={{ height: "100px", maxWidth: "250px" }}
                    >
                      <div className="card-body text-start text-dark">
                        <h3 className="card-title fw-bold">23</h3>
                        <p className="card-text">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-6 mb-4">
                    <div
                      className="card shadow-none bg-transparent border border-secondary"
                      style={{ height: "100px", maxWidth: "250px" }}
                    >
                      <div className="card-body text-start">
                        <h3 className="card-title fw-bold">830+</h3>
                        <p className="card-text">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-6 mb-4">
                    <div
                      className="card shadow-none bg-transparent border border-secondary"
                      style={{ height: "100px", maxWidth: "250px" }}
                    >
                      <div className="card-body text-start">
                        <h3 className="card-title fw-bold">200K</h3>
                        <p className="card-text pt-0">Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-12 mb-4">
                <p>
                  Autoprofito has been the industry's standard demat account
                  since the 20's, when an unknown printer took a galley of type
                  manager scrambled it to make a type specimen book. It has
                  survived not centuries, but also the leap into electronic
                  typesetting, remaining essentially unchanged. It was
                  popularised in the 1960s, with the release sheets containing
                  lorem ipsum passages, and more.
                </p>
              </div>
             
            </div>
          </div>
        </div>
      </section>
      <div className="my-10 "></div>
      <LandingFooter />
    </>
  );
}

export default About;
