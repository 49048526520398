import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";

import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import config from "../../../component/config";
import Footer from "../../../component/Footer";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const MyReportView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [errors, setErrors] = useState({});
  const [month, setMonth] = useState("");
  const authToken = localStorage.getItem("authToken");
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  useEffect(() => {
    const { state } = location;


    if (!state || !state.userId || !state.month) {
      navigate(-1); 
      return;
    }

 
    setMonth(state.month);
    fetchData(state.userId, state.month);
  }, [location, navigate]);

  const fetchData = async (userId, month) => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_date: month,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, 
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin"); 
      } else {
       
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    try {
      const { userId, month } = location.state;
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_date: month,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, 
            "Content-Type": "application/json",
          },
        }
      );

      const { data } = response;
      const errorMsg =
        formatMessage(data?.msg) || "An unexpected error occurred";

      if (data) {
        setData(data.trades);
        setSummary(data.completed_trades_aggregate);

        if (data.st === 1) {
        } else if (data.st === 2) {
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: errorMsg,
            life: 3000,
          });
        } else if (data.st === 3 || data.st === 4) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMsg,
            life: 3000,
          });
        } else {
          setErrors({ global: "Unexpected response status" });
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Unexpected response status",
            life: 3000,
          });
        }
      } else {
        setErrors({ global: "No data found" });
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No data found",
          life: 3000,
        });
      }
    } catch (error) {
      const errorMsg = error.response?.data?.msg
        ? formatMessage(error.response.data.msg)
        : String(error.message || "Failed to fetch data");

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMsg,
        life: 3000,
        className: "text-start",
      });
    } finally {
      setLoading(false);
    }
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
 

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

    
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); 


    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

 
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary"; 
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };

 

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString("en-US", options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString("default", { month: "short" });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          Price: <strong>{rowData.buy_price} </strong> | Lot:
          <strong>
            {" "}
            {rowData.buy_lotsize} ({rowData.buy_stock_quantity})
          </strong>
          | <strong>{rowData.buy_type}</strong>
        </div>
        <div className="mb-1">
          {formatDateDay(rowData.buy_datetime)}-
          {formatDateMonth(rowData.buy_datetime)}-
          {formatDateYear(rowData.buy_datetime)}{" "}
          {formatDateTime(rowData.buy_datetime)}
        </div>
        <div> {rowData.buy_orderid}</div>
      </div>
    );
  };

  const sellColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          Price: <strong>{rowData.sell_price} </strong> | Lot:
          <strong>
            {" "}
            {rowData.sell_lotsize} ({rowData.sell_stock_quantity})
          </strong>
          | <strong>{rowData.sell_type}</strong>
        </div>
        <div className="mb-1">
          {formatDateDay(rowData.sell_datetime)}-
          {formatDateMonth(rowData.sell_datetime)}-
          {formatDateYear(rowData.sell_datetime)}{" "}
          {formatDateTime(rowData.sell_datetime)}{" "}
        </div>
        <div> {rowData.sell_orderid}</div>
      </div>
    );
  };
  return (
    <>
      <Header></Header>
      <SubHeader></SubHeader>

      <Toast ref={toast} />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li className="breadcrumb-item">
              <Link to="/teacher/my_report" className="text-black">
                My Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/my_report_Datewise" className="text-black">
                Datewise Report
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              My Report Details
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">My Report Details</h5>
            </div>
            <div className="col-12 col-md-3 col-lg-3 text-end mb-5">
              <Link to="/teacher/student_report">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-group-line me-1 ri-lg"></i>{" "}
                    <span>Student Report</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>

          <div className="row align-items-center mb-3">
  <div className="col-12 col-md-6">
    <div className="d-flex align-items-center">
      <IconField iconPosition="left">
        <InputIcon className="ri ri-search-line"></InputIcon>
        <InputText
          type="search"
          placeholder="Search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="rounded"
        />
      </IconField>

      {loading ? (
        <div className="d-flex align-items-center">
          <div
            className="spinner-border spinner-border-sm custom-spinner ms-3"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Tooltip target=".custom-target-icon" />
          <i
            className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
            role="button"
            data-pr-tooltip="Reload"
            onClick={handleRefresh}
            data-pr-position="top"
          ></i>
        </div>
      )}
    </div>
  </div>

  <div className="col-12 col-md-6 text-md-end text-end mt-3 mt-md-0">
    <strong className="fs-4">{month}</strong>
  </div>
</div>


          <DataTable
            className="custom-column-border"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="left"
              className="custom-column-border"
              header="Entry"
              body={buyColumnTemplate}
            ></Column>

            <Column
              align="left"
              className="custom-column-border"
              header="Exit"
              body={sellColumnTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl_total"
              header="P&L Total"
              body={PLTotalBodyTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
    </>
  );
};

export default MyReportView;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
