import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { saveAs } from "file-saver";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const PaymentHistory = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const userId = localStorage.getItem("userId");

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };

  const renderOrderStatus = (rowData) => {
    const status = rowData.payment_status
      ? rowData.payment_status.charAt(0).toUpperCase() +
        rowData.payment_status.slice(1).toLowerCase()
      : "Pending";
    const badgeClass =
      status === "Success"
        ? "badge rounded-pill bg-label-success"
        : status === "Failed"
        ? "badge rounded-pill bg-label-danger"
        : "badge rounded-pill bg-label-warning";

    return <span className={badgeClass}>{status}</span>;
  };

  const downloadInvoice = (invoiceUrl) => {
    saveAs(invoiceUrl, "invoice.pdf");
  };

  const renderInvoice = (rowData) => {
    if (rowData.payment_status === "Success" && rowData.invoiec_url) {
      return (
        <div
          onClick={() => {
            // Trigger the download directly
            saveAs(rowData.invoiec_url, "invoice.pdf");
          }}
          className="p-0"
          style={{ cursor: "pointer" }}
        >
          <i className="ri-download-line text-dark"></i>
          <span className="text-dark"> Download</span>
        </div>
      );
    }
    return null;
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/commonlogin");
          return;
        }
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/teacher_payment_history`,
          { teacher_id: userId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          const paymentHistory = Array.isArray(
            response.data.payment_history_list
          )
            ? response.data.payment_history_list
            : [];
          setData(paymentHistory);
        } else {
          throw new Error("Failed to fetch payment history.");
        }
      } catch (error) {
        console.clear(); 
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        } else {
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentHistory();
  }, [navigate]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/teacher_payment_history`,
        { teacher_id: userId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        const paymentHistory = Array.isArray(response.data.payment_history_list)
          ? response.data.payment_history_list
          : [];
        setData(paymentHistory);

        const successMsg = response.data.msg || "Success";
      } else if (response.data && response.data.st === 2) {
        const warningMsg = response.data.msg || "Warning";
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: warningMsg,
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch payment history";
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch payment history"
          : error.message || "Failed to fetch payment history";
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getButtonVariant()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Market Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The market is open!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">

          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Payment History
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0">Payment History</h5>
            </div>
            <div className="col-12 col-md-3  col-lg-3 text-end mb-5">
              <Link to="/teacher/billing">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-medal-2-fill me-1 ri-lg"></i>{" "}
                    <span>Subscription</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-start mb-3">
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <div className="d-flex align-items-center">
                <div
                  className="spinner-border spinner-border-sm custom-spinner ms-3"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>
          <DataTable
            className="text-center border border-1"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column
              align="center"
              className="text-center border border-1"
              field="payment_id"
              header="Payment Id"
            ></Column>
            <Column
              align="center"
              className="text-center border border-1"
              field="datetime"
              header="Date & Time"
            ></Column>
            <Column
              align="left"
              className=" border border-1 text-capitalize"
              field="error_description"
              header="Description"
            ></Column>
            <Column
              align="center"
              className="text-center border border-1 text-capitalize"
              field="error_reason"
              header="Reason"
            ></Column>
            <Column
              align="center"
              className="text-center border border-1"
              field="amount"
              header="Amount"
              body={(rowData) => `₹${rowData.amount}`}
            ></Column>
            <Column
              align="center"
              className="text-center border border-1"
              field="payment_status"
              header="Status"
              body={renderOrderStatus}
            ></Column>

            <Column
              align="center"
              className="text-center border border-1"
              field="invoiec_url"
              header="Invoice"
              body={renderInvoice}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default PaymentHistory;
