
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";

import config from "../../../component/config";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import StudentHeader from "./StudentHeader";
import SubHeaderS from "./SubHeaderS";
const LoginActivityLog = () => {
  const [loading, setLoading] = useState(false);
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const userId = localStorage.getItem("userId");

  const [brokerData, setBrokerData] = useState({});

  const [formData, setFormData] = useState(null);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");
         if (!authToken || !userId) {
        navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.msg,
            life: 3000,
          });
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();


      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  

  const handleConnectionStatus = (status) => {
  
  };



  useEffect(() => {
    const fetchActivityLog = async () => {
      const authToken = localStorage.getItem("authToken");

      try {
        const response = await axios.post(
          "https://ghanish.in/api/common/login_activity_log",
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Attach the token in the Authorization header
            },
          }
        );


        if (response.data.st === 1) {
          setActivityList(response.data.activity_list);
        } else {
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchActivityLog();
  }, []);
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    userData && userData.broker_account_exchanges
      ? formatExchanges(userData.broker_account_exchanges)
      : [];

  return (
    <div>
       <StudentHeader></StudentHeader>
       <SubHeaderS></SubHeaderS>
      <Toast ref={toast} />

     
      <div className="layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 text-black">
                  <li className="breadcrumb-item">
                    <Link to="/Student/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Profile
                  </li>
                </ol>
              </nav>

              {userData && (
                <div className="row ">
                  <div className="col-md-4">
                    <div className="card ">
                      <div className="card-body pt-0">
                        <div className="w-px-40 h-auto mx-auto mt-3 rounded-circle">
                          <div className="d-flex justify-content-center align-items-center border border-info border-3 rounded-circle bg-label-info avatar-xl">
                            <span className="avatar-initial rounded-circle bg-label-info">
                              {userData.name &&
                                userData.name
                                  .split(" ")
                                  .filter(
                                    (word, index) =>
                                      index === 0 ||
                                      index ===
                                      userData.name.split(" ").length - 1
                                  )
                                  .map((word) => word.charAt(0).toUpperCase())
                                  .join("")}
                            </span>
                          </div>
                        </div>
                        <div className="user-info  mt-3 text-center">
                          <h5>{capitalizeFirstLetter(userData.name)}</h5>
                          <span className="btn btn-outline-secondary rounded-pill btn-xs">
                            {" "}
                            {capitalizeFirstLetter(userData.role)}
                          </span>
                        </div>

                        <ul className="list-unstyled my-3 py-1">
  <li className="d-flex justify-content-between align-items-center mb-4">
    <strong>Broker Connection:</strong>
    <span className="ml-auto">
      <div className="ms-auto">
        <div
          className={`ml-auto ${
            handleConnectionStatus(userData.broker_conn_status)
              ? "text-danger"
              : "text-success"
          }`}
          onClick={() =>
            handleConnectionStatus(userData.broker_conn_status)
          }
        >
          {userData.broker_conn_status ? (
            <>
              <i className="ri-shield-check-line"></i> Connected
            </>
          ) : (
            <>
              <i className="ri-close-large-line"></i> Not Connected
            </>
          )}
        </div>
      </div>
    </span>
  </li>

  {formData.broker_conn_status && userData.broker_account_founds !== 0 && (
    <li className="d-flex justify-content-between align-items-center mb-4">
      <strong>Broker Acc. Funds:</strong>
      <span className="ml-auto">
        {(userData.broker_account_founds || 0).toFixed(2)} Rs.
      </span>
    </li>
  )}

  <li className="d-flex justify-content-between align-items-center mb-4">
    <strong>Commission:</strong>
    <span className="ml-auto">{userData.commission}%</span>
  </li>

  <li className="d-flex justify-content-between align-items-center mb-4">
    <strong>Lot Size Limit:</strong>
    <span className="ml-auto">{userData.lot_size_limit} Lot</span>
  </li>

  {formattedExchanges.length > 0 && (
    <li className="mb-4">
      <strong>Segments:</strong>
      <span className="ml-auto">
        <ul className="list-unstyled text-end"> {/* New <ul> for the exchanges */}
          {formattedExchanges.map((exchange, index) => (
            <li key={index}>{exchange}</li>
          ))}
        </ul>
      </span>
    </li>
  )}
</ul>
                        <hr />
                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex flex-column align-items-start mb-4">
                            <span className="fw-medium fs-5">Contacts</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Email:</strong>
                            <span className="ml-auto">{userData.email}</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Mobile:</strong>
                            <span className="ml-auto">{userData.mobile}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row mt-4 mt-md-0 mt-lg-0">
                    <div className="col-md-8 col-sm-7">
  <div className="nav-align-top">
    <ul className="nav nav-pills flex-row justify-content-center flex-wrap mb-3 row-gap-1">
      <li className="nav-item active">
                              <Link
                                to="/app2/student_profile"
                                className="nav-link  "
                              >
                                <i className="ri-user-3-line me-1_5"></i>Profile
                              </Link>
                            </li>
                            <li className="nav-item  active">
                              <Link
                                to="/student/login_activity_log"
                                className="nav-link active btn btn-primary "
                              >
                                <i className="ri-user-3-line me-1_5"></i>Login Log
                              </Link>
                            </li>
                            <li className="nav-item  active">
                              <Link
                                to="/student/activity_log"
                                className="nav-link  "
                              >
                                <i className="ri-user-3-line me-1_5"></i>Activity Log
                              </Link>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
      <div className="card mb-12 mt-1">
        <h5 className="card-header text-center">Recent Devices</h5>

          <DataTable value={activityList} scrollable
          scrollHeight="450px" 
          virtualScrollerOptions={{ itemSize: 50 }} >
            <Column field="browser" header="Browser" 
            body={(rowData) => (
              <div className="d-flex align-items-center">
                {rowData.browser === "Edge" && (
                  <i className="ri-edge-line me-2"></i>
                )}
                {rowData.browser === "Chrome" && (
                  <i className="ri-chrome-line me-2"></i>
                )}
                {rowData.browser === "Firefox" && (
                  <i className="ri-firefox-line me-2"></i>
                )}
                {rowData.browser === "Opera" && (
                  <i className="ri-opera-line me-2"></i>
                )}
                {![
                  "Edge",
                  "Chrome",
                  "Firefox",
                  "Opera",
                ].includes(rowData.browser) && (
                  <i className="ri-earth-line me-2"></i>
                )}
                <span className="text-heading">
                  {`${rowData.browser} on ${rowData.browser_version}`}
                </span>
              </div>
            )}
            />
            <Column field="ip_address" header="IP Address" />
            <Column field="details" header="Details" />
            <Column field="browser_version" header="Version"
             body={(rowData) => (
              <div className="d-flex align-items-center">
                {rowData.os === "Windows" && (
                  <i className="ri-windows-line me-2"></i>
                )}
                {rowData.os === "Linux" && (
                  <i className="ri-qq-line me-2"></i>
                )}
                {rowData.os === "iOS" && (
                  <i className="ri-apple-line me-2"></i>
                )}
                {!["Windows", "Linux", "iOS"].includes(
                  rowData.os
                ) && <i className="ri-computer-line me-2"></i>}

                <span className="text-heading">
                  {rowData.os}
                </span>
              </div>
            )}
            />
            <Column field="datetime" header="Recent Activities" />
          </DataTable>
      
      </div>
    </div>

                  </div>
                  <CardLinks></CardLinks>
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginActivityLog;
