import React, { useEffect, useState ,useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
const TeacherReportDatewise = () => {
  const { teacherId, monthName } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  // Fetch teacher_id and sell_month from URL params
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backClicked, setBackClicked] = useState(false);
  const [error, setError] = useState(null);
 
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
     if (!authToken || !userId) {
    navigate("/commonlogin");
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/datewise_report`,
        {
          teacher_id: teacherId,
          month_name: monthName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

      if (response.data) {
        setData(response.data.completed_trades_per_date);
       
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/commonlogin"); // Redirect to common login screen on 401 error
      } else {
        console.error("Unauthorized access, redirecting to common login screen");
      
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [teacherId, monthName]);

 

 

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    const authToken = localStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/datewise_report`,
        {
          teacher_id: teacherId,
          month_name: monthName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

     
       
        if (response.data && response.data.st === 1) {
          setData(response.data.completed_trades_per_date);
    
         
        } else if (response.data && response.data.st === 2) {
          const errorMsg = response.data.msg || "Warning";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
          const errorMsg = response.data.msg || "Danger: Server Error";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        } else {
          const errorMsg = response.data.msg || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        }
      }catch (error) {
        console.error('Error fetching data:', error);
        // Check for 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized access, redirecting to common login screen');
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        } else {
          const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
          setError(new Error(errorMsg));
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };
 
  
  return (
    <>
      <AdminHeader />
      <AdminSubHeader />
      <Toast ref={toast} />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_list" className="text-black">
                Teacher Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_report" className="text-black">
              Monthwise  Teacher Report 
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
             Datewise Teacher Report
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-9 text-center mb-5">
              <h5 className="mb-0"> Datewise Teacher Report </h5>
            </div>
          </div>


          

          <div className="d-flex justify-content-start mb-3">
           
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
               <div className="d-flex align-items-center">
               <div
                 className="spinner-border spinner-border-sm custom-spinner ms-3"
                 role="status"
                 
               >
                 <span className="visually-hidden">Loading...</span>
               </div>
             </div>
           ) : (
             <div className="mt-3">
               <Tooltip target=".custom-target-icon" />
               <i
                 className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary" role="button"
                 data-pr-tooltip="Reload"
                 onClick={handleRefresh}
                 data-pr-position="top"
                
               ></i>
             </div>
              )}
          </div>
          <DataTable
            className="custom-column-border"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
              align="center"
          >
            <Column
    align="center"
      className="custom-column-border"
    header="Exit Date"
    field="sell_date"
  ></Column>

  <Column
    align="center"
     className="custom-column-border"
    header="Trades Count"
   field="trades_count"
  ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="profitable_trades"
              header="Profitable Trade"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"              
              field="losing_trades"
              header="Losing Trades"
            
            ></Column>
           
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column>
                <Column
              align="center"
              className="custom-column-border"
              header="Actions"
              body={(rowData) => (
                <Link
                  to={`/admin/teacher_report_details/${teacherId}/${rowData.sell_date}`}
                >
                  <button className="btn btn-info custom-btn-action1 custom-target-icon"  data-pr-tooltip="View Record"
                    data-pr-position="top">
                    <i className="ri-gallery-view-2"></i>
                  </button>
                </Link>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default TeacherReportDatewise;
