import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import CardLinks from "../../../component/CardLinks";
import { Modal } from "react-bootstrap";
const ManageFaq = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [deleteLoading, setDeleteLoading] = useState(false); // Deletion loading state
  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");
      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }
      setLoading(true);

      try {
        const response = await axios.get(
          "https://ghanish.in/api/admin/list_view_faq",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFaqs(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch FAQs",
        });
        console.error("There was an error fetching the FAQs!", error);
      }
    };

    fetchFaqs();
  }, []);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleView = (faqId) => {
    navigate(`/admin/manage_faq/view_faq/${faqId}`);
  };
  const handleEdit = (faqId) => {
    console.log("Editing FAQ with ID:", faqId); // Debugging statement
    if (faqId) {
      navigate(`/admin/manage_faq/update_faq/${faqId}`);
    } else {
      console.error("FAQ ID is undefined");
    }
  };

  const openDeleteModal = (faqId) => {
    setSelectedFaqId(faqId); // Set the FAQ ID to be deleted
    setShowModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowModal(false); // Close the modal
    setSelectedFaqId(null); // Clear selected FAQ ID
  };

  const confirmDelete = async () => {
    setDeleteLoading(true);
    const authToken = localStorage.getItem("authToken");
  
    if (!authToken) {
      setDeleteLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Authorization token missing",
      });
      return;
    }
  
    try {
      // Send DELETE request
      await axios.delete("https://ghanish.in/api/admin/delete_faq", {
        data: { faq_id: selectedFaqId },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      
      setFaqs((prevFaqs) => prevFaqs.filter((faq) => faq.faq_id !== selectedFaqId));
  
     
  
      
      setShowModal(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete FAQ",
      });
    } finally {
      setDeleteLoading(false);
      setSelectedFaqId(null); // Clear the selected FAQ ID
    }
  };
  


  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const categoryBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.category)}</span>;
  };
  const questionBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.question)}</span>;
  };
  const handleRefresh = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      setError(new Error("Auth token not found"));
      setLoading(false);
      return;
    }
    setLoading(true);

    try {
      const response = await axios.get(
        "https://ghanish.in/api/admin/list_view_faq",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFaqs(response.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch FAQs",
      });
      console.error("There was an error fetching the FAQs!", error);
    }
  };

  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
               FAQ
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center">
              <h5 className="mb-0"> FAQ</h5>
            </div>
            <div className="col-2"></div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">

  <div className="d-flex align-items-center flex-grow-1 w-25 w-md-100 w-lg-100">
  <IconField iconPosition="left">
  <InputIcon className="ri ri-search-line"></InputIcon>
  <InputText
    type="search"
    placeholder="Search"
    value={globalFilter}
    onChange={(e) => setGlobalFilter(e.target.value)}
    className="rounded ps-6 pe-0 search-input form-control form-control-md form-control-sm input-sm"
  />
</IconField>


    {loading ? (
      <div className="d-flex align-items-center ms-3">
        <div className="spinner-border spinner-border-sm custom-spinner" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    ) : (
      <div className="ms-3">
        <Tooltip target=".custom-target-icon" />
        <i
          className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary refresh-icon"
          role="button"
          data-pr-tooltip="Reload"
          onClick={handleRefresh}
          data-pr-position="top"
        ></i>
      </div>
    )}
  </div>

  {/* Create Button */}
  <div className="ms-auto w-25 mt-0 mt-md-0">
    <Link to="/admin/manage_faq/create_faq">
      <button className="btn btn-success create-btn btn-sm ">
        <i className="ri-add-circle-line ri-lg me-1"></i>
        <span>Create</span>
      </button>
    </Link>
  </div>
</div>



          <DataTable
            value={faqs}
            rows={20}
            paginator
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="p-datatable-rounded border border-1 text-center  "
          >
            <Column
              field="category"
              body={categoryBodyTemplate}
              header="Category"
              className=" border border-1"
            ></Column>

            <Column
              field="question"
              style={{
                wordBreak: "break-word",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "400px",
              }}
              align={"left"}
              className=" border border-1"
              body={questionBodyTemplate}
              header="Question "
            ></Column>
  <Column
  align={"center"}
  className="custom-column-border w-25" // Increased column width to 50% to give more space
  header="Actions"
  body={(rowData) => (
    <div className="d-flex flex-nowrap justify-content-center align-items-center">
      <button
        className="btn btn-info me-2 custom-btn-action1 custom-target-icon"
        data-pr-tooltip="View Record"
        data-pr-position="top"
        onClick={() => handleView(rowData.faq_id)}
      >
        <i className="ri-gallery-view-2 ri-lg"></i>
      </button>
      <button
        className="btn btn-warning me-2 custom-btn-action1 custom-target-icon"
        data-pr-tooltip="Update Record"
        data-pr-position="top"
        onClick={() => handleEdit(rowData.faq_id)}
      >
        <i className="ri-edit-2-line ri-lg"></i>
      </button>
      <button
        className="btn btn-danger custom-btn-action1 custom-target-icon"
        data-pr-tooltip="Delete Record"
        data-pr-position="top"
        onClick={() => openDeleteModal(rowData.faq_id)}
      >
        <i className="ri-delete-bin-line ri-lg"></i>
      </button>
    </div>
  )}
/>


          </DataTable>
        </div>
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure?
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-between w-100">
              <button
                onClick={closeModal}
                className="btn btn-outline-secondary rounded-pill btn-sm"
              >
                <i className="ri-close-large-line me-2"></i>
                <span>Cancel</span>
              </button>
              <button
                onClick={confirmDelete}
                disabled={deleteLoading}
                className="btn btn-danger rounded-pill btn-sm"
              >
                <i className="ri-delete-bin-line me-2"></i>
                {deleteLoading ? "Deleting" : "Delete"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
};

export default ManageFaq;
