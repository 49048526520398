import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import autoprofito_logo from  "../../../assets/img/products/company/autoprofito_logo.png"
import MetaTags from "./MetaTags";


const LandingHeader = () => {

  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/");

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <MetaTags />
     
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top d-lg-none">
        <div className="container-fluid d-flex justify-content-between align-items-center px-3">
        
          <button
            className="navbar-toggler border-0"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
            onClick={handleMenuToggle}
          >
            <i className="ri-menu-fill ri-30px align-middle fs-4"></i>
          </button>
          
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img
              src={autoprofito_logo}
              alt="Favicon"
              width="44"
            />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
         
          <div  className="d-flex align-items-center">
          
          </div>
        </div>
        {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}


  <div className={`sidebar ${isMenuOpen ? "active" : ""}`} id="navbarSupportedContent">
        
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/" className="navbar-brand d-flex align-items-center px-5" onClick={toggleMenu}>
            <img
              src={autoprofito_logo}
              alt="Favicon"
              width="44"
            />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
          <span className="close-icon me-3" onClick={toggleMenu}>&times;</span>
        </div>
  <hr className="my"></hr>
  <ul className="sidebar-nav">
    <li className="nav-item">
      <Link
        className={`nav-link ${activeLink === "/" ? "text-primary" : ""} px-4`}
        to="/"
        onClick={() => {
          setActiveLink("/");
          toggleMenu();
        }}
      >
        Home
      </Link>
    </li>
    <li className="nav-item">
      <Link
        className={`nav-link ${activeLink === "/pricing" ? "text-primary" : ""} px-4`}
        to="/pricing"
        onClick={() => {
          setActiveLink("/pricing");
          toggleMenu();
        }}
      >
        Pricing
      </Link>
    </li>
    <li className="nav-item">
      <Link
        className={`nav-link ${activeLink === "/contact" ? "text-primary" : ""} px-4`}
        to="/contact"
        onClick={() => {
          setActiveLink("/contact");
          toggleMenu();
        }}
      >
        Contact
      </Link>
    </li>
    <li className="nav-item">
      <Link
        className={`nav-link ${activeLink === "/about" ? "text-primary" : ""} px-4`}
        to="/about"
        onClick={() => {
          setActiveLink("/about");
          toggleMenu();
        }}
      >
        About
      </Link>
    </li>
    <li className="nav-item">
     
    </li>
    <div className="text-center mt-12">
  <div className="flex justify-center space-x-4">
    <Link
      className="btn btn-info waves-effect waves-light rounded-pill me-3"
      to="/commonlogin"
    >
      Login
    </Link>
    <Link
      className="btn btn-outline-secondary rounded-pill"
      to="/signup"
    >
      Signup
    </Link>
  </div>
</div>

  
  </ul>

  
  
</div>
      </nav>

      {/* Desktop Navbar */}
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top d-none d-lg-block">
  <div className="container-xxl d-flex justify-content-between align-items-center">
  
    <Link to="/" className="navbar-brand d-flex align-items-center">
      <img
        src={autoprofito_logo}
        alt="Favicon"
        width="44"
      />
      <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
    </Link>

    
    <div
      className={`collapse navbar-collapse justify-content-center ${isMenuOpen ? "show" : ""}`}
      id="navbarSupportedContent"
    >
      <ul className="navbar-nav text-center mb-2 mb-lg-0">
        <li className="nav-item">
          <Link
            className={`nav-link fs-5 ${
              activeLink === "/" ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4" : "text-black"
            } px-4`}
            to="/"
            onClick={() => setActiveLink("/")}
          >
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link fs-5 ${
              activeLink === "/pricing" ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4" : "text-black"
            } px-4`}
            to="/pricing"
            onClick={() => setActiveLink("/pricing")}
          >
            Pricing
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link fs-5 ${
              activeLink === "/contact" ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4" : "text-black"
            } px-4`}
            to="/contact"
            onClick={() => setActiveLink("/contact")}
          >
            Contact
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link fs-5 ${
              activeLink === "/about" ? "text-primary fw-bold border-bottom border-primary  pb-2 border-4" : "text-black"
            } px-4`}
            to="/about"
            onClick={() => setActiveLink("/about")}
          >
          About  
          </Link>
        </li>
      </ul>
    </div>

    {/* Right: Signup and Login */}
    <div className="navbar-nav ms-auto d-flex align-items-center">
      <Link
        to="/signup"
        className="d-flex justify-content-center align-items-center rounded-pill me-2 text-dark pe-3"
      >
        <div className="text-muted">
          Signup
        </div>
      </Link>
      <Link
        to="/commonlogin"
        className="btn btn-info rounded-pill btn-outline-secondary waves-effect"
      >
        <i className="ri-user-line me-1" />
        Login
      </Link>
    </div>
  </div>
</nav>

    </>
  );
};

export default LandingHeader;
