
import config from "../../../component/config";

import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const ViewStudent = () => {
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [students, setStudents] = useState([]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/manage_students/view`,
          {
            student_id: id,
            teacher_id: userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            },
          }
        );

        if (response.data && response.data.st === 1) {
          setTeacherData(response.data.data);
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
      } catch (error) {
       
        console.clear(); 
        if (error.response && error.response.status === 401) {
          
          navigate("/commonlogin"); 
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleToggleTrade = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("Auth token not found");

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/student_trade_switch`,
        { student_id: id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.st === 1) {
        setTeacherData((prevData) => ({
          ...prevData,
          trade_status: !prevData.trade_status,
        }));
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg || "Trade status updated successfully",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg || "Error switching trade status",
          life: 3000,
        });
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.msg || "Error switching trade status",
          life: 3000,
        });
      }
    }
  };

  const handleToggle = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("Auth token not found");

      const response = await fetch(
        `${config.apiDomain}/api/teacher/student_active_switch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ student_id: id }),
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.st === 1) {
        setTeacherData((prevData) => ({
          ...prevData,
          is_active: !prevData.is_active,
        }));
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Student status updated successfully",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: responseData.msg || "Failed to update student status",
          life: 3000,
        });
      }
    } catch (error) {
      console.clear(); 
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Network error",
        life: 3000,
      });
    }
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem("userId");

      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/manage_students/view`,
        {
          student_id: id,
          teacher_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setTeacherData(response.data.data);
      } else {
        setError(new Error(response.data.msg || "Failed to fetch data"));
      }
    } catch (error) {
      console.clear(); 
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const maskString = (str) => {
    if (!str || str.length < 5) return str; 
    const firstPart = str.slice(0, 2).replace(/./g, '*'); 
    const middlePart = str.slice(2, -3); 
    const lastPart = str.slice(-3).replace(/./g, '*'); 
    return `${firstPart}${middlePart}${lastPart}`;
  };
  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/manage_student" className="text-black">
                 Student
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              View Student
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            {/* Back Button */}
            <div className="col-3 d-flex align-items-center justify-content-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
          
            <div className="col-6 text-center">
              <h5 className="mb-0">View Student</h5>
            </div>

            <div className="col-12 col-md-3 mt-6 mt-md-0 col-lg-3 d-flex justify-content-end flex-wrap">
              <div className="d-flex flex-row align-items-center">
                {teacherData && teacherData.broker_status && (
                  <div className="d-flex flex-column align-items-center mb-2 me-8">
                  
                    <label
                      className={`switch switch-square ${
                        teacherData.trade_status ? "bg-success" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={teacherData.trade_status}
                        onChange={() =>
                          handleToggleTrade(teacherData.teacher_id)
                        }
                      />
                      <span
                        className={`switch-toggle-slider ${
                          teacherData.trade_status ? "bg-success" : ""
                        }`}
                      >
                        <span className="switch-on"></span>
                        <span className="switch-off"></span>
                      </span>
                    </label>
                    
                    <div className="ms-2 mt-2 d-flex align-items-center">
                      <i className="ri-arrow-left-right-fill ri-ms me-1"></i>
                      <span className="text-black">Trade Status</span>
                    </div>
                  </div>
                )}

                {teacherData && (
                  <div className="d-flex flex-column align-items-center">
                    <label
                      className={`switch switch-square ${
                        teacherData.is_active ? "bg-success" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={teacherData.is_active}
                        onChange={() => handleToggle(teacherData.teacher_id)}
                      />
                      <span
                        className={`switch-toggle-slider ${
                          teacherData.is_active ? "bg-success" : ""
                        }`}
                      >
                        <span className="switch-on"></span>
                        <span className="switch-off"></span>
                      </span>
                    </label>
                    <div className="ms-2 mt-2">
                      <i className="ri-user-3-line ri-ms me-1"></i>
                      <span className="text-black">Account Status</span>
                    </div>
                  </div>
                )}
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm custom-spinner ms-3 mt-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="mt-1">
                    <Tooltip target=".custom-target-icon" />
                    <i
                      className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                      role="button"
                      data-pr-tooltip="Reload"
                      onClick={handleRefresh}
                      data-pr-position="top"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>

          {teacherData && (
            <div className="row">
              <div className="col-12">
                <div className="row mt-1">
                  <h4>
                    {" "}
                    <i className="ri-user-line ri-ms me-1 "></i>
                    Personal Information
                  </h4>
                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{toTitleCase(teacherData.name)}</strong>
                    </span>
                    <div>Name</div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{teacherData.mobile}</strong>
                    </span>
                    <div>Mobile</div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0">
                    <span className="text-black">
                      <strong>{teacherData.email}</strong>
                    </span>
                    <div>Email</div>
                  </div>
                  <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0">
                    <div
                      className={` ${
                        teacherData.broker_status
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {teacherData.broker_status ? (
                        <div className="d-flex justify-content-space align-items-center">
                          <i className="ri-shield-check-line me-1"></i>
                          <strong>Connected</strong>
                          {teacherData.broker_status &&
                            teacherData.broker_account_fund != null && (
                              <div className="text-muted ms-2">
                                {teacherData.broker_account_fund} Rs
                              </div>
                            )}
                        </div>
                      ) : (
                        <>
                          <i className="ri-close-large-line me-1"></i>
                          <strong>Not Connected</strong>
                        </>
                      )}
                    </div>

                    <div>Broker Conn. Status</div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 mb-5">
                <div className="row mt-1">
                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{teacherData.lot_size_limit} Lot</strong>
                    </span>
                    <div>Lot Size Limit</div>
                  </div>

                  <div className="col-6 col-md-3 col-lg-3">
                    <span className="text-black">
                      <strong>{teacherData.commission}%</strong>
                    </span>
                    <div>Commission</div>
                  </div>
                </div>
              </div>

              <hr />
              {teacherData.broker_status && (
                <>
                  <h4>
                    <i className="ri-group-line ri-ms me-2"></i>
                    Broker Information
                  </h4>
                  {teacherData.broker === "angle_one" && teacherData.broker_details && (
                  <div className="col-12 mt-5 mb-5">
                    {teacherData.broker_details && (
                      <div className="row mt-1">
                        <div className="col-12 col-md-3 col-lg-3">
                          <span className="text-black">
                            <strong>
                            {maskString(teacherData.broker_details.api_key)}
                            </strong>
                          </span>
                          <div>Broker API Key</div>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0">
                          <span className="text-black">
                            <strong>
                              {teacherData.broker_details.client_id}
                            </strong>
                          </span>
                          <div>Broker Client ID</div>
                        </div>

                       

                        <div className="col-12 col-md-3 col-lg-3 mt-5 mt-md-0 ">
                          <span className="text-black">
                            <strong>
                            <strong>{maskString(teacherData.broker_details.qr_totp_token)}</strong>
                            </strong>
                          </span>
                          <div>Broker QR TOTP Token</div>
                        </div>
                      </div>
                    )}
                  </div>
                  )}
                  {teacherData.broker === "dhan" && teacherData.broker_details && (
      <div className="col-12 mb-5">
        <div className="row mt-1">
          <div className="col-12 col-md-3 col-lg-3">
            <span className="text-black">
              <strong>{teacherData.broker_details.dhan_client_id}</strong>
            </span>
            <div>Dhan Client ID</div>
          </div>

          <div className="col-12 col-md-9 col-lg-9 mt-5 mt-mb-0">
  <span className="text-black">
    <strong>{maskString(teacherData.broker_details.access_token)}</strong>
  </span>
  <div>Dhan Access Token</div>
</div>
        </div>
      </div>
    )}
                  <hr></hr>
                </>
              )}
            </div>
          )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
    </>
  );
};

export default ViewStudent;
