import React ,{useEffect} from 'react'
import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter'
import MetaTags from './MetaTags';

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags />
      <LandingHeader></LandingHeader>
      <section
        id="landingPricing"
        className="container-p-y bg-body landing-pricing pt-10 mt-10"
      >
        
        <div className="container-xxl bg-icon-left position-relative ">
          <h2 className="h3 text-center mt-3 ">Terms and Conditions</h2>
          <p>
            Our Privacy Policy forms part of, dan must be read in conjunction
            with, website Terms and Conditions. We reserve the right to change
            this Privacy Policy at any time. We respect the privacy of our users
            and every person who visits our site quantbotsecurities.com. Here,
            (“we”, “us”, or “our”). We are committed to protecting your personal
            information and your right to privacy in accordance with the General
            Data Protection Regulation (GDPR). If you have any questions or
            concerns about our policy or our practices with regards to your
            personal information, please contact us at
            admin@quantbotsecurities.com. When you visit our website
            quantbotsecurities.com (“Site”), and use our services, you trust us
            with your personal information. We take your privacy very seriously.
            In this privacy notice, we describe our privacy policy. We seek to
            explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are any terms in this privacy policy that you do
            not agree with, please discontinue the use of our site and our
            services. This privacy policy applies to all information collected
            through our website, and/or any related services, sales, marketing
            or events (we refer to them collectively in this privacy policy as
            the “Site“).
          </p>

          <h5 className="h5 mt-3">WHAT INFORMATION DO WE COLLECT?</h5>
          <p>
            The Individual Data you uncover to us We gather individual data that
            you intentionally give to us while communicating an interest in
            getting data about us or our items and administrations, while taking
            part in exercises on the Site or in any case reaching us. The
            individual data that we gather relies upon the setting of your
            connections with us and the Site, the decisions you make and the
            items and elements you use. The individual data we gather can
            incorporate the accompanying: Name and Contact Information. We
            gather your first and last name, email address, postal location,
            telephone number and other comparable contact information. Data
            consequently gathered We naturally gather specific data when you
            visit, utilize or explore the Site. This data doesn't uncover your
            particular personality (like your name or contact data) yet may
            incorporate gadget and utilization data, for example, your IP
            address, program and gadget attributes, working framework, language
            inclinations, alluding URLs, gadget name, country, area, data
            concerning how and when you utilize our Site and other specialized
            data. If you access our site with your cell phone, we may
            consequently gather gadget data (like your cell phone ID, model and
            producer), working framework, adaptation data and IP address. This
            data is basically expected to keep up with the security and activity
            of our Site, and for our inside investigation and detailing
            purposes. In the same way as other organizations, we additionally
            gather data through treats and comparative advancements. You can
            discover more with regards to this in our Treat Strategy. Data
            gathered from different Sources We might get data about you from
            different sources, like public data sets, joint promoting
            accomplices, web-based media stages (like Facebook), just as from
            other outsiders. If you have decided to prefer our pamphlet, your
            first name, last name and email address will be imparted to our
            bulletin supplier. This is to save you refreshed with data and
            offers for showcasing purposes.
          </p>

          <h5 className="h5 mt-3">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h5>

          <p>
            We just share and uncover your data in the accompanying
            circumstances: Consistence with Laws. We might reveal your data
            where we are lawfully needed to do as such to conform to pertinent
            law, legislative solicitations, a legal procedure, court request, or
            legitimate cycle, for example, in light of a court request or a
            summon (remembering for reaction to public specialists to meet
            public safety or law implementation necessities). Crucial Interests
            and Lawful Freedoms. We might reveal your data where we accept it is
            important to examine, forestall, or make a move in regards to likely
            infringement of our arrangements, suspected misrepresentation,
            circumstances including expected dangers to the wellbeing of any
            individual and criminal operations, or as proof in prosecution in
            which we are involved. Sellers, Specialists and Other Outsider
            Specialist organizations. We might impart your information to
            outsider merchants, specialist organizations, project workers or
            specialists who perform administrations for us or for our benefit
            and expect admittance to such data to accomplish that work. Business
            Moves. We might share or move your data regarding, or during
            dealings of, any consolidation, offer of organization resources,
            financing, or obtaining of all or a piece of our business to another
            organization. Outsider Sponsors. We might utilize outsider promoting
            organizations to serve advertisements when you visit the Site. These
            organizations might utilize data about your visits to our Webpage
            and different sites that are contained in web treats and other
            following advancements to give commercials about labor and products
            important to you. See our Treat Strategy for additional data.
            Associates. We might impart your data to our associates, where case
            we will require those subsidiaries to respect this protection
            strategy. Associates incorporate our parent organization and any
            auxiliaries, joint endeavor accomplices or different organizations
            that we control or that are under normal control with us.
            Colleagues. We might impart your data to our colleagues to offer you
            certain items, administrations or advancements. With your Assent. We
            might unveil your own data for some other reason with your assent.
            Different Clients. At the point when you share individual data (for
            instance, by posting remarks, commitments or other substance to the
            Site) or in any case connect with public spaces of the Site, such
            close to home data might be seen by all clients and might be openly
            disseminated outside the Site in unendingness.
          </p>

          <h5 className="h5 mt-3">HOW DO WE USE YOUR INFORMATION?</h5>

          <p>
            The Individual Data you uncover to us We gather individual data that
            you intentionally give to us while communicating an interest in
            getting data about us or our items and administrations, while taking
            part in exercises on the Site or in any case reaching us. The
            individual data that we gather relies upon the setting of your
            connections with us and the Site, the decisions you make and the
            items and elements you use. The individual data we gather can
            incorporate the accompanying: Name and Contact Information. We
            gather your first and last name, email address, postal location,
            telephone number and other comparable contact information. Data
            consequently gathered We naturally gather specific data when you
            visit, utilize or explore the Site. This data doesn't uncover your
            particular personality (like your name or contact data) yet may
            incorporate gadget and utilization data, for example, your IP
            address, program and gadget attributes, working framework, language
            inclinations, alluding URLs, gadget name, country, area, data
            concerning how and when you utilize our Site and other specialized
            data. If you access our site with your cell phone, we may
            consequently gather gadget data (like your cell phone ID, model and
            producer), working framework, adaptation data and IP address. This
            data is basically expected to keep up with the security and activity
            of our Site, and for our inside investigation and detailing
            purposes. In the same way as other organizations, we additionally
            gather data through treats and comparative advancements. You can
            discover more with regards to this in our Treat Strategy. Data
            gathered from different Sources We might get data about you from
            different sources, like public data sets, joint promoting
            accomplices, web-based media stages (like Facebook), just as from
            other outsiders. If you have decided to prefer our pamphlet, your
            first name, last name and email address will be imparted to our
            bulletin supplier. This is to save you refreshed with data and
            offers for showcasing purposes.
          </p>

          <h5 className="h5 mt-3"> WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h5>
          <p>
            The Site may contain advertisements from third parties that are not
            affiliated with us and which may link to other websites, online
            services or mobile applications. We cannot guarantee the safety and
            privacy of data you provide to any third parties. Any data collected
            by third parties is not covered by this privacy policy. We are not
            responsible for the content or privacy and security practices and
            policies of any third parties, including other websites, services or
            applications that may be linked to or from the Site. You should
            review the policies of such third parties and contact them directly
            to respond to your questions.
          </p>

          <h5 className="h5 mt-3">HOW DO WE KEEP YOUR INFORMATION SAFE?</h5>
          <p>
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, please also remember that we cannot
            guarantee that the internet itself is 100% secure. Although we will
            do our best to protect your personal information, transmission of
            personal information to and from our Site is at your own risk. You
            should only access the services within a secure environment.
          </p>

          <h5 className="h5 mt-3"> DO WE COLLECT INFORMATION FROM MINORS?</h5>
          <p>
            We do not knowingly solicit data from or market to children under 16
            years of age. By using the Site, you represent that you are at least
            16 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Site. If we learn that
            personal information from users less than 16 years of age has been
            collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we have collected from children under age
            16, please contact us at admin@quantbotsecurities.com.
          </p>

         
          
        </div>
      </section>
      <LandingFooter></LandingFooter>
    </div>
  );
}

export default TermsCondition
