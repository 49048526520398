import React, { useEffect, useState, lazy, Suspense } from "react";
import axios from "axios";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import config from "../../../component/config";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";

const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [studentCount, setStudentCount] = useState({
    total_student_count: 0,
    active_student_count: 0,
    inactive_student_count: 0,
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTeacherHomeData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/teacher_home`,
          {
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setStudentCount(response.data.student_count);
        } else {
          console.error(
            "Failed to fetch teacher home data:",
            response.data.msg
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
         
          navigate("/commonlogin");
        } else {
        }
        console.clear(); 
      }
    };

    fetchTeacherHomeData();
  }, []);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  return (
    <div>
      <Header />
      <SubHeader />
     
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
  <div className="layout-container">
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row g-6">
        {studentCount.total_student_count > 0 && (
          <div className="col-6 col-sm-6 col-lg-2">
            <div className="card card-border-shadow-primary h-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2">
                  <div className="avatar me-4">
                    <span className="avatar-initial rounded bg-label-primary">
                      <i className="ri-group-3-line ri-24px"></i>
                    </span>
                  </div>
                  <h4 className="mb-0 fs-3">
                    {studentCount.total_student_count}
                  </h4>
                </div>
                <h6 className="mb-0 fw-normal"> Total Students</h6>
              </div>
            </div>
          </div>
        )}
        {studentCount.active_student_count > 0 && (
          <div className="col-6 col-sm-6 col-lg-2">
            <div className="card card-border-shadow-primary h-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2">
                  <div className="avatar me-4">
                    <span className="avatar-initial rounded bg-label-primary">
                      <i className="ri-group-3-line ri-24px"></i>
                    </span>
                  </div>
                  <h4 className="mb-0 fs-3">
                    {studentCount.active_student_count}
                  </h4>
                </div>
                <h6 className="mb-0 fw-normal">Total Active Students</h6>
              </div>
            </div>
          </div>
        )}
        {studentCount.inactive_student_count > 0 && (
          <div className="col-6 col-sm-6 col-lg-2">
            <div className="card card-border-shadow-primary h-100">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2">
                  <div className="avatar me-4">
                    <span className="avatar-initial rounded bg-label-primary">
                      <i className="ri-group-3-line ri-24px"></i>
                    </span>
                  </div>
                  <h4 className="mb-0 fs-3">
                    {studentCount.inactive_student_count}
                  </h4>
                </div>
                <h6 className="mb-0 fw-normal">
                  Total Inactive Students
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
</div>


   
        <Footer />
  
    </div>
  );
};

export default Home;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
