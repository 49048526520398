import React from "react";

const Footer = () => {
  return (
    <div className="mt-12">
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl">
          <div className="text-body d-flex justify-content-between align-items-center flex-column flex-md-row">
           
            <div className="mb-2 mb-md-0 text-center text-md-start">
              &copy; Copyright. All Rights Reserved.
            </div>

           
            <div className="d-flex justify-content-center justify-content-md-end flex-wrap">
              <a
                href="https://www.facebook.com/share/ra9cKRDkDpy2W94j/?mibextid=qi2Omg"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-facebook-circle-fill ri-xl"></i>
              </a>
              <a
                href="https://www.instagram.com/autoprofito/?next=%2F"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-instagram-line ri-xl"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-youtube-line ri-xl"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/104616702/admin/dashboard/"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-linkedin-fill ri-xl"></i>
              </a>
              <a
                href="https://www.threads.net/@autoprofito"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-threads-line ri-xl"></i>
              </a>
              <a
                href="https://www.threads.net/@autoprofito"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-twitter-x-line ri-xl"></i>
              </a>
              <a
                href="https://t.me/Autoprofito"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-telegram-line ri-xl"></i>
              </a>
              <a
                href="https://pin.it/4TpIUsHzB"
                className="footer-link me-2"
                target="_blank"
              >
                <i className="ri-pinterest-line ri-xl"></i>
              </a>
            </div>

           
            <div className="text-center text-md-end mt-3 mt-md-0">
              Powered by{" "}
              <a href="https://www.shekruweb.com/" target="_blank" rel="noopener noreferrer">
                Shekru Labs India Pvt. Ltd.
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
