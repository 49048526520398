



import React, { useEffect, useState } from "react";
import axios from "axios";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";

import MetaTags from "./MetaTags";

function Pricing() {
  const [plans, setPlans] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(
          "https://ghanish.in/api/website/view_all_subscription_plans",
         
        );
        if (response.data.st === 1) {
          setPlans(response.data.all_subscription_plans_list);
        }
      } catch (error) {
        // Silently handle the error without logging anything
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const gifs = [
    require('../../../assets/gif/meteor-rain.gif'), // First GIF
    require('../../../assets/gif/rocket.gif'),       // Second GIF
    require('../../../assets/gif/diagram.gif') 
  ];
  return (
    <>
      <MetaTags />
      <LandingHeader />
      <div className="container-xxl mt-10 pt-10 mb-5">
        <div className="text-center mb-5 mt-10">
          <h3 className="fw-bold">Pricing Plans</h3>
          <p className="mb-1">
            All plans include 40+ advanced tools and features to boost your
            products.
          </p>
          <p className="mt-1 mb-10">Choose the best plan to fit your needs.</p>
        </div>
      </div>
      <div className="container-xxl mb-6">
  <div className="row justify-content-center">
    {plans.map((plan, index) => {
      const isPopular = index === plans.length - 2;

      return (
        <div
            key={plan.subscription_plan_id}
            className="col-lg-4 col-md-6 col-sm-12 mb-6 d-flex"
          >
            <div
              className={`card shadow-none w-100 ${isPopular ? '' : ''}`}
              style={{
                border: hoveredIndex === index ? '2px solid #16b1ff' : 'none',
               
              }}
              onMouseEnter={() => setHoveredIndex(index)} 
              onMouseLeave={() => setHoveredIndex(null)} 
            >
              <div className="card-body position-relative pt-4">
                {isPopular && (
                  <div className="position-absolute end-0 me-6 top-0 mt-6">
                    <span className="badge bg-label-primary rounded-pill">Popular</span>
                  </div>
                )}
                <h4 className="card-title text-center text-capitalize mb-1 mt-3">
                  {plan.plan_name
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(" ")}
                </h4>
                <div className="my-5 pt-3 text-center">
                  <img src={gifs[index % gifs.length]} alt="Plan Image" height="100" />
                </div>
                <div className="text-center">
                  <div className="d-flex justify-content-center">
                    <sup className="h6 pricing-currency mt-2 mb-0 me-1 text-body">₹</sup>
                    <h1 className="price-toggle price-yearly text-primary mb-0">{plan.pricing}</h1>
<sub className="h6 pricing-duration mt-auto mb-1 text-body ms-2"> /{plan.service_period} Days</sub>

                  </div>
                  {/* <small className="price-yearly price-yearly-toggle text-muted">340</small> */}
                </div>
                <p className="text-center text-capitalize my-6 ">{plan.plan_description}</p>
                <ul className="text-start p-1 ps-4 list-unstyled">
                  <li className="p-1">
                    <i className="ri-check-double-line text-info me-2"></i>
                    Copy trades on <span className="fw-bold">{plan.max_user_limit}</span> student account.
                  </li>
                  <li className="p-1">
                    <i className="ri-check-double-line text-info me-2"></i>
                    <span className="fw-bold">{plan.service_period} Days</span> service
                  </li>
                  <li className="p-1">
                    <i className="ri-check-double-line text-info me-2"></i>
                    Reporting: Daily, Weekly, Monthly
                  </li>
                </ul>
              </div>
            </div>
          </div>
       
     
      
      
      );
    })}
  </div>
</div>

  
      <LandingFooter />
    </>
  );
}

export default Pricing;
