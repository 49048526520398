import React, { useEffect, useState ,useRef} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
import { Toast } from "primereact/toast";
const TeacherReportDetails = () => {
  const { teacherId, sellDate } = useParams();
  const navigate = useNavigate();
  const toast = useRef(null);
  // Fetch teacher_id and sell_month from URL params
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backClicked, setBackClicked] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

   
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
     if (!authToken || !userId) {
    navigate("/commonlogin");
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_details`,
        {
          teacher_id: teacherId,
          sell_date: sellDate,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

      if (response.data) {
        setData(response.data.trades);
        
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/commonlogin"); // Redirect to common login screen on 401 error
      } else {
        console.error("Unauthorized access, redirecting to common login screen");
      
      }
    } finally {
      setLoading(false);
    }
  };



  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
     if (!authToken || !userId) {
    navigate("/commonlogin");
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_details`,
        {
          teacher_id: teacherId,
          sell_date: sellDate,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

    
        
        if (response.data && response.data.st === 1) {
          setData(response.data.trades);
    
        
        } else if (response.data && response.data.st === 2) {
          const errorMsg = response.data.msg || "Warning";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
          const errorMsg = response.data.msg || "Danger: Server Error";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        } else {
          const errorMsg = response.data.msg || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        }
      }catch (error) {
        console.error('Error fetching data:', error);
        // Check for 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized access, redirecting to common login screen');
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        } else {
          const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
          setError(new Error(errorMsg));
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [teacherId, sellDate]);

  
  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString('en-US', options);
  };
  
  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };
  
  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString('default', { month: 'short' });
  };
  
  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div >
        <div className="mb-1">Price: <strong>{rowData.buy_price} </strong> | Lot:<strong>  {rowData.buy_lotsize} ({rowData.buy_stock_quantity})</strong>| <strong>{rowData.buy_type}</strong></div>
        <div className="mb-1">{formatDateDay(rowData.buy_datetime)}-{formatDateMonth(rowData.buy_datetime)}-{formatDateYear(rowData.buy_datetime)} {formatDateTime(rowData.buy_datetime)} 
        </div>
        <div > {rowData.buy_orderid}</div>
      </div>
    );
  };
  
  const sellColumnTemplate = (rowData) => {
    return (
      <div >
        <div className="mb-1">Price: <strong>{rowData.sell_price} </strong>  | Lot:<strong> {rowData.sell_lotsize} ({rowData.sell_stock_quantity}) </strong>| <strong>{rowData.sell_type}</strong></div>
        <div className="mb-1">{formatDateDay(rowData.sell_datetime)}-{formatDateMonth(rowData.sell_datetime)}-{formatDateYear(rowData.sell_datetime)} {formatDateTime(rowData.sell_datetime)} </div>
        <div > {rowData.sell_orderid}</div>
      </div>
    );
  };
  return (
    <>
      <AdminHeader />
      <AdminSubHeader />
      <Toast ref={toast} />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_list" className="text-black">
                Teacher Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_report" className="text-black">
              Monthwise  Teacher Report 
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_report_datewise" className="text-black">
              Datewise  Teacher Report 
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Teacher Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-9 text-center mb-5">
              <h5 className="mb-0"> Teacher Report Details</h5>
            </div>
          </div>


          

          <div className="row align-items-center mb-3">
  <div className="col-12 col-md-6">
    <div className="d-flex align-items-center">
      <IconField iconPosition="left">
        <InputIcon className="ri ri-search-line"></InputIcon>
        <InputText
          type="search"
          placeholder="Search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="rounded"
        />
      </IconField>

      {loading ? (
        <div className="d-flex align-items-center">
          <div
            className="spinner-border spinner-border-sm custom-spinner ms-3"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <Tooltip target=".custom-target-icon" />
          <i
            className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
            role="button"
            data-pr-tooltip="Reload"
            onClick={handleRefresh}
            data-pr-position="top"
          ></i>
        </div>
      )}
    </div>
  </div>

  <div className="col-12 col-md-6 text-md-end text-end mt-3 mt-md-0">
    <strong className="fs-4">{sellDate}</strong>
  </div>
</div>

          <DataTable
            className="custom-column-border"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
    align="center"
      className="custom-column-border "
    header="Entry"
    body={buyColumnTemplate}
    style={{ width: '200px' }} 
  ></Column>

  <Column
    align="center"
     className="custom-column-border w-50"
    header="Exit"
    body={sellColumnTemplate}
  ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"              field="pandl_total"
              header="P&L Total"
              body={ PLTotalBodyTemplate} 
            ></Column>
            <Column
              align="center"
              className="custom-column-border"              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default TeacherReportDetails;
