import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import SubHeader from "../component/SubHeader";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import { onValue, ref, push, remove } from "firebase/database";
import { realtimeDb } from "../../firebase";
import { Tooltip } from "primereact/tooltip";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const Chat = () => {
  const userName = localStorage.getItem("userName");
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const userId = localStorage.getItem("userId");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/commonlogin");
          return;
        }
        const response = await axios.post(
          "https://ghanish.in/api/teacher/get_student_list_for_chat",
          {
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setStudents(response.data.student_details);
        } else {
          setError("Failed to fetch students.");
        }
      } catch (error) {
        setError("Error fetching student list.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [userId]);

  const filteredStudents = students.filter((student) => {
    const name = student.name ? student.name.toLowerCase() : '';
    const mobile = student.mobile ? student.mobile : '';
    
    return name.includes(searchTerm.toLowerCase()) || mobile.includes(searchTerm);
  });
  

  useEffect(() => {
    if (selectedStudent) {
      const messagesRef = ref(
        realtimeDb,
        `chats/${userId}_${selectedStudent.student_id}`
      );
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setChatMessages(Object.values(data));
        } else {
          setChatMessages([]);
        }
      });

      return () => unsubscribe();
    }
  }, [selectedStudent, userId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (message.trim() && selectedStudent) {
      const timestamp = Date.now();
      const newMessage = {
        sender: userId,
        message,
        timestamp,
      };

      try {
        await push(
          ref(realtimeDb, `chats/${userId}_${selectedStudent.student_id}`),
          newMessage
        );
        await push(
          ref(realtimeDb, `chats/${selectedStudent.student_id}_${userId}`),
          {
            ...newMessage,
            sender: selectedStudent.student_id,
          }
        );

        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  const handleDeleteChat = async () => {
    if (!selectedStudent) return;

    const chatRef1 = ref(
      realtimeDb,
      `chats/${userId}_${selectedStudent.student_id}`
    );
    const chatRef2 = ref(
      realtimeDb,
      `chats/${selectedStudent.student_id}_${userId}`
    );

    try {
      await Promise.all([remove(chatRef1), remove(chatRef2)]);
      setChatMessages([]);
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };
  const sortedMessages = chatMessages.sort((a, b) => a.timestamp - b.timestamp);

  const handleSearchButtonClick = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus(); // Focus the input when the button is clicked
    }
  };
  const handleRefresh = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://ghanish.in/api/teacher/get_student_list_for_chat",
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        setStudents(response.data.student_details);
      } else {
        setError("Failed to fetch students.");
      }
    } catch (error) {
      setError("Error fetching student list.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Header />
      <SubHeader />
      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Chat
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>

        <div className="app-chat card overflow-hidden">
          <div className="row g-0">
            <div
              className="col app-chat-contacts app-sidebar flex-grow-0 overflow-hidden border-end"
              id="app-chat-contacts"
            >
              <div className="sidebar-header h-px-75 px-5 border-bottom d-flex align-items-center">
                <div className="d-flex align-items-center me-4 me-lg-0">
                  <div className="flex-grow-1 input-group input-group-sm input-group-merge rounded-pill">
                    <span
                      className="input-group-text"
                      id="basic-addon-search31"
                    >
                      <i className="ri-search-line lh-1 ri-20px"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control chat-search-input"
                      placeholder="Search..."
                      aria-label="Search..."
                      aria-describedby="basic-addon-search31"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      ref={searchInputRef}
                    />
                  </div>
                </div>
                <i
                  className="ri-close-line ri-20px cursor-pointer position-absolute top-50 end-0 translate-middle fs-4 d-lg-none d-block"
                  data-overlay
                  data-bs-toggle="sidebar"
                  data-target="#app-chat-contacts"
                ></i>
                {loading ? (
                  <div className="d-flex align-items-center">
                    <div
                      className="spinner-border spinner-border-sm custom-spinner ms-3"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <Tooltip target=".custom-target-icon" />
                    <i
                      className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                      role="button"
                      data-pr-tooltip="Reload"
                      onClick={handleRefresh}
                      data-pr-position="top"
                    ></i>
                  </div>
                )}
              </div>

              <div className="sidebar-body">
                {loading ? (
                  <p className="text-center">Loading...</p>
                ) : error ? (
                  <p className="text-center text-danger">{error}</p>
                ) : (
                  <ul
                    className="list-unstyled chat-contact-list py-2 mb-0"
                    id="chat-list"
                  >
                    {filteredStudents.length === 0 ? (
                      <li className="chat-contact-list-item chat-list-item-0">
                        <h6 className="text-muted mb-0">No Chats Found</h6>
                      </li>
                    ) : (
                      filteredStudents.map((student) => (
                        <li
                          className={`chat-contact-list-item mb-1 ${
                            selectedStudent?.student_id === student.student_id
                              ? "active"
                              : ""
                          }`}
                          key={student.student_id}
                          onClick={() => setSelectedStudent(student)}
                        >
                          <a
                            className={`d-flex align-items-center ${
                              selectedStudent?.student_id === student.student_id
                                ? "text-white"
                                : ""
                            }`}
                          >
                            <div className="avatar d-block flex-shrink-0">
                              <span className="avatar-initial rounded-circle bg-label-primary">
                                {student.name.charAt(0).toUpperCase()}
                              </span>
                            </div>
                            <div className="chat-contact-info flex-grow-1 ms-4">
                              <div className="d-flex justify-content-between align-items-center">
                                <h6
                                  className={`chat-contact-name text-truncate fw-normal m-0 text-capitalize ${
                                    selectedStudent?.student_id ===
                                    student.student_id
                                      ? "text-white"
                                      : ""
                                  }`}
                                >
                                  {student.name}
                                </h6>
                              </div>
                              <small
                                className={`chat-contact-status  text-truncate ${
                                  selectedStudent?.student_id ===
                                  student.student_id
                                    ? "text-white"
                                    : "text-muted"
                                }`}
                              >
                                {student.mobile}
                              </small>
                            </div>
                          </a>
                        </li>
                      ))
                    )}
                  </ul>
                )}
              </div>
            </div>

            <div className="col app-chat-history bg-body ">
              <div className="chat-history-wrapper ">
                {selectedStudent && (
                  <div className="chat-history-header bg-white border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex overflow-hidden align-items-center">
                        <div className="flex-shrink-0 avatar avatar-online">
                          <span className="avatar-initial rounded-circle bg-label-primary">
                            {selectedStudent.name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                        <div className="chat-contact-info flex-grow-1 ms-4">
                          <h6 className="m-0 fw-normal text-capitalize">
                            {selectedStudent.name}
                          </h6>
                          <small className="user-status text-body text-muted">
                            {selectedStudent.mobile}
                          </small>
                        </div>
                      </div>
                      <div class="dropdown">
                        <button
                          class="btn btn-lg btn-icon btn-text-secondary text-body rounded-pill dropdown-toggle hide-arrow"
                          data-bs-toggle="dropdown"
                          aria-expanded="true"
                          id="chat-header-actions"
                        >
                          <i class="ri-more-2-line ri-20px"></i>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end"
                          aria-labelledby="chat-header-actions"
                        >
                          <a
                            class="dropdown-item"
                            href="javascript:void(0);"
                            onClick={handleDeleteChat}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="chat-history-body bg-body"
                  style={{ overflowY: "auto" }}
                >
                  {selectedStudent ? (
                    <ul className="list-unstyled chat-history">
                      {chatMessages.map((msg, index) => (
                        <li
                          key={index}
                          className={`chat-message ${
                            msg.sender === userId
                              ? "chat-message-right"
                              : "chat-message-left"
                          }`}
                        >
                          <div
                            className={`d-flex ${
                              msg.sender === userId
                                ? "justify-content-end"
                                : "justify-content-start"
                            } overflow-hidden`}
                          >
                            {msg.sender !== userId && (
                              <div className="flex-shrink-0 avatar me-3">
                                <span className="avatar-initial rounded-circle bg-label-primary">
                                  {selectedStudent.name.charAt(0).toUpperCase()}
                                </span>
                              </div>
                            )}
                            <div className="chat-message-wrapper flex-grow-1">
                              <div
                                className={`chat-message-text ${
                                  msg.sender === userId
                                    ? "bg-primary text-white"
                                    : "bg-white text-dark"
                                }`}
                              >
                                <p className="mb-0">{msg.message}</p>
                              </div>
                              <div className="text-end text-muted mt-1">
                                <small>
                                  {new Date(msg.timestamp).toLocaleTimeString()}
                                </small>
                              </div>
                            </div>
                            {msg.sender === userId && (
                              <div className="flex-shrink-0 avatar ms-3">
                                <span className="avatar-initial rounded-circle bg-label-primary">
                                  {userName.charAt(0).toUpperCase()}
                                </span>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-center">
                      <p className="text-center">
                        Select a student to view chat history
                      </p>
                      <button
                        type="submit"
                        className="btn btn-outline-secondary text-center"
                        onClick={handleSearchButtonClick}
                      >
                        <i className="ri-search-line ri-16px ms-md-1_5 ms-0 me-3"></i>
                        <span className="align-middle">Search Students</span>
                      </button>
                    </div>
                  )}
                </div>

                {selectedStudent && (
                  <div className="chat-history-footer bg-white border border-1">
                    <form
                      className="form-send-message d-flex justify-content-between align-items-center mb-0"
                      onSubmit={handleSendMessage}
                    >
                      <input
                        type="text"
                        className="form-control message-input me-4 shadow-none "
                        placeholder="Type your message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <button type="submit" className="btn btn-primary d-flex">
                        <span className="align-middle">Send</span>
                        <i className="ri-send-plane-line ri-16px ms-md-1_5 ms-0"></i>
                      </button>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
