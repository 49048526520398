import React, { useState, useEffect, useRef } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Toast } from "primereact/toast";
import config from "../../../component/config";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const PaymentGateway = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toast = useRef(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");
  const [backClicked, setBackClicked] = useState(false);
  const { subscriptionId, subscriptionPlanId, subscriptionType } = useParams();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          navigate("/commonlogin");
          return;
        }
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/purchased_subscription_plan_details`,
          {
            subscription_id: subscriptionId,
            subscription_plan_id: subscriptionPlanId,
            subscription_type: subscriptionType,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setSubscriptionDetails(response.data.subscription_plan_data);
        } else {
          setError(response.data.msg);
        }
      } catch (error) {
        setError("Error fetching subscription details");
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, [subscriptionId, subscriptionPlanId, authToken, subscriptionType]);

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (!subscriptionDetails) return;

    const { amount, subscription_plan_id, subscription_id, subscription_type } =
      subscriptionDetails;

    const options = {
      key: "rzp_test_oERLLksEMN4Hhj",
      amount: amount * 100,
      currency: "INR",
      name: "Shekru Labs India Pvt. Ltd.",
      description: "Test Transaction",
      image: "https://autoprofito.com/favicon.png",
      handler: function (response) {
        axios
          .post(
            `${config.apiDomain}/api/teacher/payment_gateway_success`,
            {
              payment_id: response.razorpay_payment_id,
              amount: amount,
              teacher_id: userId,
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          )
          .then((result) => {
            if (result.data.st === 1) {
              axios
                .post(
                  `${config.apiDomain}/api/teacher/change_subscription`,
                  {
                    teacher_id: userId,
                    subscription_id: subscription_id,
                    subscription_plan_id: subscription_plan_id,
                    subscription_type: subscription_type,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                    },
                  }
                )
                .then((changeResponse) => {
                  if (changeResponse.data.st === 1) {
                    toast.current.show({
                      severity: "success",
                      summary: "Subscription Changed",
                      detail: "Subscription changed successfully",
                      life: 2000,
                    });
                    setTimeout(() => {
                      navigate("/teacher/payment_successful");
                    }, 2000);
                  } else {
                    console.error(
                      "Error changing subscription:",
                      changeResponse.data.msg
                    );
                    toast.current.show({
                      severity: "error",
                      summary: "Subscription Change Failed",
                      detail: changeResponse.data.msg,
                    });
                  }
                })
                .catch((error) => {
                  console.clear(); 
                  toast.current.show({
                    severity: "error",
                    summary: "Subscription Change Failed",
                    detail:
                      "Failed to change subscription after successful payment.",
                  });
                });
            } else {
             
              toast.current.show({
                severity: "error",
                summary: "Payment Processing Failed",
                detail: "Unexpected response from payment success processing.",
              });
            }
          })
          .catch((error) => {
            console.clear(); 
            toast.current.show({
              severity: "error",
              summary: "Payment Processing Error",
              detail: "Failed to process payment success.",
            });
          });
      },
      prefill: {
        name: "Shekru Labs India Pvt. Ltd.",
        email: "pratiksha@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      console.log("Payment failed:", response);
      axios
        .post(
          `${config.apiDomain}/api/teacher/payment_gateway_failure`,
          {
            error_description: response.error.description,
            error_reason: response.error.reason,
            amount: amount,
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        .then((result) => {
          if (result.data.st === 1) {
            navigate("/teacher/payment_failed");
          } else {
          }
        })
        .catch((error) => {

          console.clear();         });
    });

    rzp1.open();
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  return (
    <div>
      <Header />
      <SubHeader />
      <Toast ref={toast} />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/billing" className="text-black">
                Subscriptions
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                to={`/teacher/subscription_plan/${subscriptionId}`}
                className="text-black"
              >
                Subscription Plan
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Payment
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-3 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center mb-5">
              <h5 className="mb-0"> Payment</h5>
            </div>
            <div className="col-12 col-md-3 col-md-3 text-end mb-5">
              <Link to="/teacher/payment_history">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-money-rupee-circle-line me-1 ri-lg"></i>{" "}
                    <span>Payment History</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>

          <div className="col-xl-12 col-md-12 col-12 mb-md-0 mb-0">
            <div className="text-center col-12">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 mb-0 text-center">
                    <div className="bs-stepper wizard-modern wizard-modern-example mt-2 text-center">
                      <div className="bs-stepper-header">
                        <div
                          className="step"
                          data-target="#account-details-modern"
                        >
                          <button
                            type="button"
                            className="step-trigger d-flex flex-column align-items-center"
                          >
                            <span className="bs-stepper-circle bg-white custom-stepper-circle"></span>
                            <span className="bs-stepper-label mt-2 text-center">
                              <span className="d-flex flex-column align-items-center">
                                <span className="bs-stepper-title">
                                  Subscription Plan
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>

                        <div
                          className="progress mb-1 text-center rounded mt-0 mx-auto "
                          style={{ height: "9px", width: "58%" }}
                        >
                          <div
                            className="progress-bar rounded"
                            role="progressbar"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div
                          className="step"
                          data-target="#personal-info-modern"
                        >
                          <button
                            type="button"
                            className="step-trigger d-flex flex-column align-items-center"
                          >
                            <span className="bs-stepper-circle bg-primary custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                            <span className="bs-stepper-label mt-2 text-center">
                              <span className="d-flex flex-column gap-1">
                                <span className="bs-stepper-title">
                                  Payment
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className="text-center mb-0  pb-3 mx-auto w-100">
                        All plans include 40+ advanced tools and features to
                        boost your product. Choose the best plan to fit your
                        needs. All plans include 40+ advanced tools and features
                        to boost
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" invoice-preview-header bg-body rounded p-2 text-heading">
              <div className="d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column">
                <div className="mb-xl-0 mb-0">
                  <div className="d-flex svg-illustration align-items-center gap-3 mb-4">
                    <span className="app-brand-logo demo">
                      <span style={{ color: "var(--bs-primary)" }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/favicon.png`}
                          alt="autoprofito Logo"
                          className="w-px-30 h-auto rounded-circle"
                        />
                      </span>
                    </span>
                    <span className="mb-0 app-brand-text fw-semibold">
                      AutoProfito
                    </span>
                  </div>
                  <p className="mb-1">
                    Shekru Labs India,2nd Floor,Manogat Appt Muktangan English
                    School{" "}
                  </p>
                  <p className="mb-1">
                    & Jr College ,Sahakar Nagar,Pune,411009
                  </p>

                  <p className="mb-0">
                    <strong>shekrulabs@gmail.com</strong>
                  </p>
                </div>

                <div>
                  <h5 className="mb-4 text-nowrap">Invoice #86423</h5>
                  <div className="mb-1">
                    <span>Date Issues:</span>
                    <span>25-Apr-2021</span>
                  </div>
                  <div>
                    <span>Date Due:</span>
                    <span>25-May-2021</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body px-3">
              <div className="d-flex justify-content-between flex-wrap row-gap-2 ">
                <div className="my-1">
                  <h6>Invoice To:</h6>

                  <table>
                    <tbody>
                      <tr>
                        <td className="pe-4 ">
                          <strong>Name:</strong>
                        </td>
                        <td>Thomas shelby</td>
                      </tr>
                      <tr>
                        <td className="pe-4">
                          <strong>Account Number:</strong>
                        </td>
                        <td>xxxxxxxxx</td>
                      </tr>
                      <tr>
                        <td className="pe-4">
                          <strong>Address:</strong>
                        </td>
                        <td>
                          Shekru Labs India,2nd Floor,Manogat Appt Muktangan{" "}
                          <br></br>
                          English School & Jr College ,Sahakar Nagar,Pune,411009
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="my-1">
                  <h6>Bill To:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td className="pe-4">
                          <strong>Country Name:</strong>
                        </td>
                        <td>India</td>
                      </tr>
                      <tr>
                        <td className="pe-4">
                          {" "}
                          <strong>Company Name:</strong>
                        </td>
                        <td>Shekru Labs India Pvt Ltd</td>
                      </tr>
                      <tr>
                        <td className="pe-4">
                          <strong>Address:</strong>
                        </td>
                        <td>
                          {" "}
                          Shekru Labs India,2nd Floor,Manogat Appt Muktangan{" "}
                          <br></br>
                          English School & Jr College ,Sahakar Nagar,Pune,411009
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="table-responsive border rounded border-bottom-0 mb-1 mt-2">
              <table className="table m-0 border">
                <thead>
                  <tr>
                    <th className="text-center border">Description</th>
                    <th className="text-center border">Date</th>
                    <th className="text-center border">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionDetails ? (
                    <>
                      <tr>
                        <td className="text-nowrap text-heading text-capitalize text-center border">
                          {subscriptionDetails.plan_name}
                          <br></br>
                          <span className="text-muted text-capitalize">
                            {subscriptionDetails.plan_description}
                          </span>
                        </td>
                        <td className="text-nowrap text-center border">
                          {subscriptionDetails.date}
                        </td>
                        <td className="text-center border">
                          ₹ {subscriptionDetails.amount} /-
                        </td>
                      </tr>
                      <tr className="border-bottom">
                        <td className="text-nowrap text-heading text-center">
                          <strong>TOTAL DUE</strong>
                        </td>
                        <td className="text-nowrap"></td>
                        <td className="text-nowrap text-center border">
                          <strong>₹ {subscriptionDetails.amount} /-</strong>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="row mt-3">
              <div className="col text-end">
                {subscriptionDetails && (
                  <div>
                    <button
                      className="btn btn-success rounded-pill"
                      onClick={() =>
                        handlePayment(
                          subscriptionDetails.subscription_plan_id,
                          subscriptionDetails.subscription_id
                        )
                      }
                      disabled={loading}
                    >
                      {loading ? (
                        <div className="spinner-border me-2" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                      )}
                      Pay ₹ {subscriptionDetails?.amount} /-
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PaymentGateway;
