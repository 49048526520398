import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

const SubHeader = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState("");
  const [showSecondNav, setShowSecondNav] = useState(false);
  const [secondNavActiveItem, setSecondNavActiveItem] = useState("");
  const userTradeBook = JSON.parse(localStorage.getItem("userTradeBook"));
  const userOrderBook = JSON.parse(localStorage.getItem("userOrderBook"));
  const [showTelegramItem, setShowTelegramItem] = useState(false);

  useEffect(() => {
    const broadcastingFlag = localStorage.getItem("broadcastingFlag");
 
    setShowTelegramItem(broadcastingFlag === 'true'); 
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("/teacher/dashboard")) {
      setActiveItem("student_home");
      setShowSecondNav(false);
    } else if (currentPath.includes("/user_basket")) {
      setActiveItem("user_basket");
      setShowSecondNav(true);
      setSecondNavActiveItem("user_basket");
    } else if (currentPath.includes("/telegram_broadcast")) {
      setActiveItem("telegram_broadcast");
      setShowSecondNav(false);
    } else if (currentPath.includes("/trade_position")) {
      setActiveItem("trade_position");
      setShowSecondNav(true);
      setSecondNavActiveItem("trade_position");
    } else if (currentPath.includes("/trade_book")) {
      setActiveItem("trade_book");
      setShowSecondNav(true);
      setSecondNavActiveItem("trade_book");
    } else if (currentPath.includes("/order_book")) {
      setActiveItem("order_book");
      setShowSecondNav(true);
      setSecondNavActiveItem("order_book");
    } else if (currentPath.includes("/teacher/watch_list")) {
      setActiveItem("watch_list");
      setShowSecondNav(true);
      setSecondNavActiveItem("watch_list");
    } else if (currentPath.includes("/teacher/manage_student")) {
      setActiveItem("manage_student");
      setShowSecondNav(false);
    } else {
      setActiveItem("");
      setShowSecondNav(false);
    }
  }, [location.pathname]);

  const [hoverItem, setHoverItem] = useState("");

  const handleSetActive = (item) => {
    const secondaryNavItems = [
      "trade_position",
      "trade_book",

      "order_book",
      "user_basket",
      "watch_list",
    ];

    if (secondaryNavItems.includes(item)) {
      setShowSecondNav(true);
      setSecondNavActiveItem(item);

      setActiveItem("trade_position");
    } else {
      setShowSecondNav(false);
      setActiveItem(item);
    }
  };

  const handleMouseEnter = (item) => {
    setHoverItem(item);
  };

  const handleMouseLeave = () => {
    setHoverItem("");
  };

  const getMenuItemStyle = (item) => {
    const isActive = activeItem === item;
    const isHovered = hoverItem === item;
    const baseStyle = {
      color: "black",
      backgroundColor: isHovered ? "#8c57ff" : "transparent",
    };
    if (isActive) {
      baseStyle.color = "#8c57ff";
    }
    if (isHovered) {
      baseStyle.color = "white";
    }
    return baseStyle;
  };

  const getSecondNavMenuItemStyle = (item) => {
    const isActive = activeItem === item;
    const isHovered = hoverItem === item;
    const baseStyle = {
      color: "black",
      backgroundColor: isHovered ? "#8c57ff" : "transparent",
    };
    if (isActive) {
      baseStyle.color = "#8c57ff";
    }
    if (isHovered) {
      baseStyle.color = "white";
    }
    return baseStyle;
  };

  return (
    <div>
      <div className="layout-page ">
        <div className="content-wrapper ">
          <aside
            id="layout-menu"
            className="layout-menu-horizontal menu-horizontal menu bg-menu-theme  flex-grow-0 "
          >
            <div className="container-xxl d-flex h-100 ">
              <ul className="menu-inner">
                <li
                  className={`menu-item ${
                    activeItem === "student_home" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("student_home")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("student_home")}
                >
                  <Link
                    to="/teacher/dashboard"
                    className="menu-link"
                    onClick={() => handleSetActive("student_home")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-home-7-line"></i>
                    <div>Home</div>
                  </Link>
                </li>
                {showTelegramItem && (
                  <li
                    className={`menu-item ${
                      activeItem === "telegram_broadcast" ? "active" : ""
                    }`}
                    onMouseEnter={() => handleMouseEnter("telegram_broadcast")}
                    onMouseLeave={handleMouseLeave}
                    style={getMenuItemStyle("telegram_broadcast")}
                  >
                    <Link
                      to="/teacher/telegram_broadcast"
                      className="menu-link"
                      onClick={() => handleSetActive("telegram_broadcast")}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <i className="menu-icon tf-icons ri-telegram-line"></i>
                      <div>Telegram Broadcast</div>
                    </Link>
                  </li>
                )}
                <li
                  className={`menu-item ${
                    activeItem === "manage_student" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("manage_student")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("manage_student")}
                >
                  <Link
                    to="/teacher/manage_student"
                    className="menu-link"
                    onClick={() => handleSetActive("manage_student")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-user-line"></i>
                    <div>Manage Student</div>
                  </Link>
                </li>

                <li
                  className={`menu-item ${
                    activeItem === "trade_position" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("trade_position")}
                  onMouseLeave={handleMouseLeave}
                  style={getMenuItemStyle("trade_position")}
                >
                  <Link
                    to="/trade_position"
                    className="menu-link"
                    onClick={() => handleSetActive("trade_position")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon tf-icons ri-funds-box-line"></i>
                    <div>Trade</div>
                  </Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>

      {showSecondNav && (
        <div className="container-xxl border-bottom-rounded">
          <aside
            id="layout-menu"
            className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0 "
          >
            <ul className="menu-inner">
              <li
                className={`menu-item ${
                  secondNavActiveItem === "trade_position" ? "active" : ""
                }`}
                onMouseEnter={() => handleMouseEnter("trade_position")}
                onMouseLeave={handleMouseLeave}
                style={getSecondNavMenuItemStyle("trade_position")}
              >
                <Link
                  to="/trade_position"
                  className="menu-link"
                  onClick={() => handleSetActive("trade_position")}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <i className="menu-icon ri-stock-line"></i>
                  <div>Positions</div>
                </Link>
              </li>
              {userOrderBook && (
                <li
                  className={`menu-item ${
                    secondNavActiveItem === "order_book" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("order_book")}
                  onMouseLeave={handleMouseLeave}
                  style={getSecondNavMenuItemStyle("order_book")}
                >
                  <Link
                    to="/order_book"
                    className="menu-link"
                    onClick={() => handleSetActive("order_book")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon ri-book-marked-line"></i>
                    <div>Order Book</div>
                  </Link>
                </li>
              )}
              {userTradeBook && (
                <li
                  className={`menu-item ${
                    secondNavActiveItem === "trade_book" ? "active" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter("trade_book")}
                  onMouseLeave={handleMouseLeave}
                  style={getSecondNavMenuItemStyle("trade_book")}
                >
                  <Link
                    to="/trade_book"
                    className="menu-link"
                    onClick={() => handleSetActive("trade_book")}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <i className="menu-icon ri-book-2-line"></i>
                    <div>Trade Book</div>
                  </Link>
                </li>
              )}

              <li
                className={`menu-item ${
                  secondNavActiveItem === "user_basket" ? "active" : ""
                }`}
                onMouseEnter={() => handleMouseEnter("user_basket")}
                onMouseLeave={handleMouseLeave}
                style={getSecondNavMenuItemStyle("user_basket")}
              >
                <Link
                  to="/user_basket"
                  className="menu-link"
                  onClick={() => handleSetActive("user_basket")}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <i className="menu-icon ri-shopping-basket-line"></i>
                  <div>Basket</div>
                </Link>
              </li>

              <li
                className={`menu-item ${
                  secondNavActiveItem === "watch_list" ? "active" : ""
                }`}
                onMouseEnter={() => handleMouseEnter("watch_list")}
                onMouseLeave={handleMouseLeave}
                style={getSecondNavMenuItemStyle("watch_list")}
              >
                <Link
                  to="/teacher/watch_list"
                  className="menu-link"
                  onClick={() => handleSetActive("watch_list")}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <i className="menu-icon ri-list-check-3"></i>
                  <div>Watchlist</div>
                </Link>
              </li>
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};

export default SubHeader;
