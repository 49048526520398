import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/teacher/payment_history");
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div
        className="card text-center p-5 shadow-lg"
        style={{ maxWidth: "500px" }}
      >
        <div className="card-body">
          <h1 className="card-title text-danger mb-4">Payment Failed</h1>
          <p className="card-text mb-4">
            We're sorry, but your transaction could not be completed. Please try
            again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
