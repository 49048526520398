import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import config from "../../../component/config";
import SubHeader from "../component/SubHeader";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { useNavigate, Link } from "react-router-dom";

import { VirtualScroller } from "primereact/virtualscroller";
import { Tooltip } from "primereact/tooltip";
import CardLinks from "../../../component/CardLinks";
import SubscriptionBadge from "../../../component/SubscriptionBadge";
const userId = localStorage.getItem("userId");
const Position = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});
  const [loadingMaps, setLoadingMaps] = useState({});
  const [Isloading, setIsloading] = useState(false);
  const [Isloadings, setIsloadings] = useState(false);
  const [Isloadingss, setIsloadingss] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingRefreshes, setLoadingRefreshes] = useState(false);
  const toast = useRef(null);
  const [hasApiData, setHasApiData] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const [data, setData] = useState([]);
  const [filteredeData, setFilteredeData] = useState(data);
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const wsRef = useRef(null);
  useEffect(() => {
    filterData(searchTerm);
  }, [searchTerm]);

  const filterData = (searchTerm) => {
    const filtered = filteredeData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredeData(filtered);
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const filteredData = data.filter((item) => {
    const nameMatch = item.name
      .toLowerCase()
      .includes(globalFilter.toLowerCase());
    const instrumentsMatch = item.instruments.some((instrument) =>
      instrument.toLowerCase().includes(globalFilter.toLowerCase())
    );
    return nameMatch || instrumentsMatch;
  });
  const handleRefreshes = async () => {
    setLoadingRefreshes(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken || !userId) {
        navigate("/commonlogin");
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ teacher_id: userId }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_order_placed_student_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.st === 1) {
        setData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
        setFilteredeData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Warning: Data may not be refreshed",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to fetch data",
          life: 3000,
        });
      } else {
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Network error: Failed to fetch data",
          life: 3000,
        });
      }
    } finally {
      setLoadingRefreshes(false);
    }
  };

  const getOrderPlacedStudentList = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/commonlogin");
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ teacher_id: userId }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_order_placed_student_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.data && data.data.length > 0) {
        setData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
        setFilteredeData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
        setHasApiData(true); // Set hasApiData to true if API returns data
      } else {
        setHasApiData(false); // Set hasApiData to false if no data from API
      }
    } catch (error) {
      console.clear(); 
      if (error.response && error.response.status === 401) {
       
        navigate("/commonlogin");
      } else {
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExitAllInstruments = async (student_id) => {
    setLoadingMaps((prev) => ({ ...prev, [student_id]: true }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_id: student_id,
        }),
      };

      const response = await fetch(
        " https://apbacked.xyz/exit_students_all_instrument",
        requestOptions
      );

      if (!response.ok) {
        if (response.status === 401) {
          navigate("/commonlogin");
          return;
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      await getPositionList();
      await getOrderPlacedStudentList();
    } catch (error) {
    } finally {
      setLoadingMaps((prev) => ({ ...prev, [student_id]: false }));
    }
  };

  const handleExit = async (studentId, instrumentData) => {
    const key = `${studentId}-${instrumentData.tradingsymbol}`;

    setLoadingMap((prev) => ({ ...prev, [key]: true }));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          student_id: studentId,
          instrument_data: instrumentData,
        }),
      };

      const response = await fetch(
        "https://apbacked.xyz/exit_student_instrument",
        requestOptions
      );

      if (!response.ok) {
        if (response.status === 401) {
         
          navigate("/commonlogin");
          return;
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      await getPositionList();

      await getOrderPlacedStudentList();
    } catch (error) {
    } finally {
      setLoadingMap((prev) => ({ ...prev, [key]: false }));
    }
  };

  useEffect(() => {
    getOrderPlacedStudentList();
  }, []);

  const [positionData, setPositionData] = useState({
    openPositions: [],
    closedPositions: [],
    totalRealisedPnl: 0.0,
    totalUnrealisedPnl: 0.0,
  });
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const [allSelected, setAllSelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0); // New state variable
  const [showActionColumn, setShowActionColumn] = useState(false);

  const getPositionList = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/commonlogin");
        return;
      }
  
      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      let openPositionRows = [];
      let closedPositionRows = [];
      let totalRealisedPnl = 0.0;
      let totalUnrealisedPnl = 0.0;
  
      data.position_list.forEach((item) => {
        const dailyDiffPercent = (
          ((item.ltp - item.close) / item.close) * 100
        ).toFixed(2);
  
        const netqty = parseInt(item.netqty);
        const lotsize = parseInt(item.lotsize);
        const lotquantity = parseInt(netqty / lotsize);
        const ordertype = netqty > 0 ? "BUY" : "SELL";
        const color_ordertype = netqty > 0 ? "text-success" : "text-danger";
        const avg_price = netqty > 0 ? item.totalbuyavgprice : item.totalsellavgprice;
        const color_pnl = item.pnl > 0 ? "text-success" : "text-danger";
        const color_diff_percent = dailyDiffPercent > 0 ? "text-success" : "text-danger";
  
        if (netqty !== 0) {
          totalUnrealisedPnl += parseFloat(item.unrealised);
          openPositionRows.push({
            ...item,
            dailyDiffPercent,
            ordertype,
            color_ordertype,
            color_pnl,
            color_diff_percent,
            lotquantity,
            avg_price,
          });
        } else {
          totalRealisedPnl += parseFloat(item.realised);
          closedPositionRows.push({
            ...item,
            dailyDiffPercent,
            color_diff_percent,
            color_pnl,
          });
        }
      });
  
      setPositionData({
        openPositions: openPositionRows,
        closedPositions: closedPositionRows,
        totalRealisedPnl,
        totalUnrealisedPnl,
      });
  
      const tokens = data.position_list.map((item) => item.symboltoken);
      const broker = data.broker; // Assume broker comes in response
      connectWebSocket(tokens, broker);
    } catch (error) {
      console.clear();
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        console.error("Failed to fetch position data:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  useEffect(() => {
    getPositionList();
  
    // Cleanup WebSocket when the component is unmounted
    return () => {
      disconnectWebSocket();
    };
  }, []);


  const connectWebSocket = (tokens, broker) => {
    let wsUrl;
  
    // Check the broker and set the WebSocket URL accordingly
    if (broker === 'angle_one') {
      wsUrl = 'wss://ghanish.in/ws/angelone/live_market_data/';
    } else if (broker === 'dhan') {
      wsUrl = 'wss://ghanish.in/ws/dhan/live_market_data/';
    } else {
      console.log('Unsupported broker:', broker);
      return; // Exit the function if the broker is not supported
    }
  
    // Only establish the WebSocket connection if the market is open
    if (isMarketOpen()) {
      wsRef.current = new WebSocket(wsUrl);
  
      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        const teacherId = userId; // Replace this with actual teacher ID
        const requestData = {
          teacher_id: teacherId,
          tokens: tokens, // Send all token IDs to WebSocket for live updates
        };
        wsRef.current.send(JSON.stringify(requestData));
      };
  
      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Live market data received:', data);
  
        if (data && data.data && data.data.token && data.data.ltp) {
          updateLtp(data.data.token, data.data.ltp); // Update the LTP using WebSocket data
        } else {
          console.log('Received null data or unsupported format:', data);
        }
      };
  
      wsRef.current.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
  
      wsRef.current.onclose = () => {
        console.log('WebSocket disconnected');
      };
    } else {
      console.log('Market is closed. WebSocket connection not established.');
    }
  };
  
  const updateLtp = (token, newLtp) => {
    const normalizedToken = token.toString(); // Normalize the token as string
    const normalizedLtp = parseFloat(newLtp).toFixed(2); // Convert LTP to number and format it
  
    // Update LTP in both open and closed positions
    setPositionData((prevPositionData) => ({
      ...prevPositionData,
      openPositions: prevPositionData.openPositions.map((position) =>
        position.symboltoken === normalizedToken
          ? { ...position, ltp: normalizedLtp } // Update LTP if token matches
          : position
      ),
      closedPositions: prevPositionData.closedPositions.map((position) =>
        position.symboltoken === normalizedToken
          ? { ...position, ltp: normalizedLtp } // Update LTP if token matches
          : position
      ),
    }));
  };
  
  
  const disconnectWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close(); // Close the WebSocket connection
      wsRef.current = null; // Clear the reference
    }
  };

  const handleCheckboxChange = (tradingsymbol, symboltoken, lotquantity) => {
    const key = JSON.stringify({ tradingsymbol, symboltoken, lotquantity });
    setSelectedPositions((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(key)) {
        newSelected.delete(key);
      } else {
        newSelected.add(key);
      }
      setSelectedCount(newSelected.size);
      return newSelected;
    });
  };

  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelectedPositions(new Set());
      setSelectedCount(0);
      setAllSelected(false);
      setShowActionColumn(false);
    } else {
      const newSelected = new Set();
      positionData.openPositions.forEach((item) => {
        if (item.netqty !== 0) {
          const key = JSON.stringify({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            lotquantity: parseInt(item.netqty / item.lotsize),
          });
          newSelected.add(key);
        }
      });
      setSelectedPositions(newSelected);
      setSelectedCount(newSelected.size);
      setAllSelected(true);
      setShowActionColumn(true);
    }
  };

  const isChecked = (tradingsymbol, symboltoken, lotquantity) => {
    const key = JSON.stringify({ tradingsymbol, symboltoken, lotquantity });
    return selectedPositions.has(key);
  };

  const exitPosition = async () => {
    setIsloadingss(true);
    try {
      const userId = localStorage.getItem("userId");
      const order_data = Array.from(selectedPositions).map((positionString) => {
        const { tradingsymbol, symboltoken, lotquantity } =
          JSON.parse(positionString);

        return {
          instrument: tradingsymbol,
          symboltoken,
          exit_lot: lotquantity,
        };
      });

      const response = await fetch(`https://apbacked.xyz/exit_position`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          teacher_id: userId,
          order_data,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Position exited successfully",
          life: 3000,
        });
      }
      await getOrderPlacedStudentList();
      await getPositionList();
    } catch (error) {
      console.clear(); 
    } finally {
      setIsloadingss(false);
    }
  };

  const handleExitAllPending = async () => {
    const userId = localStorage.getItem("userId");
    setIsloadings(true);

    try {
      const response = await fetch(
        `https://apbacked.xyz/exit_all_student_pending`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Add this line to set the content type to JSON
          },
          body: JSON.stringify({
            teacher_id: userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      await getOrderPlacedStudentList();
      await getPositionList();
    } catch (error) {
    } finally {
      setIsloadings(false); // Ensure loading state is set to false
    }
  };

  const handleRefresh = async () => {
    setLoadingRefresh(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/commonlogin");
        return;
      }

      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.st === 1) {
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Warning: Data may not be fully refreshed",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to fetch data",
          life: 3000,
        });
      } else {
      }

      let openPositionRows = [];
      let closedPositionRows = [];
      let totalRealisedPnl = 0.0;
      let totalUnrealisedPnl = 0.0;

      data.position_list.forEach((item) => {
        const dailyDiffPercent = (
          ((item.ltp - item.close) / item.close) *
          100
        ).toFixed(2);

        const netqty = parseInt(item.netqty);
        const lotsize = parseInt(item.lotsize);
        const lotquantity = parseInt(netqty / lotsize);
        const ordertype = netqty > 0 ? "BUY" : "SELL";
        const color_ordertype = netqty > 0 ? "text-success" : "text-danger";
        const avg_price =
          netqty > 0 ? item.totalbuyavgprice : item.totalsellavgprice;
        const color_pnl = item.pnl > 0 ? "text-success" : "text-danger";
        const color_diff_percent =
          dailyDiffPercent > 0 ? "text-success" : "text-danger";

        if (netqty !== 0) {
          totalUnrealisedPnl += parseFloat(item.unrealised);

          openPositionRows.push({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            producttype: item.producttype,
            optiontype: item.optiontype,
            exchange: item.exchange,
            lotsize: item.lotsize,
            netqty: item.netqty,
            ltp: item.ltp,
            close: item.close,
            totalbuyavgprice: item.totalbuyavgprice,
            totalsellavgprice: item.totalsellavgprice,
            pnl: item.pnl,
            dailyDiffPercent,
            ordertype,
            color_ordertype,
            color_pnl,
            color_diff_percent,

            lotquantity,
            avg_price,
          });
        } else {
          totalRealisedPnl += parseFloat(item.realised);
          closedPositionRows.push(
            <tr key={item.tradingsymbol}>
              <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>
                <span className="instrument_symbol">{item.tradingsymbol}</span>{" "}
                <span className="d-none instrument_token">
                  {item.symboltoken}
                </span>
              </td>
              <td style={{ wordWrap: "break-word", maxWidth: "110px" }}>
                {item.producttype}
              </td>
              <td className="text-center">{item.optiontype}</td>
              <td className="text-center">{item.exchange}</td>

              <td>
                0 Lots{" "}
                <span className="text-body-tertiary">
                  (1 Lot = {item.lotsize})
                </span>
              </td>
              <td>
                <span className={color_diff_percent}>{item.ltp} ₹</span>{" "}
                <span className="text-body-tertiary">
                  ({dailyDiffPercent}%)
                </span>
              </td>
              <td>{item.totalbuyavgprice} ₹</td>
              <td>{item.totalsellavgprice} ₹</td>
              <td className={color_pnl}>{item.pnl} ₹</td>
            </tr>
          );
        }
      });

      setPositionData({
        openPositions: openPositionRows,
        closedPositions: closedPositionRows,
        totalRealisedPnl,
        totalUnrealisedPnl,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        
        navigate("/commonlogin"); 
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while fetching data",
          life: 3000,
        });
      }
    } finally {
      setLoadingRefresh(false);
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleLotSizeChange = (event, tradingsymbol, symboltoken) => {
    const selectedLotSize = parseInt(event.target.value, 10);

    setSelectedPositions((prevSelected) => {
      const updatedSelected = new Set(prevSelected);

      const key = JSON.stringify({ tradingsymbol, symboltoken });

      if (updatedSelected.has(key)) {
        updatedSelected.delete(key);

        updatedSelected.add(
          JSON.stringify({
            tradingsymbol,
            symboltoken,
            lotquantity: selectedLotSize,
          })
        );
      }

      return updatedSelected;
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };

  return (
    <>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-3 container-xxl">
        {isMarketOpen() ? (
          <div
            className="text-center "
            style={{
              border: "2px solid green",
              padding: "10px",
              color: "green",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Open
          </div>
        ) : (
          <div
            className="text-center"
            style={{
              border: "2px solid orange",
              padding: "10px",
              color: "orange",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Closed
          </div>
        )}
      </div>

      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
        <div className="d-flex justify-content-between align-items-center">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Positions
            </li>
          </ol>
          <SubscriptionBadge></SubscriptionBadge>
          </div>
        </nav>
        <div className="row ">
          <div className="col-xl-9 col-lg-9 col-md-7 col-12">
          
         
            <div className="card mb-3" style={{ overflow: "hidden" }}>
              <div className="col text-start mb-5 mt-5 ms-5">
                <button
                  onClick={handleBack}
                  className="btn rounded-pill btn-outline-secondary btn-xs"
                >
                  <i className="ri-arrow-left-double-line me-1 ri-md"></i> Back
                </button>
              </div>
              <div className=" text-center">
                <div className="row">
                  <div className="col-md-4 col-6 mb-3">
                    <h4
                      className={
                        positionData.totalUnrealisedPnl < 0
                          ? "text-danger"
                          : positionData.totalUnrealisedPnl > 0
                          ? "text-success"
                          : "text-muted"
                      }
                    >
                      {positionData.totalUnrealisedPnl === 0
                        ? "0.00"
                        : positionData.totalUnrealisedPnl.toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Unrealised Profit & Loss</p>
                  </div>
                  <div className="col-md-4 col-6 mb-3">
                    <h4
                      className={
                        positionData.totalRealisedPnl < 0
                          ? "text-danger"
                          : positionData.totalRealisedPnl > 0
                          ? "text-success"
                          : "text-muted"
                      }
                    >
                      {positionData.totalRealisedPnl === 0
                        ? "0.00"
                        : positionData.totalRealisedPnl.toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Realised Profit & Loss</p>
                  </div>
                  <div className="col-md-4 col-6  mb-3">
                    <h4
                      className={
                        positionData.totalRealisedPnl +
                          positionData.totalUnrealisedPnl ===
                        0
                          ? "text-muted"
                          : positionData.totalRealisedPnl +
                              positionData.totalUnrealisedPnl <
                            0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {(
                        positionData.totalRealisedPnl +
                        positionData.totalUnrealisedPnl
                      ).toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Total Profit & Loss</p>
                  </div>
                </div>
              </div>
            </div>
             
            {positionData.openPositions &&
              positionData.openPositions.length > 0 && (
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="text-center flex-grow-1 m-0">Open Position</h5>
                <div>
                  {loadingRefresh ? (
                    <div className="d-flex align-items-center">
                      <div
                        className="spinner-border spinner-border-sm custom-spinner ms-3"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <Tooltip target=".custom-target-icon" />
                      <i
                        className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                        role="button"
                        data-pr-tooltip="Reload"
                        onClick={handleRefresh}
                        data-pr-position="top"
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-responsive text-start p-3">
              <div className="d-flex justify-content-end justify-content-md-end justify-content-sm-start mb-3">
              <button
                    type="button"
                    className="btn bg-white btn-outline-secondary rounded-pill btn-xs me-3"
                    onClick={handleToggleSelectAll}
                  >
                    {allSelected ? "Unselect All" : "Select All"}
                  </button>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Product Type</th>
                      <th>Option Type</th>
                      <th>Order Type</th>
                      <th>Exchange</th>
                      <th>Lots</th>
                      <th>LTP</th>
                      <th>Avg Buy Price</th>
                      <th>Profit & Loss</th>
                      {showActionColumn && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
  {positionData.openPositions.map((item, index) => {
    // Calculate P&L
    const liveLtp = item.ltp ? parseFloat(item.ltp) : null;
    const avgPrice = parseFloat(item.avg_price);
    let calculatedPnl;

    if (liveLtp !== null) {
      calculatedPnl = (avgPrice - liveLtp).toFixed(2); // Calculate P&L using (Avg Price - Live LTP)
    } else {
      calculatedPnl = item.pnl.toFixed(2); // Fallback to API P&L if LTP is not available
    }

    return (
      <tr key={index}>
        <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>
          <span className="instrument_symbol">{item.tradingsymbol}</span>{" "}
          <span className="d-none instrument_token">{item.symboltoken}</span>
        </td>
        <td style={{ wordWrap: "break-word", maxWidth: "110px" }}>
          <span className="instrument_producttype">{item.producttype}</span>
        </td>
        <td>{item.optiontype}</td>
        <td className={item.color_ordertype}>{item.ordertype}</td>
        <td>
          <span className="instrument_exchange">{item.exchange}</span>
        </td>
        <td className={item.color_pnl}>
          {item.lotquantity} Lots <br></br>
          <span className="text-body-tertiary lot_size">(1 Lot = {item.lotsize})</span>
        </td>
        <td>
          <span className={item.color_diff_percent}>
            {item.ltp ? `${item.ltp} ₹` : "N/A"}
          </span>{" "}
          <span className="text-body-tertiary">({item.dailyDiffPercent}%)</span>
        </td>
        <td>{item.avg_price} ₹</td>
        <td className={item.color_pnl}>
          {calculatedPnl} ₹ {/* Display the calculated or fallback P&L */}
        </td>
        {showActionColumn && (
          <td>
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={isChecked(item.tradingsymbol, item.symboltoken, parseInt(item.netqty / item.lotsize))}
                onChange={() => handleCheckboxChange(item.tradingsymbol, item.symboltoken, parseInt(item.netqty / item.lotsize))}
                id={`${item.tradingsymbol}-checkbox`}
              />
            </div>
          </td>
        )}
      </tr>
    );
  })}
</tbody>

                  <tfoot>
                    {showActionColumn && (
                      <tr>
                        <td colSpan="10">
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rounded"
                              onClick={exitPosition}
                              disabled={Isloadingss || selectedCount == 0}
                            >
                              {Isloadingss ? (
                                <>
                                  <div
                                    className="spinner-border me-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                  Executing {selectedCount} Selected
                                </>
                              ) : (
                                <>
                                  <i className="ri-send-plane-fill ri-lg me-3"></i>
                                  Exit {selectedCount} Selected
                                </>
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tfoot>
                </table>
              </div>
            </div>
              )}
           {positionData.closedPositions &&
  positionData.closedPositions.length > 0 && (
    <div className="card mt-3">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h5 className="text-center flex-grow-1 m-0">Closed Position</h5>

        {loadingRefresh ? (
          <div className="d-flex align-items-center">
            <div className="spinner-border spinner-border-sm custom-spinner ms-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="mt-3">
            <Tooltip target=".custom-target-icon" />
            <i
              className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
              role="button"
              data-pr-tooltip="Reload"
              onClick={handleRefresh}
              data-pr-position="top"
            ></i>
          </div>
        )}
      </div>

      <div className="table-responsive text-start p-3">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Instrument</th>
              <th>Product Type</th>
              <th>Option Type</th>
              <th>Exchange</th>
              <th>Lots</th>
              <th>LTP</th>
              <th>Buy Price</th>
              <th>Sell Price</th>
              <th>Profit & Loss</th>
            </tr>
          </thead>
          <tbody>
            {positionData.closedPositions.map((item, index) => (
              <tr key={index}>
                <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>
                  <span className="instrument_symbol">{item.tradingsymbol}</span>
                  <span className="d-none instrument_token">{item.symboltoken}</span>
                </td>
                <td>{item.producttype}</td>
                <td className="text-center">{item.optiontype}</td>
                <td className="text-center">{item.exchange}</td>
                <td>0 Lots <span className="text-body-tertiary">(1 Lot = {item.lotsize})</span></td>
                <td>
                  <span className={item.color_diff_percent}>
                    {item.ltp ? `${item.ltp} ₹` : 'N/A'}
                  </span>
                  <span className="text-body-tertiary">({item.dailyDiffPercent}%)</span>
                </td>
                <td>{item.totalbuyavgprice} ₹</td>
                <td>{item.totalsellavgprice} ₹</td>
                <td className={item.color_pnl}>{item.pnl} ₹</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )}

          </div>
          <div className="col-xl-3 col-lg-3 col-md-5 col-12 mt-3 mt-mb-0">
          {hasApiData && (
    <div className="right-side-content">
      <div className="card">
        <div className="d-flex justify-content-around mt-5">
          <button
            type="button"
            className="btn btn-danger rounded btn-md w-100 ms-5 me-5"
            onClick={handleExitAllPending}
            disabled={Isloadings || filteredData.length === 0}
          >
            {Isloadings ? (
              <>
                <div className="spinner-border me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                Executing all Pendings
              </>
            ) : (
              <>
                <i className="ri-send-plane-fill me-3"></i> Exit All Pendings
              </>
            )}
          </button>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-start ms-4 mb-3 me-3">
            <div className="flex-grow-1 input-group input-group-sm input-group-merge rounded-pill">
              <span className="input-group-text" id="basic-addon-search31">
                <i className="ri-search-line lh-1 ri-20px"></i>
              </span>
              <input
                type="text"
                className="form-control chat-search-input ps-8"
                placeholder="Search instruments..."
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                aria-label="Search..."
                aria-describedby="basic-addon-search31"
              />
            </div>

            {loadingRefreshes ? (
              <div className="d-flex align-items-center">
                <div
                  className="spinner-border spinner-border-sm custom-spinner ms-3"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefreshes}
                  data-pr-position="top"
                ></i>
              </div>
            )}
          </div>

          <hr />

          {filteredData.length > 0 ? (
            <div
              className="virtual-scroller-container"
              style={{
                height: "500px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <VirtualScroller
                items={filteredData}
                itemSize={70}
                itemTemplate={(item, index) => (
                  <div key={index} className="border-bottom mb-3 pb-3">
                    <div className="d-flex align-items-start mb-3">
                      <p className="text-start mb-0 fs-5 text-black flex-grow-1">
                        {capitalizeFirstLetter(item.name)}
                      </p>
                      <button
                        type="button"
                        className="btn btn-danger btn-xs btn btn-outline-danger waves-effect"
                        onClick={() => handleExitAllInstruments(item.user_id)}
                        disabled={loadingMaps[item.user_id]}
                      >
                        {loadingMaps[item.user_id] ? (
                          <div
                            className="spinner-border me-2"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <i className="ri-send-plane-fill me-2"></i>
                        )}
                        {loadingMaps[item.user_id] ? "Exit" : "Exit"}
                      </button>
                    </div>

                    {item.lots.map((lot, lotIndex) => {
                      const key = `${item.user_id}-${item.instruments[lotIndex]}`;
                      return (
                        <div key={lotIndex} className="mb-3">
                          <div className="d-flex align-items-center mb-1">
                            <span className="fs-5 text-black">
                              {item.instruments[lotIndex]}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="text-start text-primary1">
                              <strong className="text-muted">Buy:</strong>{" "}
                              <span
                                className={`${
                                  lot.size === 0 ? "text-black" : "text-success"
                                } small`}
                              >
                                {lot.size} ({lot.price} ₹)
                              </span>
                            </div>
                            <div className="text-end text-primary1">
                              <strong className="text-muted">Sell:</strong>{" "}
                              <span
                                className={`${
                                  lot.sell === 0 ? "text-black" : "text-danger"
                                } small`}
                              >
                                {lot.sell} ({lot.sellPrice} ₹)
                              </span>
                            </div>
                            <button
                              type="button"
                              className="btn btn-danger btn-xs btn-outline-danger waves-effect"
                              onClick={() =>
                                handleExit(item.user_id, {
                                  tradingsymbol: item.instruments[lotIndex],
                                  symboltoken: item.lots[lotIndex].symboltoken,
                                })
                              }
                              disabled={loadingMap[key] === true}
                            >
                              {loadingMap[key] ? (
                                <div
                                  className="spinner-border me-2"
                                  role="status"
                                >
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              ) : (
                                <i className="ri-send-plane-fill"></i>
                              )}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                delay={250}
                className="border-1 surface-border border-round"
                style={{ height: "500px" }}
              />
            </div>
          ) : (
            <div>
              <p className="text-center text-muted" style={{ height: "500px" }}>
                No results found
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )}
</div>



          <CardLinks></CardLinks>
        </div>
      </div>
      {/* </div> */}
      <Footer />
    </>
  );
};

export default Position;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
